import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useAddBusinessWithOwner,
  createBusiness,
} from '../../../../utils/hooks/Organization/business';
import Cropper from '../../../../components/cropper';

export default function FormDialog(props) {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const { mutate: addBusiness, isLoading } = useAddBusinessWithOwner();
  const history = useHistory();

  const [business, setBusiness] = useState({
    name: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageChanger = (image) => {
    setImage(image);
  };

  const fileChanger = (file) => {
    setFile(file);
  };

  const submitBusiness = async () => {
    const newBusiness = {
      name: business.name,
      file: file,
      owner: props.userId,
      handleClose: handleClose,
    };
    const id = await createBusiness(newBusiness);
    if (props.refetch) {
      props.refetch();
      window.location.reload();
    } else {
      history.push(`/admin/business/${id}`);
    }
  };

  return (
    <div>
      <button
        className="btn btn-sm btn-warning btn-icon"
        onClick={handleClickOpen}
      >
        <span className="btn-inner--text">Create new Organization</span>
        <span className="btn-inner--icon">
          <FeatherIcon icon="plus" />
        </span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#2d2d2d',
            width: '500px',
          },
        }}
      >
        <DialogTitle className="ml-4 mt-4">Create new Organization</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group ml-4">
                <label className="form-control-label">Name</label>
                <div className="input-group" style={{ width: '190px' }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex.: Org name"
                    value={business.name}
                    onChange={(e) =>
                      setBusiness({ ...business, name: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <Cropper setFile={fileChanger} />
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="btn btn-sm btn-warning btn-icon mr-auto mb-4"
            style={{ height: '39px', width: '105px', marginLeft: '2.3rem' }}
          >
            Cancel
          </button>
          <button
            onClick={submitBusiness}
            className="btn btn-sm btn-warning btn-icon d-flex align-items-center justify-content-center mb-4"
            style={{ height: '39px', width: '90px', marginRight: '2.28rem' }}
          >
            {isLoading ? (
              <CircularProgress style={{ height: '15px', width: '15px' }} />
            ) : (
              'Create'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
