import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Slider } from '@mui/base';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export default function Cropper(props) {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(4 / 4);
  const [c, setC] = useState();

  const onComplete = useCallback(async (c) => {
    setC(c);
    setCompletedCrop(c);
    const croppedImg = await getCroppedImg(
      imgRef.current,
      c,
      'logo.png',
      scale,
      rotate,
    );
    const result = await fetch(croppedImg).then((r) => r.blob());
    const file = new File([result], 'logo.png');
    props.setFile(file);
  }, [scale, rotate, props]);

  useEffect(() => {
    if (imgSrc) {
      onComplete(c);
    }
  }, [rotate, scale, c, imgSrc, onComplete]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || ''),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function getCroppedImg(image, crop, fileName, scale = 1, rotate = 0) {
    const TO_RADIANS = Math.PI / 180;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    let scaleX;
    let scaleY;
    if (image !== null) {
      scaleX = image.naturalWidth / image.width;
      scaleY = image.naturalHeight / image.height;
    }

    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(URL.createObjectURL(blob));
      }, 'image/png');
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 4 / 4));
    }
  }

  return (
    <div className="App">
      <div className="Crop-Controls">
        <div className="form-group w-100">
          <label className="form-control-label d-flex w-100 align-items-center justify-content-start">
            Upload Logo
          </label>
          <div className="input-group w-100 align-items-center justify-content-start">
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              id="file-input"
              style={{ display: 'none' }}
            />
            <label htmlFor="file-input" className="w-100">
              <span
                className="btn btn-sm btn-warning btn-icon form-control d-flex align-items-center justify-content-center"
                style={{ width: '100%' }}>
                Upload Image
              </span>
            </label>
          </div>
        </div>
        {Boolean(imgSrc) && (
          <div className="d-flex justify-content-center">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={async (c) => await onComplete(c)}
              aspect={aspect}>
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
        )}
        {Boolean(imgSrc) && (
          <>
            <div className="form-check my-3">
              <input
                type="checkbox"
                className="form-check-input"
                onClick={handleToggleAspectClick}
                value={aspect}
              />
              <span className="form-control-label ml-2">Remove Aspect Ratio</span>
            </div>
            <div className="d-flex flex-column justify-content-between mb-3">
              <span className="form-control-label">Zoom</span>
              <div className="bg-light p-2 px-3 rounded border border-neutral d-flex">
                <Slider
                  value={scale}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Scale"
                  onChange={(e, zoom) => setScale(Number(zoom))}
                />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between mb-3">
              <span className="form-control-label">Rotation</span>
              <div className="bg-light p-2 px-3 rounded border border-neutral d-flex">
                <Slider
                  value={rotate}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotate"
                  onChange={(e, degree) => setRotate(Number(degree))}
                />
              </div>
            </div>
          </>
        )}
        {Boolean(imgSrc) && (
          <div className="d-flex justify-content-center">
            <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
          </div>
        )}
      </div>
    </div>
  );
}
