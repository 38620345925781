import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListContainer = styled.ul`
  position: absolute;
  top: 45px;
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 4px;
  z-index: 99;
  padding: 15px;

  list-style: none;
  li {
    cursor: pointer;
    margin-bottom: 10px;
    border-bottom: 1px solid #333;

    &:hover {
      color: #fff;
    }
  }
`;
