import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import QRCode from 'qrcode.react';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import api from '../../../utils/api';

import Side from './side';
import context from 'react-bootstrap/esm/AccordionContext';

const Details = ({ setPrintBatch, modalOpen }) => {
  const { batchId } = useParams();

  const [batch, setBatch] = useState({});
  const [codes, setCodes] = useState([]);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    getCodes();
    getBatch();
  }, []);

  const getCodes = async () => {
    try {
      const { data } = await api.get(
        `/admin/qrcode/batch/qrcodeList/${batchId}`,
      );
      setCodes(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadAllQrCodes = async () => {
    await setViewMore(true);
    const zip = new JSZip();
    for (let qr of codes) {
      const canva = document.getElementById(qr._id);
      const data = canva
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
        .split(';base64,')[1];
      const name = `QRCode-${qr.code}.png`;
      zip.file(name, data, { base64: true });
    }
    setViewMore(false);
    await zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'codes.zip');
    });
  };

  const getBatch = async () => {
    try {
      const { data } = await api.get(`/admin/batch/${batchId}`);
      setBatch(data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadImage = async (code, index) => {
    const qrCodeURL = document
      .getElementById(`qr-${index}`)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QRCode-${code}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <>
      <section
        style={modalOpen ? { filter: 'blur(4px)' } : {}}
        class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <Side />
            </div>
            <div class="col-md-10">
              <div class="row align-items-center mb-4">
                <div class="col">
                  <h1 class="h5 mb-0">
                    <button
                      type="button"
                      class="btn btn-sm btn-warning btn-icon"
                      onClick={() =>
                        (window.location.href = '/admin/codes/all')
                      }>
                      <span class="btn-inner--icon">
                        <FeatherIcon icon="arrow-left" />
                      </span>
                      <span class="btn-inner--text">Return</span>
                    </button>
                    <span class="ml-3">Batch Details</span>
                  </h1>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => setPrintBatch(codes)}>
                    <span class="btn-inner--text">Print Batch</span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="printer" />
                    </span>
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => downloadAllQrCodes()}>
                    <span class="btn-inner--text">
                      Download All QRCodes Batches
                    </span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="plus" />
                    </span>
                  </button>
                </div>
              </div>
              <div class="card p-3 mb-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Name</span>
                        <h1 class="h5 mb-0">{batch?.nickname}</h1>
                      </div>
                      <div class="col-md-4">
                        <span>Amount</span>
                        <h1 class="h5 mb-0">{codes.length}</h1>
                      </div>
                      <div class="col-md-4">
                        <span>Created</span>
                        <h1 class="h5 mb-0">
                          {new Date(batch?.createdAt).toLocaleDateString()}{' '}
                          {new Date(batch?.createdAt).toLocaleTimeString()}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h1 class="h5 mb-2">QR Codes</h1>
              <div class="card p-4">
                {codes.slice(0, viewMore ? 1000 : 5).map((item, index) => (
                  <div class="row">
                    <div class="col-md-3">
                      <span>Code</span>
                      <h1 class="h5 mb-0">{item.code}</h1>
                    </div>
                    <div class="col-md-3">
                      <span>Status</span>
                      {item?.active ? (
                        <div>
                          <span class="badge badge-success">Active</span>
                        </div>
                      ) : (
                        <div>
                          <span class="badge badge-warning">Inactive</span>
                        </div>
                      )}
                    </div>
                    <div class="col-md-3">
                      <span>Image File</span>
                      <div>
                        <span
                          style={{ cursor: 'pointer' }}
                          class="badge badge-primary"
                          onClick={() => downloadImage(item.code, index)}>
                          Download PNG
                        </span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <span>Details</span>
                      <div>
                        <span
                          style={{ cursor: 'pointer' }}
                          class="badge badge-success"
                          onClick={() =>
                            window.open(`/admin/qr/${item._id}`, '_blank')
                          }>
                          View Details
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        display: 'none',
                      }}>
                      <QRCode
                        id={`${item._id}`}
                        size={1000}
                        style={{ borderRadius: 5 }}
                        value={`${process.env.REACT_APP_BASE_QR}${item.code}`}
                      />
                    </div>
                    {codes.slice(0, viewMore ? 1000 : 5).length !==
                      index + 1 && (
                      <div
                        style={{
                          width: '100%',
                          margin: '10px 15px 10px 15px',
                          height: '1px',
                          backgroundColor: '#FFFFFF50',
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
              {!viewMore && (
                <div class="mt-4">
                  <button
                    onClick={() => setViewMore(true)}
                    type="submit"
                    class="btn btn-block btn-warning">
                    View All
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
