import { useState } from 'react';
import Select from 'react-select';
import { HexColorPicker } from 'react-colorful';
import { useSnackbar } from 'notistack';
import api from '../../../utils/api';
import axios from 'axios';
import { PlacesAutocomplete } from '../../admin/business/components/PlacesAutoComplete';

const shapeStyles = {
  option: (styles, { isFocused }) => ({
    ...styles,
    color: '#000',
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#333333',
    borderRadius: '.375rem',
    transition:
      'transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    borderColor:
      state.isSelected || state.isFocused ? 'var(--orange)' : 'transparent',
    backgroundColor: state.isSelected || state.isFocused ? '#111' : '#333333',
    ':hover': {
      borderColor: 'var(--orange)',
      boxShadow: 'none',
    },
    boxShadow: 'none',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: '#FFFFFF' };
  },
};

const uploadFiles = async (files) => {
  const images = [];
  for (const file of files) {
    const response = await api.post('/upload/signedUrl', {
      fileExtension: file.name.split('.').pop()[1],
    });
    await axios.put(response.data.signedUrl.url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    images.push({
      url:
        `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
        response.data.signedUrl.key,
    });
  }
  return images;
};

const EditUserPremium = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editUser, setEditUser] = useState({
    ...user,
  });
  const [files, setFiles] = useState([]);
  const [color, setColor] = useState(editUser.profileIconColor.secondary);
  const options = [
    { value: 'Square', label: 'Small' },
    { value: 'Wide', label: 'Big' },
  ];
  const optionsProfile = [
    { value: 'Standard', label: 'Standard' },
    { value: 'Premium', label: 'Premium' },
    { value: 'Organization', label: 'Organization' },
  ];

  const setAddressAndCoords = (address, lat, lng) => {
    setEditUser({
      ...editUser,
      address,
      latitude: lat,
      longitude: lng,
    });
  };

  const submitData = async (e) => {
    e.preventDefault();
    if (files.length + editUser.gallery.length > 3) {
      enqueueSnackbar('You can only upload 3 images', {
        variant: 'error',
      });
      return;
    }
    const images = await uploadFiles(files);
    try {
      const [{ data }, response] = await Promise.all([
        api.put('/user/info', {
          ...editUser,
          gallery: images ? [...editUser.gallery, ...images] : editUser.gallery,
        }),
      ]);
      enqueueSnackbar('Successfully update the profile!', {
        variant: 'success',
      });
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));
      window.location.href = '/profile';
    } catch (error) {
      enqueueSnackbar(`Could not update the profile! Error: ${error}`, {
        variant: 'error',
      });
      console.log(error);
    }
  };

  const deletePictureFromGallery = async (id, url) => {
    try {
      await api.delete(`/user/gallery/`, {
        data: {
          id,
          url,
        },
      });
      enqueueSnackbar('Successfully delete the picture!', {
        variant: 'success',
      });

      // update the editUser state
      const newGallery = editUser.gallery.filter((item) => item._id !== id);
      setEditUser({ ...editUser, gallery: newGallery });
    } catch (error) {
      enqueueSnackbar(`Could not delete the picture! Error: ${error}`, {
        variant: 'error',
      });
      console.log(error);
    }
  };

  return (
    <>
      <form
        className="card-body border rounded border-secondary"
        onSubmit={(e) => submitData(e)}>
        <div className="row">
          <div className="col">
            <h5>SKANZ Profile Edit</h5>
            <div className="form-group">
              <label className="form-control-label">Logo Style</label>
              <Select
                styles={shapeStyles}
                className="light-input"
                defaultValue={{
                  value: user.outProfileLogoStyle,
                  label: user.outProfileLogoStyle,
                }}
                options={options}
                onChange={(e) => {
                  setEditUser({
                    ...editUser,
                    outProfileLogoStyle: e.value,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-control-label">Color Picker</label>
              <HexColorPicker
                color={color}
                onChange={(e) => {
                  setColor(e);
                  setEditUser({
                    ...editUser,
                    profileIconColor: {
                      ...editUser.profileIconColor,
                      secondary: e,
                    },
                  });
                }}
                className="mb-3"
                style={{ zIndex: 0 }}
              />
              <input
                className="light-input form-control"
                type="text"
                value={color}
                onChange={(e) => {
                  editUser.profileIconColor.secondary = e.target.value;
                  setColor(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-control-label">Profile Type</label>
              <Select
                styles={shapeStyles}
                className="light-input"
                defaultValue={{
                  value: user.profileType,
                  label: user.profileType,
                }}
                options={optionsProfile}
                onChange={(e) => {
                  setEditUser({
                    ...editUser,
                    profileType: e.value,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-control-label">Gallery Pictures</label>
              <input
                className="light-input form-control"
                type="file"
                multiple
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <PlacesAutocomplete
                  setAddress={setAddressAndCoords}
                  address={editUser?.address || ''}
                />
                {/* <div className="form-group">
                  <label className="form-control-label">Latitude</label>
                  <input
                    class="light-input form-control"
                    type="number"
                    value={editUser.latitude}
                    onChange={(e) => {
                      setEditUser({
                        ...editUser,
                        latitude: e.target.value,
                      });
                    }}
                  />
                </div> */}
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Longitude</label>
                  <input
                    class="light-input form-control"
                    type="number"
                    value={editUser.longitude}
                    onChange={(e) => {
                      setEditUser({
                        ...editUser,
                        longitude: e.target.value,
                      });
                    }}
                  />
                </div>
              </div> */}
            </div>
            {/* if gallery legnth is bigger than 0 show the images */}
            {editUser.gallery.length > 0 && (
              <div className="form-group">
                <label className="form-control-label">Gallery Pictures</label>
                <div className="row">
                  {editUser.gallery.map((image) => (
                    <div
                      className="col-11 col-md-5 ml-3 mb-3 position-relative d-flex flex-column align-items-center justify-content-center border border-secondary rounded mr-3"
                      key={image._id}>
                      <img
                        src={image.url}
                        alt="gallery"
                        // fit the image to constrain the size
                        style={{
                          objectFit: 'contain',
                          width: '200px',
                          height: '150px',
                        }}
                        className="img-fluid p-2"
                      />
                      <div
                        onClick={() => {
                          deletePictureFromGallery(image._id, image.url);
                        }}>
                        <i className="fas fa-trash text-danger" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col text-right">
            <button
              type="submit"
              className="btn btn-sm btn-outline-warning mr-2 mt-3">
              Update
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditUserPremium;
