import React, {useState} from "react";

import Header from "./components/header";
import Business from "./components/business";
import Create from "../admin/events/components/create";
import Details from '../admin/events/components/details';
import ModalQrCode from './components/qrcodeModal'

const UserBusiness = () => {
    const [page, setPage] = useState('business');
    const [eventId, setEventId] = useState(null);
    const [modalQR, setModalQR] = useState(false);

    const setPageToBusiness = () => {
        setPage('business');
    }

    return (
        <>
        <Header />
        {page === 'business' && (
            <Business setPage={setPage} setEventId={setEventId}/>
        )}
        {page === 'create' && (
            <Create setPage={setPageToBusiness} adminDashBoard={false} />
        )}
        {page === 'event' && (
            <Details setPage={setPageToBusiness} adminDashBoard={false} eventIdProps={eventId} setModalQR={setModalQR}/>
        )}
        {modalQR && <ModalQrCode modalQR={modalQR} setModalQR={setModalQR} />}
        </>
    );
};

export default UserBusiness;