import React, { useState, useEffect } from "react";

import { urls } from "../../../utils/constants";
import api from "../../../utils/api";

const AddSocialModal = ({ setEditUserModal }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("@SKANZ/user"))
  );

  const submitData = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put("/user/info", user);
      localStorage.setItem("@SKANZ/user", JSON.stringify(data.user));
      window.location.href = "/profile";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 10000,
        backgroundColor: "#00000050",
      }}
    >
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit User
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setEditUserModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={(e) => submitData(e)}>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-control-label">First name</label>
                      <input
                        class="form-control"
                        type="text"
                        required
                        value={user.firstName}
                        onChange={(e) =>
                          setUser({ ...user, firstName: e.target.value })
                        }
                        placeholder="Ex.: John"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Last name</label>
                      <input
                        class="form-control"
                        type="text"
                        required
                        value={user.lastName}
                        onChange={(e) =>
                          setUser({ ...user, lastName: e.target.value })
                        }
                        placeholder="Ex.: Doe"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Phone Number</label>
                      <input
                        class="form-control"
                        type="text"
                        required
                        value={user.phone}
                        onChange={(e) =>
                          setUser({ ...user, phone: e.target.value })
                        }
                        placeholder="Ex.: +1-123-456-7890"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Contact Email</label>
                      <input
                        class="form-control"
                        type="text"
                        value={user?.contactEmails?.length > 0 ? user?.contactEmails[0] : ""}
                        onChange={(e) =>
                          setUser({ ...user, contactEmails: [e.target.value] })
                        }
                        placeholder="Ex.: email@company.com"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">About</label>
                      <textarea
                        value={user.bio}
                        onChange={(e) =>
                          setUser({ ...user, bio: e.target.value })
                        }
                        class="form-control"
                        rows={3}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSocialModal;
