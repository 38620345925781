import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Header from "../../components/header/header";
import Content from "./components/content";
import Footer from "../../components/footer";

const Home = () => {
  const history = useHistory();

  /*useEffect(() => {
    // Redirect the user to https://skanz.io
    window.location.href = "https://skanz.io";
  }, []);*/

  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
};

export default Home;