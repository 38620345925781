import { UseUser } from './hooks/User/user';
import vCardsJS from './vCard';
import api from './api';
import download from 'downloadjs';
import { urls } from './constants';

const user = localStorage.getItem('@SKANZ/user');

export async function userSet(userId) {
  const { data, isSuccess } = await UseUser(userId);
  if (isSuccess)
    localStorage.setItem('@SKANZ/user', JSON.stringify(data.data.user));
}

export async function saveContact(user) {
  const getEmail = (user) => {
    if (user?.contactEmails?.length > 0) {
      if (user?.contactEmails[0]) return user.contactEmails[0];
      return user?.email;
    }
    return user?.email;
  };

  const getPhone = (user) => {
    if (user?.contactPhones?.length > 0) {
      if (user?.contactPhones[0]) return user.contactPhones[0];
      return user?.phone;
    }
    return user?.phone;
  };

  const getBase64FromUrl = async (url) => {
    const response = await api.post(`/user/image`, { url });
    return response.data;
  };
  var vCard = vCardsJS();
  vCard.firstName = user?.firstName;
  vCard.lastName = user?.lastName;
  vCard.email = getEmail(user);
  user?.social?.forEach((social) => {
    vCard.socialUrls[social?.kind?.toString().toLowerCase()] = String(
      social?.data,
    );
  });
  vCard.cellPhone = getPhone(user);

  if (user?.company) vCard.organization = user?.company;

  if (user?.profilePicture && !user?.profilePicture.includes('newUser')) {
    let fileFormat = user?.profilePicture
      ?.substr(user?.profilePicture?.lastIndexOf('.') + 1)
      ?.toUpperCase();

    const image = await getBase64FromUrl(user?.profilePicture);

    if (
      !(fileFormat === 'PNG' || fileFormat === 'JPG' || fileFormat === 'JPEG')
    )
      fileFormat = 'PNG';

    vCard.photo.embedFromString(image, `image/${fileFormat}`);
  }

  if (user?.contactEmails?.length > 0) {
    if (user?.contactEmails?.length === 2) {
      vCard.workEmail = user?.contactEmails[1];
    } else if (user?.contactEmails?.length > 2) {
      vCard.workEmail = user?.contactEmails[1];
      vCard.otherEmail = user?.contactEmails[2];
    }
  }

  if (user?.contactPhones?.length > 0) {
    if (user?.contactPhones?.length === 2) {
      vCard.workPhone = user?.contactPhones[1];
    } else if (user?.contactPhones?.length > 2) {
      vCard.workPhone = user?.contactPhones[1];
      vCard.otherPhone = user?.contactPhones[2];
    }
  }

  vCard.version = '3.0';

  await api.post(`/user/contact/email`);

  download(
    vCard.getFormattedString(),
    `${user?.firstName}${user?.lastName}.vcf`,
    'text/x-vcard',
  );
}

export const findSocial = (item) => {
  for (let i = 0; i < urls.length; i++) {
    if (item.kind == urls[i].kind) {
      return urls[i];
    }
  }
  return { social: '', source: 'iconmonstr-link-1.svg', kind: 'Url' };
};
