import React, { useState } from 'react';

import Header from '../components/header';
import Events from './components/index';
import Create from './components/create';

const EventsDashboard = () => {
  const [page, setPage] = useState('list');

  return (
    <>
      <Header />
      {page === 'list' && (
        <Events setPage={setPage} />
      )}
      {page === 'create' && (
        <Create setPage={setPage} />
      )}
    </>
  );
};

export default EventsDashboard;
