import React, { useState, useEffect, useMemo } from 'react';
import QRCode from 'qrcode.react';
import FeatherIcon from 'feather-icons-react';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import EditEvent from './edit';
import { useHistory } from 'react-router-dom';

import api from '../../../../utils/api';

import Side from '../../components/side';
import './style.css';
import { useEvent } from '../../../../utils/hooks/Events/events';
import { parse } from 'json2csv';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
} from '@mui/material';
import { returnUsDate } from '../../../../utils/time';

const Details = ({
  modalOpen = false,
  setAddTattooModal,
  adminDashBoard = true,
  eventIdProps,
  setPage,
  setModalQR,
}) => {
  const history = useHistory();
  const { eventId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useEvent(eventId ? eventId : eventIdProps);
  const thisUrl = window.location.origin;
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [batches, setBatches] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [file, setFile] = useState({ files: [] });
  const [participants, setParticipants] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getAnalytics();
    getPartiticipants();
  }, [eventIdProps]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(!deleteOpen);
  };

  const getAnalytics = async () => {
    try {
      const id = adminDashBoard ? eventId : eventIdProps;
      const { data } = await api.get(`/event/analytics?eventId=${id}`);
      setAnalytics(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPartiticipants = async () => {
    try {
      const id = adminDashBoard ? eventId : eventIdProps;
      const { data } = await api.get(`/event/participant/email/list/${id}`);

      setParticipants(data.participants);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadParticipants = async () => {
    try {
      const fields = ['email', 'name'];
      const opts = { fields };
      const csv = parse(participants, opts);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'participants.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const onDropFiles = (files) => {
    setFile({
      ...file,
      files: [...files.map((item) => ({ raw: item, timestamp: Date.now() }))],
    });
  };

  const addParticipants = async () => {
    try {
      const id = adminDashBoard ? eventId : eventIdProps;
      if (!file.files.length) return;

      const formData = new FormData();
      formData.append('table', file.files[0]?.raw);

      const response = await api.put(`/event/addParticipants/${id}`, formData);

      getPartiticipants();
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const dispatchEmails = async (onlyNonParticipants = false) => {
    try {
      const id = adminDashBoard ? eventId : eventIdProps;
      const response = await api.post(
        `/event/dispatch/emails/${id}`,
        {},
        { params: { onlyNotContacted: onlyNonParticipants } },
      );

      getPartiticipants();

      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const shownParticipants = useMemo(() => {
    return search.length > 1
      ? participants.filter(
          (item) =>
            [
              item.email,
              ...Object.keys(item.meta).map((_item) => item.meta[_item]),
            ]
              .join(',')
              .toLowerCase()
              .indexOf((search || '').toLowerCase()) !== -1,
        )
      : participants;
  }, [search, participants]);

  const copyParticipantsEmails = () => {
    const emails = participants.map(({ email }) => email).join(',');
    copy(emails);
    enqueueSnackbar('Copied participants emails to clipboard', {
      variant: 'success',
    });
  };

  const ReturnToPage = () => {
    if (adminDashBoard) {
      window.location.href = '/admin/events';
    } else {
      history.push('/business-dashboard/events');
    }
  };

  const removeParticipant = (participant) => {
    const id = adminDashBoard ? eventId : eventIdProps;
    api
      .put(`/event/remove-participant/${id}`, {
        userId: participant.userId,
        email: participant.email,
      })
      .then(() => {
        getPartiticipants();
      });
  };

  const downloadImage = async (code) => {
    const qrCodeURL = document
      .getElementById(`qr-${code}`)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QRCode-${code}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  if (isLoading) {
    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <section
        style={modalOpen ? { filter: 'blur(4px)' } : {}}
        class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            {adminDashBoard && (
              <div class="col-md-2">
                <Side />
              </div>
            )}
            <div className={adminDashBoard ? 'col-md-10' : 'col'}>
              <div class="row align-items-center mb-4">
                <div class="col">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-12 text-lg-left text-center my-lg-0 my-2">
                      <button
                        type="button"
                        class="btn btn-sm btn-warning btn-icon w-100 w-lg-25"
                        onClick={() => ReturnToPage()}>
                        <span class="btn-inner--icon">
                          <FeatherIcon icon="arrow-left" />
                        </span>
                        <span class="btn-inner--text">Return</span>
                      </button>
                    </div>
                    <div className="col-lg-6 col-12 text-center my-lg-0 my-2">
                      <h1 class="h5 mb-0">
                        <span>Event Details</span>
                      </h1>
                    </div>
                    <div className="col-lg-3 col-12 text-lg-left text-center my-lg-0 my-2">
                      <EditEvent
                        open={open}
                        onClose={handleClose}
                        eventId={eventId ? eventId : eventIdProps}
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-warning btn-icon w-100"
                        onClick={() => handleClose()}>
                        <span class="btn-inner--text">Edit Event</span>
                        <span class="btn-inner--icon">
                          <FeatherIcon icon="edit" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => setAddTattooModal(true)}
                  >
                    <span class="btn-inner--text">Create Batch</span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="plus" />
                    </span>
                  </button>
                </div> */}
              </div>
              <div class="card p-4">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      style={{ width: '100%' }}
                      src={data.data.event?.logoUrl}></img>
                    <div>
                      <div class="avatar-parent-child ml-5 mt-4 mb-2">
                        <div
                          style={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: 10,
                            padding: '7px 7px 0 7px',
                          }}>
                          <QRCode
                            onClick={() => {
                              setModalQR(
                                `${thisUrl}/join-event/${data.data.event?.uniqueCode}`,
                              );
                            }}
                            size={50}
                            style={{ borderRadius: 5, cursor: 'pointer' }}
                            value={`${thisUrl}/join-event/${data.data.event?.uniqueCode}`}
                          />
                          <QRCode
                            id={`qr-${data.data.event?.uniqueCode}`}
                            size={1000}
                            style={{ borderRadius: 5, display: 'none' }}
                            value={`${thisUrl}/join-event/${data.data.event?.uniqueCode}`}
                          />
                        </div>
                      </div>
                      <a
                        onClick={() =>
                          downloadImage(data.data.event?.uniqueCode)
                        }
                        class=" action-item mb-0"
                        style={{
                          marginLeft: '45px',
                        }}>
                        Download
                      </a>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Name</span>
                        <h1 class="h5 mb-0">{data.data.event?.name}</h1>
                      </div>
                      <div class="col-md-4">
                        <span>Place</span>
                        <h1 class="h5 mb-0">{data.data.event?.place}</h1>
                      </div>
                      <div class="col-md-4">
                        <span>Unique Code</span>
                        <h1 class="h5 mb-0">{data.data.event?.uniqueCode}</h1>
                      </div>
                    </div>
                    <div class="row mt-2">
                      {/* <div class="col-md-6">
                        <span>Start</span>
                        <h1 class="h5 mb-0">
                          {new Date(data.data.event?.time?.start).toLocaleDateString()}
                        </h1>
                      </div> */}
                      <div class="col">
                        <h1 class="h5 mb-0">
                          {returnUsDate(data.data.event?.time?.finish)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {analytics && (
                <>
                  <h1 class="h5 mb-2">Event Analytics</h1>
                  <div class="card p-3 mb-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: '20px',
                          }}>
                          <div>
                            <div class="grid-item p-2">
                              <span>Number of participants</span>
                              <h1 class="h5 mb-0">
                                <span class="sub-h1">
                                  {data.data.event?.numberOfParticipants}
                                </span>
                              </h1>
                            </div>
                            <span class="small-text">
                              *based on the number of users that joined with QR
                              Code
                            </span>
                          </div>
                          <div>
                            <div class="grid-item p-2">
                              <span>Number of Registrants</span>
                              <h1 class="h5 mb-0">
                                <span class="sub-h1">
                                  {data.data.event?.numberOfRegistrants}
                                </span>
                              </h1>
                            </div>
                            <span class="small-text">
                              *based on the number of users that joined with QR
                              Code
                            </span>
                          </div>
                          <div>
                            <div class="grid-item p-2">
                              <span>Contact Saves</span>
                              <h1 class="h5 mb-0">{analytics?.contactSaves}</h1>
                            </div>
                            <span class="small-text">
                              *based on QR Codes related to the event
                            </span>
                          </div>
                          <div>
                            <div class="grid-item p-2">
                              <span>Connections Made</span>
                              <h1 class="h5 mb-0">
                                {analytics?.connectionsMade}
                              </h1>
                            </div>
                            <span class="small-text">
                              *based on QR Codes related to the event
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* <h1 class="h5 mb-2">Event Batches</h1>
              {batches.map(batch => (
                <div
                  onClick={() =>
                    window.open(`/admin/batch/${batch._id}`, '_blank')
                  }
                  class="card custom-card p-3 mb-3"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <span>Name</span>
                          <h1 class="h5 mb-0">{batch.nickname}</h1>
                        </div>
                        <div class="col-md-6">
                          <span>Created</span>
                          <h1 class="h5 mb-0">
                            {new Date(batch.createdAt).toLocaleDateString()}{' '}
                            {new Date(batch.createdAt).toLocaleTimeString()}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}

              <h1 class="h5 mb-2">Add Participants</h1>
              <FileBox
                files={file.files.map((item) => item.raw)}
                onDropFiles={onDropFiles}
                removeFiles={() => setFile({ ...file, files: [] })}
                handleSubmit={addParticipants}
              />

              <div class="row align-items-center mb-2 mt-4">
                <div class="col">
                  <h1 class="h5 mb-0">
                    <span class="ml-0">Participants</span>
                  </h1>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => dispatchEmails(true)}
                    disabled>
                    <span class="btn-inner--text">Dispatch Non-contacted</span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="x-square" />
                    </span>
                  </button>
                  <button
                    disabled
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => dispatchEmails()}>
                    <span class="btn-inner--text">Dispatch emails</span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="archive" />
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => copyParticipantsEmails()}>
                    <span class="btn-inner--text">
                      Copy participants emails
                    </span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="clipboard" />
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={async () => await downloadParticipants()}>
                    <span class="btn-inner--text">Download participants</span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="clipboard" />
                    </span>
                  </button>
                </div>
              </div>
              <div class="row mb-3 mt-4">
                <div class="col">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    class="form-control-sm form-control"
                    placeholder="Search"
                  />
                </div>
                <div class="col">
                  <button
                    onClick={() => setSearch('')}
                    type="button"
                    class="btn btn-sm btn-warning ">
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="x" />
                    </span>
                  </button>
                </div>
              </div>
              {/* <h1 class="h5 mb-2">Participants</h1> */}
              {shownParticipants.map((participant) => (
                <div
                  // onClick={() =>
                  //   window.open(`/admin/batch/${batch._id}`, '_blank')
                  // }
                  class="card custom-card p-3 mb-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-4">
                          {participant.name ? (
                            <>
                              <h1 class="h5 mb-0">{participant.name}</h1>
                              <h4 class="h6 mb-0 text-secondary">
                                {participant.email}
                              </h4>
                            </>
                          ) : (
                            <h4 class="h5 mb-0">{participant.email}</h4>
                          )}
                        </div>
                        <div class="col-md-2">
                          <span>Contacted</span>
                          {!participant.meta || !participant.meta.setupEmail ? (
                            <div>
                              <span class="badge badge-warning">No</span>
                            </div>
                          ) : (
                            <div>
                              <span class="badge badge-success">{`Yes, last contact: ${new Date(
                                participant.meta.setupEmailLastSent,
                              ).toLocaleDateString()}`}</span>
                            </div>
                          )}
                        </div>
                        <div class="col-md-2">
                          <span>Registered</span>
                          {participant.userId ? (
                            <div>
                              <span class="badge badge-success">Yes</span>
                            </div>
                          ) : (
                            <div>
                              <span class="badge badge-warning">No</span>
                            </div>
                          )}
                        </div>
                        <div class="col-md-2">
                          <span>Sharing Profile</span>
                          {participant.shareProfile ? (
                            <div>
                              <span class="badge badge-success">Yes</span>
                            </div>
                          ) : (
                            <div>
                              <span class="badge badge-warning">No</span>
                            </div>
                          )}
                        </div>
                        <div class="col-md-2">
                          <span>Remove</span>
                          <div>
                            <button
                              type="button"
                              class="badge badge-danger border-0"
                              onClick={() => removeParticipant(participant)}>
                              <span class="btn-inner--icon">
                                <FeatherIcon icon="x" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {adminDashBoard && (
                <>
                  <div class="row align-items-center mb-2 mt-4">
                    <div class="col">
                      <h1 class="h5 mb-0">
                        <span class="ml-0">Actions</span>
                      </h1>
                    </div>
                  </div>
                  <div class="row mb-3 mt-4">
                    <div class="col">
                      <ConfirmDeleteDialog
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                        eventId={eventId ? eventId : eventIdProps}
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-danger btn-icon"
                        onClick={() => {
                          handleDeleteClose();
                        }}>
                        <span class="btn-inner--text">Delete Event</span>
                        <span class="btn-inner--icon">
                          <FeatherIcon icon="trash-2" />
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FileBox = ({ onDropFiles, files, removeFiles, handleSubmit }) => {
  const DEFAULT_PADDING = 25;

  const uploadZoneMessage = (isDragActive, isDragReject) => (
    <>
      {isDragActive && !isDragReject && (
        <p
          style={{
            color: '#78e5d5',
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}>
          Release the file to upload it
        </p>
      )}
      {isDragReject && (
        <p
          style={{
            color: '#e57878',
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}>
          Your file isn't compatible
        </p>
      )}
      {!isDragActive && !isDragReject && !files[0] && (
        <p
          style={{
            color: '#999',
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}>
          Drag your Excel sheet / CSV here or click to select
        </p>
      )}
      {files.map((file) => (
        <div className="preview-container">
          <img
            // className="preview-files"
            src={`/assets/img/svg/icons/xls-file.svg`}
            style={{ height: 30, width: 30 }}
          />
          <p
            style={{
              color: '#999',
              textAlign: 'center',
              padding: DEFAULT_PADDING,
            }}>
            {file.name}
          </p>
        </div>
      ))}
    </>
  );
  return (
    <>
      <Dropzone onDropAccepted={onDropFiles} multiple={false}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            {...getRootProps()}
            className={`file-input-container ${isDragActive && 'drag-active'} ${
              isDragReject && 'drag-reject'
            }`}>
            <input {...getInputProps()} />

            {uploadZoneMessage(isDragActive, isDragReject)}
          </div>
        )}
      </Dropzone>
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          class="btn btn-sm btn-outline-warning"
          onClick={() => removeFiles(false)}>
          Cancel
        </button>

        <button class="btn btn-sm btn-outline-warning" onClick={handleSubmit}>
          Confirm
        </button>
      </div>
    </>
  );
};

function ConfirmDeleteDialog(props) {
  async function handleDelete() {
    await api.delete(`/event/delete/${props.eventId}`);
    props.onClose();
    window.location.href = '/admin/events';
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}>
        <DialogTitle>Delete Event</DialogTitle>
        <DialogContent>
          <div class="row">
            <div class="col">
              <Alert severity="warning">
                Are you sure you want to delete this event? This action cannot
                be undone.
              </Alert>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            class="btn btn-sm btn-danger btn-icon"
            onClick={handleDelete}>
            <span class="btn-inner--text">Delete Event</span>
            <span class="btn-inner--icon">
              <FeatherIcon icon="trash-2" />
            </span>
          </button>
          {/* cancel button */}
          <button
            type="button"
            class="btn btn-sm btn-outline-warning"
            onClick={props.onClose}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Details;
