import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ModalJoinEvent from './modalJoinEvent';
import vCardsJS from 'vcards-js';
import download from 'downloadjs';
import { isMobile } from 'react-device-detect';
import ItemSocial from './socialItem';
import { returnUsDate } from '../../../utils/time';
import FeatherIcon from 'feather-icons-react';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';

import { urls } from '../../../utils/constants';

import api from '../../../utils/api';

const Contacts = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('@SKANZ/user')),
  );
  const { qrCodeToJoin: businessId } = useParams();
  const [modalJoinEvent, setModalJoinEvent] = useState(false);
  const [codeToJoin, setCodeToJoin] = useState('');
  const [contacts, setContacts] = useState([]);
  const [events, setEvents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getContacts();
    getEvents();
    if (businessId) {
      getEvent();
    }
  }, []);

  const getContacts = async () => {
    try {
      const { data } = await api.get('/user/contacts/all');
      setContacts(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getEvents = async () => {
    try {
      const { data } = await api.get('/event/user/events');
      setEvents(data.events.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    try {
      const { data } = await api.get(`/event/business/${businessId}`);
      console.log(data.result[0]);
      setCodeToJoin(data.result[0].uniqueCode);
    } catch (error) {
      setCodeToJoin(businessId);
      setModalJoinEvent(true);
    }
  };

  const saveContactVCard = async (user, setIsDownloading) => {
    setIsDownloading(true);
    var vCard = vCardsJS();
    vCard.firstName = user?.firstName;
    vCard.lastName = user?.lastName;
    vCard.email = getEmail(user);
    user?.social?.forEach((social) => {
      vCard.socialUrls[social?.kind?.toString().toLowerCase()] = String(
        social?.data,
      );
    });
    vCard.cellPhone = getPhone(user);

    if (user?.company) vCard.organization = user?.company;

    if (user?.profilePicture && !user?.profilePicture.includes('newUser')) {
      let fileFormat = user?.profilePicture
        ?.substr(user?.profilePicture?.lastIndexOf('.') + 1)
        ?.toUpperCase();

      const image = await getBase64FromUrl(user?.profilePicture);

      if (
        !(fileFormat === 'PNG' || fileFormat === 'JPG' || fileFormat === 'JPEG')
      )
        fileFormat = 'PNG';

      vCard.photo.embedFromString(image, `image/${fileFormat}`);
    }

    if (user?.contactEmails?.length > 0) {
      if (user?.contactEmails?.length === 2) {
        vCard.workEmail = user?.contactEmails[1];
      } else if (user?.contactEmails?.length > 2) {
        vCard.workEmail = user?.contactEmails[1];
        vCard.otherEmail = user?.contactEmails[2];
      }
    }

    if (user?.contactPhones?.length > 0) {
      if (user?.contactPhones?.length === 2) {
        vCard.workPhone = user?.contactPhones[1];
      } else if (user?.contactPhones?.length > 2) {
        vCard.workPhone = user?.contactPhones[1];
        vCard.otherPhone = user?.contactPhones[2];
      }
    }

    vCard.version = '3.0';

    download(
      vCard.getFormattedString(),
      `${user?.firstName}${user?.lastName}.vcf`,
      'text/x-vcard',
    );
    setIsDownloading(false);
    enqueueSnackbar('Contact saved successfully', {
      variant: 'success',
    });
  };

  const findSocial = (item) => {
    for (let i = 0; i < urls.length; i++) {
      if (item.kind == urls[i].kind) {
        return urls[i];
      }
    }
    return { social: '', source: 'iconmonstr-link-1.svg', kind: 'Url' };
  };

  const getEmail = (user) => {
    if (user?.contactEmails?.length > 0) {
      if (user?.contactEmails[0]) return user.contactEmails[0];
      return user?.email;
    }
    return user?.email;
  };

  const getPhone = (user) => {
    if (user?.contactPhones?.length > 0) {
      if (user?.contactPhones[0]) return user.contactPhones[0];
      return user?.phone;
    }
    return user?.phone;
  };

  const SocialItem = ({ item, isFile = false }) => {
    const social = findSocial(item);
    return (
      <div style={{ margin: 10 }}>
        <a
          style={{
            width: isMobile ? 40 : 50,
            height: isMobile ? 40 : 50,
            display: 'flex',
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--orange)',
          }}
          target="_blank"
          rel="noreferrer"
          href={isFile ? item.url : item.data}>
          <img
            style={{
              height: isMobile ? 20 : 30,
              width: isMobile ? 20 : 30,
            }}
            alt="social"
            src={
              isFile
                ? `/assets/img/svg/icons/pdf-icon.svg`
                : `/assets/img/svg/social/${social.source}`
            }
          />
        </a>
      </div>
    );
  };

  const ContactItem = ({ user }) => {
    const [open, setOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const item = user.user;
    const event = user?.event;
    return (
      <>
        {event && (
          <div style={{ width: '100%' }}>
            <div
              style={{
                marginLeft: '65%',
                backgroundColor: 'var(--orange)',
                padding: '5px 15px 5px 15px',
                borderTopRightRadius: 12,
                borderTopLeftRadius: 12,
                color: '#fff',
                zIndex: 10000,
              }}>
              Event: <strong>{event?.name}</strong>
            </div>
          </div>
        )}
        <div class="row">
          <div class="col-xl-12">
            <div
              style={event?.name ? { borderTopRightRadius: 0 } : {}}
              class="card card-fluid">
              <div onClick={() => setOpen(!open)} class="clickable card-header">
                <div class="row align-items-center pb-3">
                  <div class="col-auto">
                    <form>
                      <input type="file" id="file" name="file" />
                    </form>
                    <div class="avatar rounded-circle" id="editPhoto">
                      <img
                        alt="placeholder"
                        src={item?.profilePicture ?? '/assets/img/newUser.png'}
                        id="profilePhoto"
                        style={{
                          objectFit: 'contain',
                          height: 60,
                          width: 60,
                          backgroundColor: '#EEE',
                        }}
                      />
                    </div>
                  </div>
                  <div class="col ml-md-n2">
                    <span class="d-block h6 mb-0">
                      {`${item.firstName} ${item.lastName}`}
                    </span>
                    <small class="d-block text-muted">{getEmail(item)}</small>
                    <small class="d-block text-muted">{item.phone}</small>
                  </div>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}>
                    {item?.social?.map((social) => (
                      <SocialItem item={social} />
                    ))}
                    {item?.files?.map((social) => (
                      <SocialItem item={social} isFile />
                    ))}
                  </div>
                </div>
              </div>
              {open && (
                <>
                  <hr class="m-0" />
                  <div class="card-body">
                    {item.bio && (
                      <div class="mb-lg-last">
                        <label class="form-control-label">About</label>
                        <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                          {item.bio}
                        </div>
                      </div>
                    )}
                    {item.contactPhones.length === 0 && (
                      <div class="mb-lg-last">
                        <label class="mb-last form-control-label">Phone</label>
                        <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                          {item.phone}
                        </div>
                      </div>
                    )}
                    {item.contactPhones.length > 0 && (
                      <div class="mb-lg-last">
                        <label class="mb-last form-control-label">
                          Contact Phones
                        </label>
                        {item.contactPhones.map((phone) => (
                          <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                            {phone}
                          </div>
                        ))}
                      </div>
                    )}
                    {item.contactEmails.length === 0 && (
                      <div class="mb-lg-last">
                        <label class="mb-last form-control-label">Email</label>
                        <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                          {item.email}
                        </div>
                      </div>
                    )}
                    {item.contactEmails.length > 0 && (
                      <div class="mb-lg-last">
                        <label class="mb-last form-control-label">
                          Contact Emails
                        </label>
                        {item.contactEmails.map((email) => (
                          <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                            {email}
                          </div>
                        ))}
                      </div>
                    )}
                    <button
                      onClick={() => saveContactVCard(item, setIsDownloading)}
                      type="button"
                      class="btn btn-outline-warning btn-sm btn-block mt-4">
                      {isDownloading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <span>Download Contact</span>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const EventContactItem = ({ item }) => {
    const [otherOpen, setOtherOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    return (
      <>
        <div
          class="card card-fluid m-0 mt-3 p-3"
          style={{ backgroundColor: '#1d1d1d' }}
          key={item.userId._id}
          onClick={() => {
            setOtherOpen(!otherOpen);
          }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div class="avatar rounded-circle mr-3" id="editPhoto">
              <img
                alt="placeholder"
                src={item?.userId?.profilePicture ?? '/assets/img/newUser.png'}
                id="profilePhoto"
                style={{
                  objectFit: 'contain',
                  height: 50,
                  width: 50,
                  backgroundColor: '#EEE',
                }}
              />
            </div>
            <div>
              <div>
                <span class="d-block h6 mb-0">
                  {item.userId.firstName + ' ' + item.userId.lastName}
                </span>
                {item.userId.company && item.userId.position ? (
                  <small class="d-block">
                    {item.userId.position} - {item.userId.company}
                  </small>
                ) : (
                  <>
                    {item.userId.company && (
                      <small class="d-block">{item.userId.company}</small>
                    )}
                    {item.userId.position && (
                      <small class="d-block">{item.userId.position}</small>
                    )}
                  </>
                )}
              </div>
              {!isMobile && (
                <div className="row">
                  {item?.userId?.social.map((item, index) => (
                    <ItemSocial
                      item={item}
                      id={item?.userId?.social.indexOf(item)}
                      isLast={index + 1 == item?.userId?.social.length}
                      user={user}
                      setUser={setUser}
                      isOnProfile={false}
                    />
                  ))}
                </div>
              )}
            </div>
            <h4 className="ml-auto user-select-none">
              {otherOpen ? (
                <FeatherIcon icon="chevron-up" />
              ) : (
                <FeatherIcon icon="chevron-down" />
              )}
            </h4>
          </div>
          {isMobile && (
            <div className="row">
              {item?.userId?.social.map((item, index) => (
                <>
                  <ItemSocial
                    item={item}
                    id={item?.userId?.social.indexOf(item)}
                    isLast={index + 1 == item?.userId?.social.length}
                    user={user}
                    setUser={setUser}
                    isOnProfile={false}
                  />
                </>
              ))}
            </div>
          )}
        </div>
        {otherOpen && (
          <>
            <hr class="m-0" />
            <div class="card-body">
              {item.userId.bio && (
                <div class="mb-lg-last">
                  <label class="form-control-label">About</label>
                  <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                    {item.userId.bio}
                  </div>
                </div>
              )}
              {item.userId.contactPhones.length === 0 && item.userId.phone && (
                <div class="mb-lg-last">
                  <label class="mb-last form-control-label">Phone</label>
                  <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                    {item.userId.phone}
                  </div>
                </div>
              )}
              {item.userId.contactPhones.length > 0 && (
                <div class="mb-lg-last">
                  <label class="mb-last form-control-label">
                    Contact Phones
                  </label>
                  {item.userId.contactPhones.map((phone) => (
                    <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                      {phone}
                    </div>
                  ))}
                </div>
              )}
              {item.userId.contactEmails.length === 0 && (
                <div class="mb-lg-last">
                  <label class="mb-last form-control-label">Email</label>
                  <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                    {item.userId.email}
                  </div>
                </div>
              )}
              {item.userId.contactEmails.length > 0 && (
                <div class="mb-lg-last">
                  <label class="mb-last form-control-label">
                    Contact Emails
                  </label>
                  {item.userId.contactEmails.map((email) => (
                    <div class="mb-last card light-input pt-1 pb-1 pl-2 pr-2">
                      {email}
                    </div>
                  ))}
                </div>
              )}
              <button
                onClick={() => saveContactVCard(item.userId, setIsDownloading)}
                type="button"
                class="btn btn-outline-warning btn-sm btn-block mt-4">
                {isDownloading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Download Contact</span>
                )}
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  const EventItem = ({ event }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <div class="row">
          <div class="col-xl-12">
            <div class="card card-fluid">
              <div
                onClick={() => {
                  setOpen(!open);
                }}
                class="clickable card-header">
                <div class="row align-items-center pb-3">
                  <img src={event.logoUrl} alt="Event logo" className="w-15" />
                  <div class="col ml-md-n2">
                    <span class="d-block h6 mb-0">{event.name}</span>
                    <small class="d-block">{event.place}</small>
                    {event?.time?.start && (
                      <small class="d-block text-muted">
                        {returnUsDate(event?.time?.finish)}
                      </small>
                    )}
                  </div>
                  <h3 class="user-select-none">
                    {open ? (
                      <FeatherIcon icon="chevron-up" />
                    ) : (
                      <FeatherIcon icon="chevron-down" />
                    )}
                  </h3>
                </div>
              </div>
              {open && (
                <>
                  <hr class="m-0" />
                  <div class="card-body">
                    <h6>Participants ({event.participants.length})</h6>
                    <div>
                      {event.participants.map((item) => (
                        <>
                          <EventContactItem item={item} />
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <section class="slice slice-sm bg-section-secondary">
        <div class="container min-vh-100">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div>
                <div class="row align-items-center mb-3">
                  <div class="col">
                    <h6 class="mb-0">Your Events</h6>
                  </div>
                </div>
                {events.map((item) => {
                  return <EventItem event={item} key={item._id} />;
                })}
                <div class="row align-items-center mb-3">
                  <div class="col">
                    <h6 class="mb-0">Your Contacts</h6>
                  </div>
                </div>
                {contacts.map((item) => (
                  <ContactItem user={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalJoinEvent && (
        <ModalJoinEvent
          setModalJoinEvent={setModalJoinEvent}
          codeToJoin={codeToJoin}
        />
      )}
    </>
  );
};

const getBase64FromUrl = async (url) => {
  const response = await api.post(`/user/image`, { url });
  return response.data;
};

export default Contacts;
