import React from "react";

import Header from "./components/header";
import Settings from "./components/settings";

const UserSettings = () => {
  return (
    <>
      <Header />
      <Settings />
    </>
  );
};

export default UserSettings;
