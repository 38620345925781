import { useEffect, useState } from 'react';
import api from '../../../utils/api';
import { UseBusiness } from '../../../utils/hooks/Organization/business';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/user';
import getTier from '../../../utils/getTier';

const Subscription = () => {
  const user = useStore((state) => state.user);
  const fetchUser = useStore((state) => state.fetchUser);
  const [subscription, setSubscription] = useState([]);
  const { data: business } = UseBusiness(user.organizationId);

  useEffect(() => {
    fetchUser(user);
  }, []);

  async function handleSubscription() {
    try {
      const { data } = await api.post('/order/create-portal-session', {
        customerId: user.customerId,
      });
      window.location = data.url;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="row ">
        <div className="col-lg-12">
          <Card
            status={user.plans.isEventIncluded}
            title={'Events Organization Subscription'}
            isEventIncluded={user.plans.isEventIncluded}
            handleSubscription={handleSubscription}
          />
          <Card
            employee={user.maxOwners}
            status={user.plans.isOrganization && user.maxOwners > 0}
            title={
              'Employee Organization Subscription Tier ' +
              getTier(user.plans.planType)
            }
            isEventIncluded={false}
            handleSubscription={handleSubscription}
          />
        </div>
      </div>
    </>
  );
};

const Card = ({
  title,
  status,
  employee = 0,
  planType,
  handleSubscription,
}) => {
  return (
    <div className="card p-4 mt-4 w-100">
      <div className="card-body rounded">
        <div className="card-text d-flex flex-column justify-content-center align-items-baseline">
          <p className="h5 font-weight-light text-white mb-0">{title}</p>
          {employee !== 0 && (
            <p className="h4 font-weight-bold text-white mb-3">
              <strong>
                {employee ?? 0}
                <span className="text-muted" style={{ fontSize: '14px' }}>
                  {' '}
                  Employees Limit
                </span>
              </strong>
            </p>
          )}
          <h6 className="h6 mb-4 font-weight-bold text-white">
            STATUS:{' '}
            <span
              className={`badge-${
                status ? 'success' : 'danger'
              } px-2 py-1 ml-2 rounded`}>
              {status ? 'ACTIVE' : 'Unactive'}
            </span>
          </h6>
          <div className="row w-100">
            {status && (
              <div className="col-4">
                <button
                  className="btn btn-outline-warning btn-sm mt-2 w-100"
                  onClick={handleSubscription}>
                  Manage in Stripe
                </button>
              </div>
            )}
            {status === false && (
              <div className="col-4">
                <Link to="/cart" className="w-100">
                  <button className="btn btn-warning btn-sm mt-2 w-100">
                    Get this plan
                  </button>
                </Link>
              </div>
            )}
            {planType !== 'enterprise_employee' && employee !== 0 && (
              <div className="col-4">
                <Link to="/cart">
                  <button className="col-12 btn btn-warning btn-sm mt-2 ml-4 w-100">
                    Upgrade Plan
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
