import React, { useState, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import { isMobile } from 'react-device-detect';

import useOutsideAlerter from '../../../utils/index';

const Header = () => {
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));

  const [showMenu, setShowMenu] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const menuRef = useRef(null);
  const menuRefMobile = useRef(null);
  useOutsideAlerter(menuRef, () => {
    !showMenu && setShowMenu(false);
  });
  useOutsideAlerter(menuRefMobile, () => {
    !showMenuMobile && setShowMenuMobile(false);
  });

  const getCurrentPath = path => {
    const { pathname } = window.location;
    if (pathname === path) return 'active';
    return '';
  };

  const openMenu = () => {
    if (!isMobile) {
      setShowMenu(!showMenu)
    } else {
      setShowMenuMobile(!showMenuMobile)
    }
  }

  return (
    <>
      {isMobile && showMenuMobile && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#000000aa',
            top: 65,
            left: 0,
            zIndex: 1000000,
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
          }}
        >
          <div
            ref={menuRefMobile}
            style={{
              width: '100vw',
              padding: 20,
              backgroundColor: '#1f1f1f',
              borderBottomRightRadius: 16,
              borderBottomLeftRadius: 16,
            }}
          >
            <div class="dropdown-divider"></div>
            <h6 class="px-0 mb-2 text-warning">
              {`${user.firstName} ${user.lastName}`}
            </h6>
            <div>
              <a style={{ color: '#C7C7C7' }} href="/profile">
                <FeatherIcon icon="user" />{' '}
                <span style={{ marginLeft: 5 }}>My Profile</span>
              </a>
            </div>
            <div>
              <a
                href={`${
                  user.roleId === 'admin' ? '/admin/dashboard' : '/dashboard'
                }`}
                style={{ color: '#C7C7C7' }}
              >
                <FeatherIcon icon="activity" />{' '}
                <span style={{ marginLeft: 5 }}>Dashboard</span>
              </a>
            </div>
            <div>
              <a style={{ color: '#C7C7C7' }} href="/settings">
                <FeatherIcon icon="settings" />{' '}
                <span style={{ marginLeft: 5 }}>Settings</span>
              </a>
            </div>
            <div>
              <span
                onClick={() => {
                  localStorage.clear();
                  window.location.href = '/';
                }}
                style={{ color: '#C7C7C7' }}
              >
                <FeatherIcon icon="log-out" />{' '}
                <span style={{ marginLeft: 5 }}>Sign Out</span>
              </span>
            </div>
          </div>
        </div>
      )}
      <nav
        class="navbar navbar-main navbar-expand-lg shadow navbar-dark bg-dark"
        id="navbar-main"
      >
        <div class="container">
          <a class="navbar-brand order-lg-1" href="/">
            <img
              alt="placeholder"
              src="/assets/img/brand/logo.png"
              id="navbar-logo"
            />
          </a>
          <div
            class="collapse navbar-collapse navbar-collapse-overlay order-lg-3"
            id="navbar-main-collapse"
          >
            <div class="position-relative">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-main-collapse"
                aria-controls="navbar-main-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FeatherIcon icon="x" />
              </button>
            </div>
            <ul
              style={{ marginRight: -50, marginTop: 50 }}
              class="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto"
            >
              <li class="nav-item dropdown dropdown-animate">
                <div
                  ref={menuRef}
                  class={`${
                    showMenu && 'show'
                  } dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow p-3`}
                >
                  <h6 class="dropdown-header px-0 mb-2 text-warning">
                    {`${user.firstName} ${user.lastName}`}
                  </h6>
                  <a href="/profile" class="dropdown-item">
                    <FeatherIcon icon="user" />
                    <span>My Profile</span>
                  </a>
                  <a
                    href={`${
                      user.roleId === 'admin'
                        ? '/admin/dashboard'
                        : '/dashboard'
                    }`}
                    class="dropdown-item"
                  >
                    <FeatherIcon icon="activity" />
                    <span>Dashboard</span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a href="/settings" class="dropdown-item">
                    <FeatherIcon icon="settings" />
                    <span>Settings</span>
                  </a>
                  <span
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = '/';
                    }}
                    style={{ cursor: 'pointer' }}
                    class="dropdown-item"
                  >
                    <FeatherIcon icon="log-out" />
                    <span>Sign Out</span>
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <div class="order-lg-4 ml-lg-3">
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => openMenu()}
            >
              <span class="avatar rounded-circle">
                <img
                  style={{
                    objectFit: 'contain',
                    height: 40,
                    width: 40,
                    backgroundColor: '#EEE',
                  }}
                  alt="placeholder"
                  src={user?.profilePicture ?? '/assets/img/newUser.png'}
                />
              </span>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
