import React, { useState } from 'react';

import Header from './components/header';
import Tattoos from './components/tattoos';
import BatchList from './components/batchDetails';
import AddTattooModal from './components/addTattooModal';
import PrintTattooModal from './components/printTattooModal';
import PrintBatchModal from './components/printBatchModal';
import Modal from './components/modal';

const AdminDashboard = () => {
  const [addTattooModal, setAddTattooModal] = useState(false);
  const [printTattooModal, setPrintTattooModal] = useState(false);
  const [page, setPage] = useState('list');

  return (
    <>
      <Header />
      {page === 'list' && (
        <Tattoos
          modalOpen={addTattooModal || printTattooModal}
          setPage={setPage}
          setAddTattooModal={setAddTattooModal}
        />
      )}
      {addTattooModal && (
        <AddTattooModal setAddTattooModal={setAddTattooModal} />
      )}
      {printTattooModal && (
        <PrintTattooModal
          setPrintTattooModal={setPrintTattooModal}
          printTattooModal={printTattooModal}
        />
      )}
    </>
  );
};

export default AdminDashboard;
