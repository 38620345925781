import React, { useState } from 'react';
import download from 'downloadjs';

import api from '../../../utils/api';
import { Select } from '@mui/base';

const PrintBatchModal = ({ printBatch, setPrintBatch }) => {
  const [amount, setAmount] = useState(1);
  const [file, setFile] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('');

  const [error, setError] = useState('');

  const submitData = async e => {
    e.preventDefault();
    setError('');

    const formData = new FormData();
    if (file) formData.append('image', file);

    let currentCodes = printBatch.map(function (item) {
      return item.code;
    });

    let codes = [];
    for (let i = 0; i < currentCodes.length; i++) {
      for (let j = 0; j < amount; j++) {
        codes.push(`${process.env.REACT_APP_BASE_QR}${currentCodes[i]}`);
      }
    }

    console.log(codes);

    console.log(formData);
    fetch(
      `${process.env.REACT_APP_API_URL}/admin/pdf?codes=${JSON.stringify(
        codes,
      )}&invert=${backgroundColor === 'black' ? 'true' : 'false'}`,
      {
        headers: {
          Accept: 'application/pdf',
        },
        method: 'POST',
        responseType: 'blob',
        body: formData,
      },
    )
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
        if (response.status === 400) {
          return response.json();
        }
      })
      .then(blob => {
        if (blob?.error) {
          return setError(blob?.error);
        }
        const url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `QRCode-${new Date().getTime()}.pdf`);
        link.click();
      })
      .catch(error => {
        setError("Something wen't wrong.");
        console.log(error);
      });
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 10000,
        backgroundColor: '#00000050',
      }}
    >
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Print Tattoos
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setPrintBatch(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {error && (
                <div class="alert alert-danger text-center">{error}</div>
              )}
              <form onSubmit={e => submitData(e)}>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-control-label">Logo</label>
                      <input
                        id="logoUpload"
                        class="form-control code-label"
                        type="file"
                        placeholder="Logo"
                        onChange={e => setFile(e.target.files[0])}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Amount of Each</label>
                      <input
                        class="form-control code-label"
                        type="text"
                        onChange={e => setAmount(e.target.value)}
                        value={amount}
                        required
                        placeholder="Ex.: 1"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">
                        QR Code Background Color
                      </label>
                      <select
                        value={backgroundColor}
                        onChange={e => setBackgroundColor(e.target.value)}
                        class="custom-select"
                        name="type"
                        required
                      >
                        <option value="white" selected>
                          White
                        </option>
                        <option value="black">Black</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintBatchModal;
