import React, { useState, useEffect } from 'react';

import Header from './components/header';
import Cart from './components/cart';

const UserCart = () => {
  return (
    <>
      <Header />
      <Cart />
    </>
  );
};

export default UserCart;
