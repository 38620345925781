import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';

import api from '../../../utils/api';

const Content = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Step 1: Create loading state


  const submitData = async e => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      await api.post('/auth/forgotPassword', { email });
      setSuccess(
        `An email was sent to ${email}, please follow the instructions.`,
      );
    } catch (error) { // Moved the catch block outside of the try block
      if (error.response.data === 'Token invalid') {
        setError('The token is invalid, please try again.');
      } else {
        setError('The email is not registered in our system, please try again.');
      }
      console.log(error);
    }
  };
  

  return (
    <div class="container-fluid d-flex flex-column">
      <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
        <div class="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div class="row justify-content-center">
            <div class="col-11 col-lg-10 col-xl-6">
              <div>
                <div class="mb-5">
                  <h6 class="h3 mb-1">Forgot Password</h6>
                  <p class="text-muted mb-0">
                    Please enter your e-mail in order to continue.
                  </p>
                </div>
                <span class="clearfix"></span>
                {error && (
                  <div class="alert alert-danger text-center">{error}</div>
                )}
                {success && (
                  <div class="alert alert-success text-center">{success}</div>
                )}
                {!success && (
                  <form onSubmit={e => submitData(e)}>
                    <div class="form-group">
                      <label class="form-control-label">Email address</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <FeatherIcon icon="user" />
                          </span>
                        </div>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="name@example.com"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div class="mt-3">
                    <button type="submit" class="btn btn-block btn-warning">
                      {loading ? (
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        'Send'
                      )}
                    </button>
                  </div>
                  </form>
                )}
                <div class="mt-4">
                  Remembered your password?{' '}
                  <a href="/login" class="font-weight-bold">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
