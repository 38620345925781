import React, { useState } from 'react';

import Header from './components/header';
import Contacts from './components/contacts';

const UserContacts = () => {

  return (
    <>
      <Header />
      <Contacts/>
    </>
  );
};

export default UserContacts;
