/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery, useMutation, useQueryClient } from 'react-query';
import api from '../../api';
import axios from 'axios';

const fetchEvent = (eventId) => {
  return api.get(`/event/${eventId}`);
};

const fetchEventOrgId = (eventId) => {
  return api.get(`/event/business/${eventId}`);
};
const updateEvent = async (Event) => {
  let response;
  let logoUrl;
  if (Event.image) {
    response = await api.post('/upload/signedUrl', {
      fileExtension: Event.image.type.split('/')[1],
    });
    await axios.put(response.data.signedUrl.url, Event.image, {
      headers: {
        'Content-Type': Event.image.type,
      },
    });
    logoUrl =
      `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
      response.data.signedUrl.key;
  }
  await api.put(`/event/update/${Event._id}`, {
    name: Event.name,
    place: Event.place,
    time: Event.time,
    uniqueCode: Event.uniqueCode,
    logoUrl: Event.image ? logoUrl : Event.logoUrl,
    shareProfile: Event.shareProfile,
  });
};

export const useEvent = (eventId) => {
  return useQuery(['event', eventId], () => fetchEvent(eventId));
};

export const useEventByOrgId = (ownerId, config) => {
  if (config) {
    return useQuery(
      ['eventByOrgId', ownerId],
      () => fetchEventOrgId(ownerId),
      config,
    );
  } else {
    return useQuery(['event', ownerId], () => fetchEventOrgId(ownerId));
  }
};

export const useUpdateEvent = () => {
  const client = useQueryClient();
  return useMutation(updateEvent, {
    onSuccess: (data) => {
      client.invalidateQueries(['event']);
    },
  });
};
