import React, { useState } from 'react';
import download from 'downloadjs';

import api from '../../../utils/api';

const PrintTattooModal = ({ printTattooModal, setPrintTattooModal }) => {
  const [amount, setAmount] = useState(1);
  const [current, setCurrent] = useState(printTattooModal);
  const [selected, setSelected] = useState(`${printTattooModal.code ?? ''}`);
  const [file, setFile] = useState(null);

  const [error, setError] = useState('');

  const submitData = async e => {
    e.preventDefault();
    setError('');

    const formData = new FormData();
    if (file) formData.append('image', file);

    let currentCodes = selected.split(',');

    let codes = [];
    for (let i = 0; i < currentCodes.length; i++) {
      for (let j = 0; j < amount; j++) {
        codes.push(`${process.env.REACT_APP_BASE_QR}${currentCodes[i]}`);
      }
    }

    console.log(codes);

    console.log(formData);
    fetch(
      `${process.env.REACT_APP_API_URL}/admin/pdf?codes=${JSON.stringify(
        codes,
      )}`,
      {
        headers: {
          Accept: 'application/pdf',
        },
        method: 'POST',
        responseType: 'blob',
        body: formData,
      },
    )
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
        if (response.status === 400) {
          return response.json();
        }
      })
      .then(blob => {
        if (blob?.error) {
          return setError(blob?.error);
        }
        const url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `QRCode-${new Date().getTime()}.pdf`);
        link.click();
      })
      .catch(error => {
        setError("Something wen't wrong.");
        console.log(error);
      });
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 10000,
        backgroundColor: '#00000050',
      }}
    >
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Print Codes
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setPrintTattooModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {error && (
                <div class="alert alert-danger text-center">{error}</div>
              )}
              <form onSubmit={e => submitData(e)}>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-control-label">Logo</label>
                      <input
                        id="logoUpload"
                        class="form-control code-label"
                        type="file"
                        placeholder="Logo"
                        onChange={e => setFile(e.target.files[0])}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Amount of Each</label>
                      <input
                        class="form-control code-label"
                        type="text"
                        onChange={e => setAmount(e.target.value)}
                        value={amount}
                        required
                        placeholder="Ex.: 1"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">
                        QR Codes To Print
                      </label>
                      <input
                        class="form-control code-label"
                        type="text"
                        onChange={e => setSelected(e.target.value)}
                        value={selected}
                        required
                        placeholder="Ex.: A000001,A000002"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintTattooModal;
