import React from 'react';
import { createRoot } from 'react-dom/client';

import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

const tagManagerArgs = {
  gtmId: 'GTM-5CGGRQS',
  auth: process.env.GTM_AUTH,
  preview: process.env.GTM_PREVIEW,
};

// Initialize the TagManager with tagManagerArgs
TagManager.initialize(tagManagerArgs);

root.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </QueryClientProvider>
  </SnackbarProvider>
);
