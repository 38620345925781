import { useState } from 'react';
import { UseBusiness } from '../../../utils/hooks/Organization/business';
import CreateBusinessModal from '../../admin/business/components/createBusinessModal';
import EditBusinessModal from '../../admin/business/components/editBusinessModal';
import AddOwnerModal from '../../admin/business/components/addOwnerModal';
import api from '../../../utils/api';
import QRCode from 'qrcode.react';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import getTier from '../../../utils/getTier';
import { useStore } from '../../../store/user';
import axios from 'axios';

const uploadFiles = async (files) => {
  const images = [];
  for (const file of files) {
    const response = await api.post('/upload/signedUrl', {
      fileExtension: file.name.split('.').pop()[1],
    });
    await axios.put(response.data.signedUrl.url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    images.push({
      url:
        `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
        response.data.signedUrl.key,
    });
  }
  return images;
};

const Dashboard = () => {
  const user = useStore((state) => state.user);
  const {
    data: business,
    isError,
    isLoading,
    refetch,
  } = UseBusiness(user.organizationId);
  const [open, setOpen] = useState(false);
  const [addImages, setAddImages] = useState(false);
  const [files, setFiles] = useState([]);
  const toggleModal = () => {
    setOpen(!open);
  };
  const toggleAddImages = () => {
    setAddImages(!addImages);
  };
  const [isLoadingStyle, setIsLoadingStyle] = useState(false);
  const options = [
    { value: 'Square', label: 'Small' },
    { value: 'Wide', label: 'Big' },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const thisUrl = window.location.origin;

  const getBusiness = async (businessId) => {
    try {
      const { data } = await api.get(`/business/${business?._id}/owners`);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const removeOwner = async (ownerId) => {
    try {
      await api.delete(`business/${business?._id}/owner`, {
        data: { id: ownerId },
      });
      getBusiness();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadImage = async (code) => {
    const qrCodeURL = document
      .getElementById(code)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QRCode-${code}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const submitStyle = async (option) => {
    try {
      setIsLoadingStyle(true);
      const style = option.value;
      await api.put(`/business/${business._id}`, {
        outProfileLogoStyle: style,
      });
      setIsLoadingStyle(false);
      enqueueSnackbar('Successfully changed logo style!', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        'There was some error changing your logo style, please contact us.',
        {
          variant: 'error',
        },
      );
      setIsLoadingStyle(false);
    }
  };

  const addImagesToBussiness = async () => {
    try {
      if (files.length + business.gallery.length > 3) {
        enqueueSnackbar('You can only upload 3 images', {
          variant: 'error',
        });
        return;
      }
      const images = await uploadFiles(files);
      await api.post(`/business/${business._id}/gallery`, {
        urls: images ? [...business.gallery, ...images] : business.gallery,
      });
      enqueueSnackbar('Successfully added images!', {
        variant: 'success',
      });
      toggleAddImages();
      refetch();
    } catch (error) {
      console.log(error.response);
      enqueueSnackbar(
        'There was some error adding your images, please contact us.',
        {
          variant: 'error',
        },
      );
    }
  };

  const removeImage = async (url) => {
    try {
      await api.delete(`/business/${business._id}/gallery`, {
        data: { url },
      });
      enqueueSnackbar('Successfully removed image!', {
        variant: 'success',
      });
      refetch();
      business.gallery = business.gallery.filter((image) => image.url !== url);
    } catch (error) {
      console.log(error.response);
      enqueueSnackbar(
        'There was some error removing your image, please contact us.',
        {
          variant: 'error',
        },
      );
    }
  };

  if (isError) {
    return (
      <>
        <div className="row">
          <div className="col">
            <div className="card p-4 mt-4 text-center">
              <div className="card-body">
                <h5>It seems like you don't have any organization yet.</h5>
                <p className="card-text text-start">Create one right now</p>
                <CreateBusinessModal userId={user._id} refetch={refetch} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card p-4 mt-4">
            <div className="row g-0 justify-content-start">
              <div className="col-md-4">
                <img
                  src={business.logoUrl}
                  alt="Org Logo"
                  className="avatar-image"></img>
              </div>
              <div class="col-md-8">
                <div class="card-body pt-lg-2 pt-5">
                  <div className="row align-items-center mb-3">
                    <div className="col">
                      <h1 className="h3 mb-0">{business.name}</h1>
                      <h1 className="h6 text-info mb-0">
                        {user.plans.isOrganization &&
                          'Employee Organization Subscription Tier '}{' '}
                        {getTier(user.plans.planType)}
                        {user.plans.isOrganization &&
                          user.plans.isEventIncluded && <>{' | '}</>}
                        {user.plans.isEventIncluded &&
                          'Events Organization Subscription'}
                      </h1>
                    </div>
                    <div className="col-auto">
                      <EditBusinessModal
                        business={business}
                        refetch={refetch}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col">
                      <h1 className="h3">
                        {user.maxOwners}
                        <span className="h6 text-muted ml-1">
                          Max Employees
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="card-text mb-0 d-flex">
                        <span className="h5 font-weight-bolder text-warning mr-2">
                          SKANZ
                        </span>{' '}
                        Profile Color:
                        <div
                          style={{
                            backgroundColor: business.profileColor,
                            width: '25px',
                            height: '25px',
                            display: 'inline-block',
                            marginLeft: '6px',
                            borderRadius: '25%',
                          }}></div>
                      </p>
                    </div>
                  </div>
                  <div>
                    <h6 className="mt-4 mb-0">Select Logo Style</h6>
                    <Select
                      defaultValue={{
                        value: business.outProfileLogoStyle,
                        label: business.outProfileLogoStyle,
                      }}
                      options={options}
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      onChange={submitStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card p-4">
            <div className="row d-flex align-items-center justify-content-between mb-0">
              <div>
                <h1 className="h3 mb-0 ml-3">Employees</h1>
              </div>
              <div className="mr-3">
                <button
                  type="button"
                  className="btn btn-sm btn-warning btn-icon"
                  onClick={toggleModal}>
                  <span className="btn-inner--text">Add Employee</span>
                  <AddOwnerModal
                    open={open}
                    onClose={toggleModal}
                    owners={business?.owners}
                    updateDetails={getBusiness}
                    businessId={business?._id}
                    codeToJoin={business?.codeToJoin}
                  />
                </button>
              </div>
            </div>
            <div className="row mt-4 mx-1 py-3 border border-neutral bg-dark rounded">
              <div className="col-md-12">
                {business?.owners.length > 0 ? (
                  business?.owners.map((owner, index) => (
                    <div
                      className="row align-items-center"
                      key={owner._id}
                      style={{
                        marginBottom:
                          index + 1 === business?.owners.length
                            ? '0.5rem'
                            : '3rem',
                      }}>
                      <div className="col-auto">
                        <img
                          style={{ height: '120px' }}
                          src={owner.profilePicture}
                          className="rounded"
                          alt="Owner Profile"></img>
                      </div>
                      <div className="col">
                        <div>
                          <h1 className="h5 mb-0">{owner.firstName}</h1>
                          <h1 className="h6 text-muted mb-0">{owner.email}</h1>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <h1 className="h4 mb-0">
                              {owner.profileViews}
                              <span className="h6 ml-1 font-weight-light text-muted">
                                Scan Count
                              </span>
                            </h1>
                          </div>
                          <QRCode
                            id={`${owner._id}-qr`}
                            value={`${thisUrl}/${owner.userslug}`}
                            hidden
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-12 mt-2">
                            <a href={`/out-profile/${owner.userslug}`}>
                              <button className="btn btn-sm btn-warning btn-icon w-100">
                                <span className="btn-inner--text">
                                  View Profile
                                </span>
                              </button>
                            </a>
                          </div>
                          <div className="col-md-4 col-12 mt-2">
                            <button
                              onClick={() => downloadImage(`${owner._id}-qr`)}
                              className="btn btn-sm btn-outline-warning btn-icon w-100">
                              <span className="btn-inner--text">
                                Download QRCode
                              </span>
                            </button>
                          </div>
                          <div className="col-md-auto col-12 mt-2">
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-danger border-0 btn-icon w-100"
                              onClick={() => removeOwner(owner._id)}>
                              <span className="btn-inner--text">Remove</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3 d-flex justify-content-end  mb-auto">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger border-0 btn-icon"
                            style={{ width: '116px' }}
                            onClick={() => removeOwner(owner._id)}>
                            <span className="btn-inner--text">Remove</span>
                          </button>
                        </div> */}
                    </div>
                  ))
                ) : (
                  <h1 className="h5 mb-0">No Employees</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card p-4">
            <div className="row d-flex align-items-center justify-content-between mb-0">
              <h1 className="h3 mb-0 ml-3">Gallery</h1>
              <div className="mr-3">
                <button
                  type="button"
                  className="btn btn-sm btn-warning btn-icon"
                  onClick={toggleAddImages}>
                  <span className="btn-inner--text">Add Images</span>
                </button>
              </div>
            </div>
            {addImages && (
              <div className="row mt-4 mx-1 py-3 border border-neutral bg-dark rounded">
                <div className="form-group w-100 m-4 d-flex align-items-center justify-content-between">
                  <input
                    class="light-input form-control w-50"
                    type="file"
                    multiple
                    onChange={(e) => {
                      setFiles(e.target.files);
                    }}
                  />
                  <div>
                    <button
                      className="btn btn-sm btn-warning btn-icon"
                      onClick={toggleAddImages}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-sm btn-warning btn-icon"
                      onClick={addImagesToBussiness}>
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div
              className="row mt-4 mx-1 py-3 border border-neutral bg-dark rounded align-items-end"
              style={{ gap: '10px' }}>
              {business?.gallery.length > 0 ? (
                business?.gallery.map((image, index) => (
                  <div className="col-md-3 align-items-center" key={image._id}>
                    <div
                      className="d-flex flex-column"
                      style={{ gap: '10px', maxWidth: '200px' }}>
                      <img
                        style={{ maxWidth: '200px' }}
                        src={image.url}
                        className="rounded"
                        alt="Owner Profile"></img>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger border-0 btn-icon"
                        onClick={() => {
                          removeImage(image.url);
                        }}>
                        <span className="btn-inner--text">Remove</span>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="h5 mb-0">No Images</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
