import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Side from '../../components/side';
import FeatherIcon from 'feather-icons-react';
import AddOwnerModal from './addOwnerModal';
import EditBusinessModal from './editBusinessModal';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import api from '../../../../utils/api';

const getBusiness = async businessId => {
  try {
    const { data } = await api.get(`/business/${businessId}/owners`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default function BusinessDetails() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { businessId } = useParams();
  const { data, isLoading, isError } = useQuery(['business', businessId], () =>
    getBusiness(businessId),
  );

  const toggleModal = () => {
    setOpen(!open);
  };

  const toggleEditModal = () => {
    setOpenEdit(!openEdit);
  };

  const removeOwner = async ownerId => {
    try {
      await api.delete(`business/${businessId}/owner`, {
        data: { id: ownerId },
      });
      getBusiness();
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <section className="slice py-5 bg-section-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Side />
            </div>
            <div className="col-md-10">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (isError) {
    return (
      <section className="slice py-5 bg-section-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Side />
            </div>
            <div className="col-md-10">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div>
                  <Link to={`/admin/business/`}>
                    <button
                      type="button"
                      className="btn btn-sm btn-warning btn-icon ml-4"
                      style={{ width: '116px' }}>
                      <span className="btn-inner--icon">
                        <FeatherIcon icon="arrow-left" />
                      </span>
                      <span className="btn-inner--text">Return</span>
                    </button>
                  </Link>
                  <span className="sr-only">Error loading data</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="slice py-5 bg-section-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Side />
            </div>
            {Object.keys(data).length > 0 && (
              <div className="col-md-10">
                <div className="row align-items-center mb-4">
                  <div className="col">
                    <h1 className="h5 mb-0">
                      <Link to={`/admin/business/`}>
                        <button
                          type="button"
                          className="btn btn-sm btn-warning btn-icon ml-4"
                          style={{ width: '116px' }}>
                          <span className="btn-inner--icon">
                            <FeatherIcon icon="arrow-left" />
                          </span>
                          <span className="btn-inner--text">Return</span>
                        </button>
                      </Link>
                      <span className="ml-3">Organization Details</span>
                    </h1>
                  </div>
                  <div className="col-auto">
                      <EditBusinessModal
                        business={data}
                        open={openEdit}
                        onClose={toggleEditModal}
                        updateDetails={getBusiness}
                      />
                  </div>
                </div>
                <div className="card p-4">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        style={{ width: '100%' }}
                        src={data.logoUrl}
                        alt="Business Logo"
                        className="avatar-image"></img>
                    </div>
                    <div className="col-md-9">
                      <span>Name</span>
                      <h1 className="h5 mb-0">{data.name}</h1>
                    </div>
                  </div>
                </div>
                <div className="card p-4">
                  <div className="row d-flex align-items-center justify-content-between mb-0">
                    <div>
                      <h1 className="h5 mb-0 ml-3">Owners</h1>
                    </div>
                    <div className="mr-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-warning btn-icon"
                        onClick={toggleModal}>
                        <span className="btn-inner--text">Add Owner</span>
                        <AddOwnerModal
                          open={open}
                          onClose={toggleModal}
                          owners={data.owners}
                          businessId={data._id}
                          updateDetails={getBusiness}
                        />
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      {data.owners ? (
                        data.owners.map((owner, index) => (
                          <div
                            className="row align-items-center"
                            key={owner._id}
                            style={{
                              marginBottom:
                                index + 1 === data.owners.length
                                  ? '0.5rem'
                                  : '3rem',
                            }}>
                            <div className="col-md-3">
                              <img
                                style={{ width: '100%' }}
                                src={owner.profilePicture}
                                alt="Owner Profile"
                                className="avatar-image"></img>
                            </div>
                            <div className="col-md-6">
                              <span>Name</span>
                              <h1 className="h5 mb-0">{owner.firstName}</h1>

                              <span>Email</span>
                              <h1 className="h5 mb-0">{owner.email}</h1>

                              <span>Phone</span>
                              <h1 className="h5 mb-0">{owner.phone}</h1>
                            </div>
                            <div className="col-md-3 d-flex justify-content-end  mb-auto">
                              <button
                                type="button"
                                className="btn btn-sm btn-warning btn-icon"
                                style={{ width: '116px' }}
                                onClick={() => removeOwner(owner._id)}>
                                <span className="btn-inner--text">Remove</span>
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1 className="h5 mb-0">No Owners</h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
