import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const Side = () => {
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const { route } = useParams()
  return (
    <>
      <div>
        <ul className="nav nav-menu overflow-x flex-nowrap flex-md-column mx-n2">
        <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="home" />
              Home
            </h6>
            <ul className="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li className="nav-item px-2">
                <Link to="/business-dashboard">
                  <FeatherIcon className="mr-3" icon="users" />
                  Dashboard
                </Link>
              </li>
            </ul>
            <hr />
          </li>
          {user?.plans.isEventIncluded && (
                      <li class="nav-item px-2">
                      <h6>
                        <FeatherIcon className="mr-3" icon="map" />
                        Events
                      </h6>
                      <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
                        <li class="nav-item px-2">
                          <Link to="/business-dashboard/events"
                          >
                            <FeatherIcon className="mr-3" icon="map" />
                            All Events
                          </Link>
                        </li>
                      </ul>
                      <hr />
                    </li>
          )}
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="dollar-sign" />
              Subscription
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2 text-start w-100">
                <Link to="/business-dashboard/subscription">
                  <FeatherIcon className="mr-3" icon="dollar-sign" />
                Manage
                </Link>
              </li>
              {/* <li class="nav-item px-2 text-start w-100">
                <Link to="/business-dashboard/plans">
                  <FeatherIcon className="mr-3" icon="dollar-sign" />
                  Plans
                </Link>
              </li> */}
            </ul>
            <hr />
          </li>
        </ul>
      </div>
    </>
  )
}

export default Side;