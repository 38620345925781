import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import axios from 'axios';

import api from '../../../../utils/api';

import Side from '../../components/side';

const Events = ({ setPage, modalOpen = false, adminDashBoard = true }) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [event, setEvent] = useState({
    name: null,
    linkPrefix: '',
    place: null,
    initialIssue: 0,
    uniqueCode: '',
    time: { start: null, finish: null },
    qrCode: false,
    shareProfile: true,
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const submitData = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/upload/signedUrl', {
        fileExtension: image.type.split('/')[1],
      });
      await axios.put(response.data.signedUrl.url, image, {
        headers: {
          'Content-Type': image.type,
        },
      });
      const logoUrl =
        `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
        response.data.signedUrl.key;
      if (adminDashBoard) {
        const { data } = await api.post('/event', {
          ...event,
          logoUrl,
        });
      } else {
        const { data } = await api.post(`/event/owner/${user._id}`, {
          ...event,
          logoUrl,
        });
      }
      if (adminDashBoard) {
        setPage('list');
      } else {
        history.push('/business-dashboard/events');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section
        style={modalOpen ? { filter: 'blur(4px)' } : {}}
        class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            {adminDashBoard && (
              <div class="col-md-2">
                <Side />
              </div>
            )}
            <div className={adminDashBoard ? 'col-md-10' : 'col'}>
              <div class="row align-items-center mb-4">
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => {
                      if (adminDashBoard) {
                        setPage('list');
                      } else {
                        history.push('/business-dashboard/events');
                      }
                    }}>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="arrow-left" />
                    </span>
                    <span class="btn-inner--text">Return</span>
                  </button>
                </div>
                <div class="col">
                  <h1 class="h5 mb-0">Create Event</h1>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      {error && (
                        <div class="alert alert-danger text-center">
                          {error}
                        </div>
                      )}
                      <form onSubmit={(e) => submitData(e)}>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">Name</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Ex.: Event name"
                                  value={event.name}
                                  onChange={(e) =>
                                    setEvent({ ...event, name: e.target.value })
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">Code</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Ex.: JSAD7"
                                  value={event.uniqueCode}
                                  onChange={(e) =>
                                    setEvent({
                                      ...event,
                                      uniqueCode: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">Place</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Ex.: Howard Convention Center"
                                  value={event.place}
                                  onChange={(e) =>
                                    setEvent({
                                      ...event,
                                      place: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">Logo</label>
                              <div class="input-group">
                                <input
                                  type="file"
                                  class="form-control"
                                  placeholder="Ex.: Logo.png"
                                  onChange={(e) => setImage(e.target.files[0])}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          {/* <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">
                                Start Date
                              </label>
                              <div class="input-group">
                                <input
                                  value={event.time.start}
                                  onChange={e =>
                                    setEvent({
                                      ...event,
                                      time: {
                                        start: e.target.value,
                                        finish: event.time.finish,
                                      },
                                    })
                                  }
                                  type="date"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div> */}
                          <div class="col">
                            <div class="form-group">
                              <label class="form-control-label">
                                Event Date
                              </label>
                              <div class="input-group">
                                <input
                                  value={event.time.finish}
                                  onChange={(e) =>
                                    setEvent({
                                      ...event,
                                      time: {
                                        start: e.target.value,
                                        finish: e.target.value,
                                      },
                                    })
                                  }
                                  type="date"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2"
                                value={event.qrCode}
                                onChange={(e) => {
                                  setEvent({
                                    ...event,
                                    qrCode: e.target.checked,
                                  });
                                }}
                              />
                              <label class="form-check-label">
                                Qrcode to Join?
                              </label>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2"
                                onChange={(e) => {
                                  setEvent({
                                    ...event,
                                    shareProfile: !e.target.checked,
                                  });
                                }}
                              />
                              <label class="form-check-label">
                                Disable automatic sharing of profiles
                              </label>
                            </div>
                          </div>
                        </div>
                        {/*<div class="form-group">
                          <label class="form-control-label">
                            QR Code Amount
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              placeholder="Ex.: 50"
                              class="form-control"
                              value={event.initialIssue}
                              onChange={e =>
                                setEvent({
                                  ...event,
                                  initialIssue: e.target.value,
                                })
                              }
                            />
                          </div>
                            </div>*/}
                        <div class="mt-4">
                          <button
                            type="submit"
                            class="btn btn-block btn-warning">
                            Create event
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
