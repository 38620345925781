import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import QRCode from 'qrcode.react';
import download from 'downloadjs';

import api from '../../../utils/api';

import Side from './side';

const Tattoos = ({
  modalOpen,
  setAddTattooModal,
  setSelectedBatch,
  setPage,
}) => {
  const [batches, setBatches] = useState([]);
  const [filter, setFilter] = useState('');
  const [codes, setCodes] = useState([]);
  const [qrCodes, setQrCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBatches();
  }, []);

  const downloadImage = async (code, index) => {
    const qrCodeURL = document
      .getElementById(`qr-${index}`)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QRCode-${code}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const getBatches = async (item) => {
    try {
      const { data } = await api.get(`/admin/qrcode/batch/list`);
      console.log(data);
      setBatches(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCodes = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/qrcode/search/${filter}`);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getAllQrCodes = async () => {
    try {
      const { data } = await api.get('/admin/qrcode/list');
      console.log(data);
      setQrCodes([...data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCodes([]);
    getAllQrCodes();
    if (filter?.length > 1) getCodes();
  }, [filter]);

  return (
    <>
      {qrCodes.map((qrcode) => (
        <div key={qrcode._id}>
          <QRCode
            size={500}
            id={qrcode._id}
            style={{
              display: 'none',
            }}
            value={`aa${process.env.REACT_APP_BASE_QR}${qrcode.code}`}
          />
        </div>
      ))}
      <section
        style={modalOpen ? { filter: 'blur(4px)' } : {}}
        class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <Side />
            </div>
            <div class="col-md-10">
              <div class="row align-items-center mb-4">
                <div class="col">
                  <h1 class="h5 mb-0">Codes</h1>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning btn-icon"
                    onClick={() => setAddTattooModal(true)}>
                    <span class="btn-inner--text">Create Batch</span>
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="plus" />
                    </span>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <div class="col">
                      <input
                        class="form-control-sm form-control"
                        placeholder="Search"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                    <div class="col">
                      <button
                        onClick={() => setFilter('')}
                        class="btn btn-sm btn-warning">
                        <span class="btn-inner--icon">
                          <FeatherIcon icon="x" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {loading && (
                <div
                  class="card p-4 mb-3"
                  style={{
                    color: '#FFFFFF',
                  }}>
                  <CircularProgress
                    style={{ margin: '0 auto' }}
                    color="inherit"
                  />
                </div>
              )}
              {!loading && filter?.length > 1 && codes?.length === 0 && (
                <div
                  class="card p-4 mb-3"
                  style={{
                    color: '#FFFFFF',
                  }}>
                  No codes found.
                </div>
              )}
              {codes.map((item, index) => (
                <div class="card p-4 mb-3">
                  <div class="row">
                    <div class="col-md-3">
                      <span>Code</span>
                      <h1 class="h5 mb-0">{item.code}</h1>
                    </div>
                    <div class="col-md-3">
                      <span>Status</span>
                      {item?.active ? (
                        <div>
                          <span class="badge badge-success">Active</span>
                        </div>
                      ) : (
                        <div>
                          <span class="badge badge-warning">Inactive</span>
                        </div>
                      )}
                    </div>
                    <div class="col-md-3">
                      <span>Image File</span>
                      <div>
                        <span
                          style={{ cursor: 'pointer' }}
                          class="badge badge-primary"
                          onClick={() => downloadImage(item.code, index)}>
                          Download PNG
                        </span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <span>Details</span>
                      <div>
                        <span
                          style={{ cursor: 'pointer' }}
                          class="badge badge-success"
                          onClick={() =>
                            window.open(`/admin/qr/${item._id}`, '_blank')
                          }>
                          View Details
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        display: 'none',
                      }}>
                      <QRCode
                        id={`qr-${index}`}
                        size={1000}
                        style={{ borderRadius: 5 }}
                        value={`${process.env.REACT_APP_BASE_QR}${item.code}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {batches
                .filter((item) =>
                  item.nickname?.toLowerCase().includes(filter.toLowerCase()),
                )
                .reverse()
                .map((batch) => (
                  <div
                    onClick={() => {
                      window.location.href = `/admin/batch/${batch._id}`;
                    }}
                    class="card custom-card p-3 mb-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <span>Name</span>
                            <h1 class="h5 mb-0">{batch.nickname}</h1>
                          </div>
                          <div class="col-md-6">
                            <span>Created</span>
                            <h1 class="h5 mb-0">
                              {new Date(batch.createdAt).toLocaleDateString()}{' '}
                              {new Date(batch.createdAt).toLocaleTimeString()}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tattoos;
