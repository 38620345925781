/* logs users in and stores token in local storage */

import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const joinEventLink = JSON.parse(localStorage.getItem("@SKANZ/joinEventLink"));

api.interceptors.request.use(
  async function (config) {
    const bearer = "Bearer " + localStorage.getItem('@SKANZ/token');
    config.headers.authorization = bearer
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    if (response.data.token) {
      storeToken(response.data.token);
      storeUser(response.data.user);
    }

    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('@SKANZ/token');
      localStorage.removeItem('@SKANZ/user');
      if(joinEventLink) return (window.location.href = `/login`);
      else return (window.location.href = '/login');
    }
    return Promise.reject(error);
  }
);

async function storeToken(token) {
  localStorage.setItem('@SKANZ/token', token);
}

async function storeUser(user) {
  localStorage.setItem('@SKANZ/user', JSON.stringify(user));
}

export const isAuthenticated = () => {
  if (!localStorage.getItem('@SKANZ/token')) return false;

  return localStorage.getItem('@SKANZ/token').length > 5;
};

export default api;
