import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/user';

import api from '../../../utils/api';

const Settings = () => {
  const user = useStore((state) => state.user);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [orders, setOrders] = useState([]);
  const [requests, setRequests] = useState([]);
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    getOrders();
    getRequests();
  }, []);

  const getOrders = async () => {
    try {
      const { data } = await api.get('/order/all');
      setOrders(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getRequests = async () => {
    try {
      const { data } = await api.get('/requests');
      // Check if the data.requests has the user id inside, if it is inside, set hasRequested to true
      if (data.requests.includes(user._id)) {
        setHasRequested(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const requestToBeOwner = async () => {
    try {
      await api.put(`/requests/add`, { id: user._id });
      getRequests();
    } catch (error) {
      console.log(error);
    }
  };

  const submitChangePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (newPassword !== repeatPassword)
      return setError('Passwords do not match.');
    try {
      const { email } = user;
      await api.post('/auth/signIn', {
        email,
        password: oldPassword,
      });
      await api.put('/user/info', { password: newPassword });
      setSuccess('Password successfully changed.');
    } catch (error) {
      console.log(error);
      if (error.response?.data?.error) setError(error.response.data.error);
    }
  };

  const getColor = (code) => {
    if (code == -1) return 'danger';
    if (code == 0) return 'warning';
    if (code == 1) return 'warning';
    if (code == 2) return 'warning';
    if (code == 3) return 'success';
  };

  return (
    <>
      <div class="slice slice-sm bg-section-secondary">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-5">
                    <div class="mb-4">
                      <h5 class="mb-2">Purchases</h5>
                      <div class="row">
                        <div class="col-md-12">
                          {orders.length == 0 && (
                            <label class="form-control-label">
                              No purchases found.
                            </label>
                          )}
                          {orders.map((order) => (
                            <div
                              style={{
                                borderRadius: '.375rem',
                                padding: '8px 16px',
                              }}
                              className="card mb-3">
                              <div class="row">
                                <div class="col-md-3">
                                  <label class="form-control-label">Date</label>
                                  <h6>
                                    {new Date(
                                      order.createdAt,
                                    ).toLocaleDateString()}
                                  </h6>
                                </div>
                                <div class="col-md-3">
                                  <label class="form-control-label">
                                    Amount
                                  </label>
                                  <h6>{order.amount}</h6>
                                </div>
                                <div class="col-md-3">
                                  <label class="form-control-label">
                                    Receipt
                                  </label>
                                  <br />
                                  {order.statusCode >= 2 ? (
                                    <a href={order.receipt}>Download</a>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                                <div class="col-md-3">
                                  <label class="form-control-label">
                                    Status
                                  </label>
                                  <h6>
                                    <span
                                      className={`badge badge-${getColor(
                                        order.statusCode,
                                      )}`}>
                                      {order.statusMessage.toUpperCase()}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <h5 class="mb-2">Details</h5>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">E-Mail</label>
                            <h6>{user?.email}</h6>
                            {/* shows user role with a badge */}
                            <label class="form-control-label">Role</label>
                            <h6>
                              <span className={`badge badge-primary`}>
                                {user?.roleId.toUpperCase()}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {user.roleId !== 'employee' &&
                      user.roleId !== 'admin' &&
                      user.roleId !== 'org_owner' && (
                        <div class="mb-4">
                          <h5 class="mb-2">Subscription</h5>
                          <div class="row">
                            <div class="col-md-6">
                              <label
                                class="form-check-label"
                                for="flexCheckDefault">
                                Want to become an organization owner? You can
                                now do so! Checkout in our{' '}
                                <Link to="/cart" className="link-primary">
                                  store
                                </Link>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    <div>
                      <h5 class="mb-2">Password</h5>
                      {error && (
                        <div class="alert alert-danger text-center">
                          {error}
                        </div>
                      )}
                      {success && (
                        <div class="alert alert-success text-center">
                          {success}
                        </div>
                      )}
                      <form onSubmit={(e) => submitChangePassword(e)}>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">
                                Old password
                              </label>
                              <input
                                required
                                class="light-input form-control"
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="col-md-6"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">
                                New password
                              </label>
                              <input
                                required
                                class="light-input  form-control"
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">
                                Confirm password
                              </label>
                              <input
                                required
                                class="light-input  form-control"
                                type="password"
                                value={repeatPassword}
                                onChange={(e) =>
                                  setRepeatPassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div class="mt-3 text-right">
                          {/*<a
                            onClick={() => alert("Not implemented.")}
                            class="btn btn-sm btn-neutral"
                          >
                            I forgot my password
                          </a>*/}
                          <button type="submit" class="btn btn-sm btn-warning">
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
