import React, { useState } from 'react';

import Header from './components/header';
import BatchDetails from './components/batchDetails';
import PrintBatchModal from './components/printBatchModal';

const AdminDashboard = () => {
  const [printBatch, setPrintBatch] = useState(false);

  return (
    <>
      <Header />
      <BatchDetails modalOpen={printBatch} setPrintBatch={setPrintBatch} />
      {printBatch && (
        <PrintBatchModal
          setPrintBatch={setPrintBatch}
          printBatch={printBatch}
        />
      )}
    </>
  );
};

export default AdminDashboard;
