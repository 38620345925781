export function returnUsDate(date) {
  if(!date) {
    return '';
  }
  const replacedDate = date.replace(/-/g, '/');
  return new Date(replacedDate).toLocaleDateString('en-US',
  {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  });
}