import React from "react";

import Content from "./components/content";

const ExternalProfile = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default ExternalProfile;
