import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components';

import api from '../../../utils/api';

/*import { 
  createScanRecord,
  updateAccount
} from '../../../utils/questIntegration';*/

const LogoHeader = styled.img`
  width: 50%;
  margin-bottom: 40px;
  @media (min-width: 991px) {
    display: none;
  }
`;



const Content = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleGoogleLoginSuccess = async (googleData) => {
    console.log(googleData);
    try {
      const res = await api.post('auth/google', { token: googleData.tokenId });
      console.log(res.data);

      handleRedirect(res.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleLoginFailure = async (error) => {
    console.log(error);
    setError(JSON.stringify(error));
  };

  const submitData = async (e) => {
    e.preventDefault();
    setError('');
    if (!email) return setError('Email required.');
    if (!password) return setError('Password required.');

    try {
      const { data } = await api.post('/auth/signIn', {
        email,
        password,
      });

      let questResponse = data.questResponse
      
      // Store account id from response in local storage
      //localStorage.setItem('@SKANZ/accountId', questResponse.id);
      //localStorage.setItem('@SKANZ/questToken', questResponse.token);
     // localStorage.setItem('@SKANZ/quests', JSON.stringify(questResponse.quest_ids));
      //localStorage.setItem('@SKANZ/collectionPoints', JSON.stringify(questResponse.collection_points));

      /*const tempCollectionPoint = localStorage.getItem('@SKANZ/tempCollectionPoint');

      // Call the function to create a scan record if tempCollectionPoint exists
      if (tempCollectionPoint) {
        //create scan record on Quest
        const scanRecordResponse = await createScanRecord(questResponse.id, tempCollectionPoint);
      
        //create or update account
        const updateAccountResponse = await updateAccount(questResponse.id, tempCollectionPoint, questResponse.token);
      }*/

      handleRedirect(data.user);
    } catch (error) {
      console.log(error);
      if (error.response?.data?.error) setError(error.response.data.error);
    }
  };

  const handleRedirect = ({ roleId }) => {
    /*if (tempCollectionPointExists) {
      return window.location.href = '/quests';
    }*/

    if (roleId === 'admin') {
      window.location.href = '/admin/dashboard';
    } else {
      window.location.href = '/dashboard';
    }
  };

  //const tempCollectionPointExists = !!localStorage.getItem('@SKANZ/tempCollectionPoint');


  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
        <div className="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div className="row justify-content-center">
            <div className="col-11 col-lg-10 col-xl-6">
              <div>
                <LogoHeader src="/assets/img/brand/logo.png" />
                <div className="mb-5">
                  <h6 className="h3 mb-1">Welcome!</h6>
                  <p className="text-muted mb-0">
                    Sign in to your account to continue.
                  </p>
                </div>
                <span className="clearfix"></span>
                {error && (
                  <div className="alert alert-danger text-center">{error}</div>
                )}
                <form onSubmit={(e) => submitData(e)}>
                  <div className="form-group">
                    <label className="form-control-label">Email address</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FeatherIcon icon="user" />
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <label className="form-control-label">Password</label>
                      </div>
                      <div className="mb-2">
                        <span
                          style={{ cursor: 'pointer' }}
                          className="small text-muted text-underline--dashed border-warning"
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? 'Hide password' : 'Show password'}
                        </span>
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FeatherIcon icon="lock" />
                        </span>
                      </div>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-0 text-right">
                    <a href="/forgot-password" className="small">
                      Forgot Password?
                    </a>
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="btn btn-block btn-warning">
                      Sign in
                    </button>
                  </div>
                </form>


                <div className="py-3 text-center">
                  <span className="text-xs text-uppercase">or</span>
                </div>


                <div className="row">

                  <div className="col-sm-12">
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Log in with Google"
                        onSuccess={(e) => handleGoogleLoginSuccess(e)}
                        onFailure={(e) => handleGoogleLoginFailure(e)}
                        cookiePolicy="single_host_origin"
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="btn btn-block btn-dark btn-icon">
                            <span class="btn-inner--icon">
                              <img
                                src="/assets/img/icons/brands/google.svg"
                                alt="Log in with google"
                              />
                            </span>
                            <span className="btn-inner--text">Google</span>
                          </button>
                        )}
                      />

                  </div>
        
                </div>
                
                <div className="mt-4">
                  Not registered?{' '}
                  <a href="/sign-up" className="font-weight-bold">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
