import React, { useState, useEffect } from 'react';
import './header.css';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const scrollHeader = document.querySelector('#header');
    window.onscroll = () => {
      if (window.pageYOffset > 0) {
        scrollHeader.classList.add('scroll');
      } else {
        scrollHeader.classList.remove('scroll');
      }
    };
  }, []);

  return (
    <>
      <header id="header">
        <nav className="nav-2">
          <MenuIcon className="menuButton" onClick={() => setMenu(!menu)} />
          <a href="/" className="box-logo">
            <img src="/assets/img/brand/logo.png" alt="logo" />
          </a>
          <div className="nav-items">
            <a href="/" className="item">
              Home
            </a>
            <a href="/how-it-works" className="item">
              How it Works
            </a>
            <a href="https://www.skanz.io/shop" className="item">
              Buy QR Codes
            </a>

            <a href="/sign-up" className="item item-login">
              Sign up for free!
            </a>
            <a href="/login" className="item item-login">
              Login
            </a>
          </div>
        </nav>
        <div className={menu ? 'menu-mobile active' : 'menu-mobile'}>
          <div className="header-menu">
            <div className="box-logo">
              <img src="/assets/img/brand/logo.png" alt="logo" />
            </div>
            <CloseIcon className="icon-close" onClick={() => setMenu(!menu)} />
          </div>
          <ul className="itens-menu">
            <li>
              <a href="/" className="item">
                Home
              </a>
            </li>
            <li>
              <a href="/how-it-works" className="item">
                How it Works
              </a>
            </li>
            <li>
              <a href="https://www.skanzshop.com" className="item">
                Buy QR Codes
              </a>
            </li>
            <li>
              <a href="/sign-up" className="item">
                Sign up for free!
              </a>
            </li>
            <li>
              <a href="/login" className="item">
                Login
              </a>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
