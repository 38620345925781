import React from "react";
import FeatherIcon from "feather-icons-react";

const Header = () => {
  return (
    <>
      <a
        href="/login"
        class="btn btn-white btn-icon-only rounded-circle position-absolute zindex-101 left-4 top-4 d-none d-lg-inline-flex"
        data-toggle="tooltip"
        data-placement="right"
        title="Go back"
      >
        <span class="btn-inner--icon">
          <FeatherIcon icon="arrow-left"/>
        </span>
      </a>
      <div
        class="bg-warning position-absolute h-100 top-0 left-0 zindex-100 col-lg-6 col-xl-6 zindex-100 d-none d-lg-flex flex-column justify-content-end"
        data-bg-size="cover"
        data-bg-position="center"
      >
        <img
          src="/assets/img/authentication/img.jpeg"
          alt="bg"
          class="img-as-bg"
        />
      </div>
    </>
  );
};

export default Header;
