export default function getTier(planType) {
  if (planType === 'free') {
    return '0';
  }

  if (planType === 'basic_employee') {
    return 'I';
  }
  if (planType === 'pro_employee') {
    return 'II';
  }
  if (planType === 'enterprise_employee') {
    return 'III';
  }
}
