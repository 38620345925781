import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  useEvent,
  useUpdateEvent,
} from '../../../../utils/hooks/Events/events';

export default function EditEvent(props) {
  const { eventId } = useParams();
  const { data } = useEvent(eventId ? eventId : props.eventId);
  const { mutate: UpdateEvent } = useUpdateEvent();
  const [event, setEvent] = useState(data.data.event);
  const [image, setImage] = useState(null);
  const submitData = async (e) => {
    e.preventDefault();
    const newEvent = {
      ...event,
      image: image ? image : undefined,
    };
    UpdateEvent(newEvent);
    props.onClose();
  };

  useEffect(() => {
    console.log(event);
  }, [event.shareProfile]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            overflow: 'hidden',
            backgroundColor: '#2d2d2d',
            width: '600px',
            height: '500px',
          },
        }}>
        <DialogTitle>Edit Event</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => submitData(e)}>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label">Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Ex.: Event name"
                      value={event.name}
                      onChange={(e) =>
                        setEvent({ ...event, name: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label">Code</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Ex.: JSAD7"
                      value={event.uniqueCode}
                      onChange={(e) =>
                        setEvent({
                          ...event,
                          uniqueCode: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label">Place</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Ex.: Howard Convention Center"
                      value={event.place}
                      onChange={(e) =>
                        setEvent({
                          ...event,
                          place: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-control-label">Logo</label>
                  <div class="input-group">
                    <input
                      type="file"
                      class="form-control"
                      placeholder="Ex.: Logo.png"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              {/* <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control-label">
                                Start Date
                              </label>
                              <div class="input-group">
                                <input
                                  value={event.time.start}
                                  onChange={e =>
                                    setEvent({
                                      ...event,
                                      time: {
                                        start: e.target.value,
                                        finish: event.time.finish,
                                      },
                                    })
                                  }
                                  type="date"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div> */}
              <div class="col">
                <div class="form-group">
                  <label class="form-control-label">Event Date</label>
                  <div class="input-group">
                    <input
                      value={event.time.finish}
                      onChange={(e) =>
                        setEvent({
                          ...event,
                          time: {
                            start: e.target.value,
                            finish: e.target.value,
                          },
                        })
                      }
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">
                    Disable automatic sharing of profiles
                  </label>
                  <div className="input-group">
                    <input
                      type="checkbox"
                      className="form-check-input ml-1 mt-2"
                      checked={!event?.shareProfile}
                      onChange={(e) => {
                        setEvent({
                          ...event,
                          shareProfile: !e.target.checked,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-block btn-warning">
                Edit event
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
