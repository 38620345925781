import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import CircularProgress from '@mui/material/CircularProgress';

import api from '../../../utils/api';

import Side from './side';

function UserItem({user, getRequests}) {

    const handleDecline = async () => {
        try {
            await api.put(`/requests/remove`, {id: user._id});
            getRequests();
        } catch (error) {
            console.log(error);
        }
    }

    const handleAccept = async () => {
        try {
            await api.put(`/user/role/org-owner/${user._id}`, {roleId: 'org_owner'});
            await api.put(`/requests/remove`, {id: user._id});
            getRequests();
        } catch (error) {
            console.log(error);
        }
    }
    
    return (
        <div className="card custom-card mt-3 mb-0">
            <div class="p-3 row">
                <div class="col-md-1">
                    <div class="avatar-container-user">
                    <img
                        alt="placeholder"
                        src={user?.profilePicture ?? '/assets/img/newUser.png'}
                        class="avatar-image ml-2"
                    />
                    </div>
                </div>
                <div class="col-md-3 mt-1">
                    <span>Name</span>
                    <h1 class="h5 mb-0">{`${user?.firstName} ${user?.lastName}`}</h1>
                </div>
                <div class="col-md-4 mt-1">
                    <span class="">E-Mail</span>
                    <h1 class="h5 mb-0 mt-2">{user?.email}</h1>
                </div>
                <div class="col-md-4">
                    <span>Submission</span>
                    <div class="d-flex mr-10 mt-2">
                        <button type="button" class="btn btn-sm btn-warning" onClick={handleDecline}>
                            <span>Decline</span>
                        </button>
                        <button class="btn btn-sm btn-warning" onClick={handleAccept}>
                        <span>Accept</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
     )
}

export default function Requests() {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRequests();
    }, []);

    const getRequests = async () => {
        try {
            const { data } = await api.get('/requests/populated');
            setRequests(data.requests);
            console.log(data.requests);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <>
          <section class="slice py-5 bg-section-secondary">
            <div class="container">
              <div class="row">
                <div class="col-md-2">
                  <Side />
                </div>
                <div class="col-md-10">
                  <div class="row align-items-center mb-4">
                    <div class="col">
                      <h1 class="h5 mb-0">Requests</h1>
                    </div>
                  </div>
                  {loading && (
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-center">
                                <CircularProgress />
                            </div>
                        </div>
                    </div>
                  )}
                  {!loading && requests.length > 0 ? (
                    requests.map(request => (
                        <UserItem user={request} getRequests={getRequests} />
                    ))
                    ) : (
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-center">
                                <h1 class="h5 mb-0">No requests</h1>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        </>
      );
}