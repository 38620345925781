import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Cropper from './cropper';
import api from '../utils/api';
import axios from 'axios';
import { useStore } from '../store/user';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';

export default function UploadImageModal(props) {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useStore((state) => state.user);
  const fetchUser = useStore((state) => state.fetchUser);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const uploadImage = async (file) => {
    try {
      const { data } = await api.post('/upload/signedUrl', {
        fileExtension: file.name.split('.').pop(),
      });
      await axios.put(data.signedUrl.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      return (
        `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
        data.signedUrl.key
      );
    } catch (error) {
      console.log(error);
    }
  };

  const update = async () => {
    setLoading(true);
    const fileUrl = await uploadImage(file);
    let request;
    if (props.type === 'profile') {
      request = {
        profilePicture: fileUrl,
      };
    } else {
      request = {
        outProfileLogo: fileUrl,
      };
    }

    try {
      await api.put('/user/info', request);
      await fetchUser(user);
      setLoading(false);
      enqueueSnackbar('Profile picture updated', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar('Something went wrong, try again or contact us.', {
        variant: 'error',
      });
    }
    handleClose();
  };

  const fileChanger = (file) => {
    setFile(file);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        {props.showEdit ? (
          <button className="btn btn-sm btn-warning" onClick={handleOpen}>
            Edit
            <FeatherIcon icon="edit" className="ml-2" />
          </button>
        ) : (
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: 100,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              cursor: 'pointer',
            }}
            onClick={handleOpen}
            className="btn-edit-photo">
            <FeatherIcon icon="edit-2" />
          </div>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#2d2d2d',
            width: '500px',
            minHeight: '500px',
          },
        }}>
        <DialogTitle className="ml-4 mt-4">
          {props.type === 'profile' ? 'Edit User Picture' : 'Edit Your Logo'}
        </DialogTitle>
        <DialogContent>
          <Cropper setFile={fileChanger} />
        </DialogContent>
        <DialogActions className="mx-3 mb-4">
          <button
            onClick={handleClose}
            className="btn btn-sm btn-warning btn-icon mr-3"
            style={{ height: '39px', width: '85px', marginRight: '1.28rem' }}>
            Cancel
          </button>
          <button
            onClick={update}
            className="btn btn-sm btn-warning btn-icon d-flex align-items-center justify-content-center"
            style={{ height: '39px', width: '85px', marginRight: '1.28rem' }}>
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <span>Save</span>
            )}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
