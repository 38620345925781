import axios from 'axios';


// Function to create a scan record
export const createScanRecord = async (accountId, collectionPointId) => {
  try {
    const response = await axios.post('https://api.quest.circlepass.io/api/scan', {
      scan: {
        collection_point_id: collectionPointId,
        account_id: accountId,
      },
    });

    console.log(response.data); // Log the response data
  } catch (error) {
    console.error(error); // Log any error that occurs
  }
};

export const updateAccount = async (accountId, collectionPointId, questToken, tempQuest) => {
  try {
    // Get the tempQuest from local storage
    console.log(tempQuest)

    const response = await axios.post(
      'https://api.quest.circlepass.io/api/accounts/update',
      {
        account: {
          id: accountId,
          collection_points: [collectionPointId],
          quest_ids: [tempQuest], // Include the quest_ids in the request
        },
      },
      {
        headers: {
          'Authorization': `Bearer ${questToken}`,
        },
      }
    );

    localStorage.setItem('@SKANZ/quests', JSON.stringify(response.data.data.quest_ids));
    localStorage.setItem('@SKANZ/collectionPoints', JSON.stringify(response.data.data.collection_points));

    
    console.log(response); // Log the entire response object
  } catch (error) {
    console.error(error); // Log any error that occurs
  }
};
