import Content from "./components/content";


const JoinEvent = () => {
  return (
    <>
      <Content/>
    </>
  )
}

export default JoinEvent;