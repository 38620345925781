import Header from "../admin/components/header"
import Content from "./components/content"

const BusinessDashboard = () => {
  return (
    <>
    <Header />
    <Content />
    </>
  )
}

export default BusinessDashboard