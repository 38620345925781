import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { isMobile } from 'react-device-detect';
import { Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import api from '../../../utils/api';
import axios from 'axios';

const FileEditor = () => {
  const [files, setFiles] = useState([]);
  const [fileBoxOpen, setFileBoxOpen] = useState(false);
  const [data, setData] = useState({ files: [], nickname: '' });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = async () => {
    try {
      const response = await api.get('/user/file/list');
      setFiles(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDropFiles = (files) => {
    console.log(files);
    // setFiles(files);
    setData({
      ...data,
      files: [...files.map((item) => ({ raw: item, timestamp: Date.now() }))],
    });
  };

  const handleSubmit = async () => {
    try {
      // Get signed url for user files
      const {
        data: { signedUrl },
      } = await api.post('/upload/signedUrl/user');

      console.log(signedUrl);
      console.log('data: ', data);
      console.log('file: ', data.files[0]?.raw);

      // Send the file to S3
      await axios.put(signedUrl.url, data.files[0]?.raw, {
        headers: {
          'Content-Type': data.files[0]?.raw.type,
        },
      });

      // Send the update to backend
      const res = await api.put('/user/file', {
        fileName: data.files[0]?.raw.name,
        url:
          `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
          signedUrl.key,
        nickname: data.nickname,
      });

      if (res.data?.message === 'SUCCESS') {
        await getFiles();
        setFileBoxOpen(false);
        setData({ files: [], nickname: '' });
      }

      console.log({ response_status: res.data });
    } catch (error) {
      console.log(error);
    }
  };

  const removeFile = async (item) => {
    try {
      const response = await api.delete('/user/file', {
        params: { url: item.url },
      });

      getFiles();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleVisibility = async (item) => {
    try {
      await api.put('/user/file/visibility', {
        params: { url: item.url, target: !item.visible },
      });

      getFiles();
      enqueueSnackbar(
        `Successfully ${item.visible ? 'hidden' : 'shown'} ${item.nickname}.`,
        { variant: 'success' },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const FileItem = ({ item, id }) => {
    if (isMobile)
      return (
        <>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <div
              style={{
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 50,
                backgroundColor: 'var(--orange)',
              }}>
              <img
                style={{ height: 30, width: 30 }}
                alt="social"
                src={`/assets/img/svg/icons/pdf-icon.svg`}
              />
            </div>
            <a
              href={item.data}
              style={{ marginLeft: '5%', fontWeight: 600, width: '60vw' }}>
              {item.nickname}
            </a>
          </div>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
            }}>
            <Tooltip
              placement="top"
              arrow
              title={item.visible ? 'Hide on profile' : 'Show on profile'}>
              <div
                onClick={() => toggleVisibility(item)}
                style={{
                  marginLeft: 'auto',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}>
                {item.visible ? (
                  <Visibility sx={{ fontSize: 24 }} />
                ) : (
                  <VisibilityOff sx={{ fontSize: 24 }} />
                )}
              </div>
            </Tooltip>
            <Tooltip placement="top" arrow title="Delete">
              <div
                onClick={() => removeFile(item)}
                style={{
                  marginLeft: '16px',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}>
                <Delete sx={{ fontSize: 24 }} />
              </div>
            </Tooltip>
          </div>
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#2c4056',
              margin: '10px 0 10px 0',
            }}
          />
        </>
      );

    return (
      <>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}>
          <div
            style={{
              height: 50,
              width: 50,
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 50,
              alignItems: 'center',
              backgroundColor: 'var(--orange)',
            }}>
            <img
              style={{ height: 30, width: 30 }}
              alt="social"
              src={`/assets/img/svg/icons/pdf-icon.svg`}
            />
          </div>
          <a
            href={item.data}
            style={{ marginLeft: '5%', fontWeight: 600, width: '30vw' }}>
            {item.nickname}
          </a>
          <Tooltip
            placement="top"
            arrow
            title={item.visible ? 'Hide on profile' : 'Show on profile'}>
            <div
              onClick={() => toggleVisibility(item)}
              style={{
                marginLeft: 'auto',
                color: '#ffffff',
                cursor: 'pointer',
              }}>
              {item.visible ? (
                <Visibility size="large" />
              ) : (
                <VisibilityOff size="large" />
              )}
            </div>
          </Tooltip>
          <Tooltip placement="top" arrow title="Delete">
            <div
              onClick={() => removeFile(item)}
              style={{
                marginLeft: '16px',
                color: '#ffffff',
                cursor: 'pointer',
              }}>
              <Delete size="large" />
            </div>
          </Tooltip>
        </div>
        <div
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#2c4056',
            margin: '10px 0 10px 0',
          }}
        />
      </>
    );
  };

  return (
    <div>
      <div className="row align-items-center mb-3">
        <div className="col">
          <h6 className="mb-0">My Files</h6>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              {files.map((item, index) => (
                <FileItem
                  item={item}
                  id={index}
                  isLast={index + 1 == files.length}
                />
              ))}
              {!fileBoxOpen && (
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                  <button
                    type="button"
                    className="btn btn-xs btn-warning btn-icon rounded-pill"
                    onClick={() => setFileBoxOpen(true)}>
                    <span className="btn-inner--icon">
                      <FeatherIcon icon="plus" />
                    </span>
                    <span className="btn-inner--text">Add</span>
                  </button>
                </div>
              )}
              {fileBoxOpen && (
                <FileBox
                  files={data.files.map((item) => item.raw)}
                  onDropFiles={onDropFiles}
                  onChange={onChange}
                  setFileBoxOpen={setFileBoxOpen}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FileBox = ({
  onDropFiles,
  files,
  onChange,
  nickname,
  setFileBoxOpen,
  handleSubmit,
}) => {
  const DEFAULT_PADDING = 25;

  const uploadZoneMessage = (isDragActive, isDragReject) => (
    <>
      {isDragActive && !isDragReject && (
        <p
          style={{
            color: '#78e5d5',
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}>
          Release the file to upload it
        </p>
      )}
      {isDragReject && (
        <p
          style={{
            color: '#e57878',
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}>
          Your file isn't compatible
        </p>
      )}
      {!isDragActive && !isDragReject && !files[0] && (
        <p
          style={{
            color: '#999',
            border: '1px solid #555',
            borderRadius: 4,
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}>
          Drag your .pdf file here or click to select
        </p>
      )}
      {files.map((file) => (
        <div className="preview-container">
          <img
            // className="preview-files"
            src={`/assets/img/svg/icons/pdf-icon.svg`}
            style={{ height: 30, width: 30 }}
          />
          <p
            style={{
              color: '#999',
              textAlign: 'center',
              padding: DEFAULT_PADDING,
            }}>
            {file.name}
          </p>
        </div>
      ))}
    </>
  );
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="form-control-label">File Nickname</label>
            <input
              className="light-input form-control"
              type="text"
              onChange={onChange}
              value={nickname}
              name="nickname"
              required
              placeholder="e. g. Curricullum"
            />
            <p style={{ fontSize: 12, marginTop: 4 }} className="text-muted">
              *Only PDF files are supported.
            </p>
          </div>
        </div>
      </div>
      <Dropzone
        accept="application/pdf"
        onDropAccepted={onDropFiles}
        multiple={false}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            {...getRootProps()}
            className={`file-input-container ${isDragActive && 'drag-active'} ${
              isDragReject && 'drag-reject'
            }`}>
            <input {...getInputProps()} />

            {uploadZoneMessage(isDragActive, isDragReject)}
          </div>
        )}
      </Dropzone>
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          className="btn btn-sm btn-outline-warning"
          onClick={() => setFileBoxOpen(false)}>
          Cancel
        </button>

        <button className="btn btn-sm btn-outline-warning" onClick={handleSubmit}>
          Confirm
        </button>
      </div>
    </>
  );
};

export default FileEditor;
