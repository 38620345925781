import React from 'react';
import QRCode from 'qrcode.react';

const QrCodeModal = ({ modalQR, setModalQR }) => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      zIndex: 10000,
      backgroundColor: '#00000050',
    }}
  >
    <div style={{ flex: 1 }}>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-dark-dark">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              SKANZ Code
            </h5>
            <button
              type="button"
              class="close"
              onClick={() => setModalQR(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              style={{
                backgroundColor: '#FFFFFF',
                borderRadius: 10,
                padding: '7px 7px 0 7px',
              }}
            >
              <QRCode
                size={1000}
                style={{
                  borderRadius: 5,
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '1',
                }}
                value={modalQR}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default QrCodeModal;
