import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import { returnUsDate } from '../../../../utils/time';

import api from '../../../../utils/api';

import Side from '../../components/side';
import './style.css';

const Events = ({ setPage, isAdminDashboard = true }) => {
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      if (isAdminDashboard) {
        const { data } = await api.get(`/event`);
        setEvents(data.events);
      } else {
        const { data } = await api.get(
          `/event/business/${user.organizationId}`,
        );
        setEvents(data.events);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section
        style={false ? { filter: 'blur(4px)' } : {}}
        class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            {isAdminDashboard && (
              <>
                <div class="col-md-2">
                  <Side />
                </div>
              </>
            )}
            <div class={isAdminDashboard ? 'col-md-10' : 'col'}>
              {isAdminDashboard && (
                <>
                  <div class="row align-items-center mb-4">
                    <div class="col">
                      <h1 class="h5 mb-0">Events</h1>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-sm btn-warning btn-icon"
                        onClick={() => setPage('create')}>
                        <span class="btn-inner--text">Create Event</span>
                        <span class="btn-inner--icon">
                          <FeatherIcon icon="plus" />
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {events.map((event) => (
                <div
                  onClick={() => {
                    if (isAdminDashboard) {
                      window.location.href = `/admin/event/${event._id}`;
                    } else {
                      history.push(`/business-dashboard/event/${event._id}`);
                    }
                  }}
                  class="card custom-card p-4">
                  <div class="row">
                    <div class="col-md-3">
                      <img
                        alt="event logo"
                        style={{ width: '100%' }}
                        src={event.logoUrl}></img>
                    </div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-6">
                          <span>Name</span>
                          <h1 class="h5 mb-0">{event.name}</h1>
                        </div>

                        {event?.place && (
                          <div class="col-md-6">
                            <span>Place</span>
                            <h1 class="h5 mb-0">{event.place}</h1>
                          </div>
                        )}
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <span>Code</span>
                          <h1 class="h5 mb-0">{event.uniqueCode}</h1>
                        </div>
                        {/* {event?.time?.start && (
                          <div class="col-md-4">
                            <span>Start</span>
                            <h1 class="h5 mb-0">
                              {new Date(event.time.start).toLocaleDateString()}
                            </h1>
                          </div>
                        )} */}
                        {event?.time?.finish && (
                          <div class="col">
                            <h1 class="h5 mb-0">
                              {returnUsDate(event.time.finish)}
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
