import React from "react";

import Header from "./components/header";
import Content from "./components/content";
import Modal from "./components/modal";

const AdminDashboard = () => {
  return (
    <>
      <Header />
      <Content />
      <Modal />
    </>
  );
};

export default AdminDashboard;
