import React from "react";

import Header from "./components/header";
import Requests from "./components/requests";
import Modal from "./components/modal";

const AdminDashboard = () => {
  return (
    <>
      <Header />
      <Requests />
      <Modal />
    </>
  );
};

export default AdminDashboard;