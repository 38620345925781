import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import api from '../../../utils/api';
import axios from 'axios';
import GoogleLogin from 'react-google-button';

const Content = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const token = params.get('token');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const submitData = async e => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setError("Passwords don't match");
    }
    setError('');
    setSuccess('');
    try {
      await api.post('/auth/resetPassword', { email, password, token });
      setSuccess(
        `Password successfully reset, you will be redirected soon.`,
      );
      setTimeout(() => window.location.href = "/login", 3000)
    } catch (error) {
      setError('An error occured, please try again.');
      console.log(error);
    }
  };

  useEffect(() => {
    if (!email || !token) setError("Invalid password reset link.")
  }, [])

  return (
    <div class="container-fluid d-flex flex-column">
      <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
        <div class="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div class="row justify-content-center">
            <div class="col-11 col-lg-10 col-xl-6">
              <div>
                <div class="mb-5">
                  <h6 class="h3 mb-1">Reset Password</h6>
                  <p class="text-muted mb-0">
                    Please type your new password.
                  </p>
                </div>
                <span class="clearfix"></span>
                {error && (
                  <div class="alert alert-danger text-center">{error}</div>
                )}
                {success && (
                  <div class="alert alert-success text-center">{success}</div>
                )}
                <form onSubmit={e => submitData(e)}>
                  <div class="form-group mb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <label class="form-control-label">Password</label>
                      </div>
                      <div class="mb-2">
                        <span
                          style={{ cursor: 'pointer' }}
                          class="small text-muted text-underline--dashed border-warning"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? 'Hide' : 'Show'}
                        </span>
                      </div>
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <FeatherIcon icon="lock" />
                        </span>
                      </div>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        class="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <label class="form-control-label">Confirm Password</label>
                      </div>
                      <div class="mb-2">
                        <span
                          style={{ cursor: 'pointer' }}
                          class="small text-muted text-underline--dashed border-warning"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? 'Hide' : 'Show'}
                        </span>
                      </div>
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <FeatherIcon icon="lock" />
                        </span>
                      </div>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        required
                        class="form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="mt-3">
                    <button type="submit" class="btn btn-block btn-warning">
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
