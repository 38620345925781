import React, { useState, useEffect } from 'react';

import api from '../../../utils/api';

const ModalJoinEvent = ({ setModalJoinEvent, codeToJoin }) => {
  const [code, setCode] = useState('');
  const [shareProfile, setShareProfile] = useState(false);
  const [error, setError] = useState('');
  const newUser = JSON.parse(localStorage.getItem('@SKANZ/newUser'));

  useEffect(() => {
    setError('');
    const fetchEvent = async (codeToJoin) => {
      const { data } = await api.get(`/event/code/${codeToJoin}`);
      setShareProfile(data.event.shareProfile);
    };
    if (codeToJoin) {
      setCode(codeToJoin);
      fetchEvent(codeToJoin);
    }
  }, [codeToJoin]);

  const submitData = async (e) => {
    e.preventDefault();
    try {
      await api.post('/event/user/join', {
        uniqueCode: code,
        shareProfile,
        newUser: newUser ? true : false,
      });
      window.location.href = '/contacts';
      localStorage.removeItem('@SKANZ/newUser');
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 10000,
        backgroundColor: '#00000050',
      }}>
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {codeToJoin
                  ? 'Confirm that you would like to join this event'
                  : 'Enter the Event QRCode'}
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setModalJoinEvent(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={(e) => submitData(e)}>
                {error && (
                  <div class="row">
                    <div class="col">
                      <div class="alert alert-danger" role="alert">
                        {error}
                      </div>
                    </div>
                  </div>
                )}
                <div class="row">
                  <div class="col">
                    {codeToJoin ? (
                      <p className="mb-0">
                        Are you sure you want to join this event?
                      </p>
                    ) : (
                      <p className="mb-0"></p>
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      {!codeToJoin && (
                        <>
                          <input
                            type="text"
                            class="form-control"
                            id="qrcode"
                            placeholder="Enter the code"
                            value={code}
                            onChange={(e) =>
                              setCode(e.target.value.toUpperCase())
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col pl-0">
                  <div class="form-check">
                    <input
                      class="form-check-input mt-2"
                      type="checkbox"
                      value=""
                      onChange={() => setShareProfile(!shareProfile)}
                      checked={shareProfile}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Share my profile with other participants
                    </label>
                  </div>
                </div>
                <div class="row justify-content-md-end">
                  <div class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-danger mt-3"
                      onClick={() => setModalJoinEvent(false)}>
                      Cancel
                    </button>
                  </div>
                  <div class="col-auto">
                    <button type="submit" class="btn btn-sm btn-warning mt-3">
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalJoinEvent;
