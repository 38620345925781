import React from "react";

import Header from "../login/components/header";
import Content from "./components/content";

const ForgotPassword = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default ForgotPassword;
