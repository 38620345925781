
import { Link } from 'react-router-dom'
import { useStore } from '../../../store/user';

const Header = ({title, route}) => {
  const user = useStore((state) => state.user);
  
  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <h2 className="mb-0">{title}</h2>
        </div>
        <div className="col-auto">
          { !route && <Link to="/dashboard" className="btn btn-warning btn-sm">Return to your Profile</Link>}
          {(user.organizationId !== null &&  route === 'events') && (
            <Link to="/business-dashboard/create-event" className="btn btn-warning btn-sm">Create new Event</Link>
          )}
          {(user.organizationId == null &&  route === 'events') && (
            <Link to="/business-dashboard" className="btn btn-warning btn-sm">Create Organization</Link>
          )}
        </div>
      </div>
    </>
  )
}

export default Header