import React from "react";

import Header from "./components/header";
import Business from "./business/index";
import Modal from "./components/modal";

const AdminDashboard = () => {
    return (
      <>
        <Header />
        <Business />
        <Modal />
      </>
    );
  };
  
  export default AdminDashboard;