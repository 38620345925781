import React from 'react';

import Content from './components/content';
import Header from '../login/components/header';

const LoginAlert = () => {

  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default LoginAlert;
