import React, { useState } from "react";
import { useParams } from 'react-router-dom';

import api from "../../../../utils/api";

const AddTattoModal = ({ setAddTattooModal }) => {
  const { eventId } = useParams();
  const [nickname, setNickname] = useState("");
  const [amount, setAmount] = useState("");

  const [error, setError] = useState('');

  const submitData = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await api.post("/admin/qrcode/bulk", { amount, nickname, event: eventId });
      setAddTattooModal(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.error)
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 10000,
        backgroundColor: "#00000050",
      }}
    >
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add Codes
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setAddTattooModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {error && (
                  <div class="alert alert-danger text-center">{error}</div>
                )}
              <form onSubmit={(e) => submitData(e)}>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-control-label">Batch Name</label>
                      <input
                        class="form-control code-label"
                        type="text"
                        onChange={(e) => setNickname(e.target.value)}
                        value={nickname}
                        required
                        placeholder="Ex.: Batch Name"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">Amount</label>
                      <input
                        class="form-control code-label"
                        type="text"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        required
                        placeholder="Ex.: 10"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTattoModal;
