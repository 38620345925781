import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { useLocation } from 'react-router-dom';

const Side = () => {
  const location = useLocation();

  const checkCurrent = path => {
    if (location.pathname === path) return 'active';
  };

  return (
    <>
      <div class="mb-4">
        <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column mx-n2">
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="home" />
              Home
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2">
                <a
                  href="/admin/dashboard"
                  class={`nav-link ${checkCurrent('/admin/dashboard')}`}
                >
                  <FeatherIcon className="mr-3" icon="users" />
                  Dashboard
                </a>
              </li>
            </ul>
            <hr />
          </li>
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="map" />
              Events
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2">
                <a
                  href="/admin/events"
                  class={`nav-link ${checkCurrent('/admin/events')}`}
                >
                  <FeatherIcon className="mr-3" icon="map" />
                  All Events
                </a>
              </li>
            </ul>
            <hr />
          </li>
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="briefcase" />
              Organizations
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2">
                <a
                  href="/admin/business"
                  class={`nav-link ${checkCurrent('/admin/business')}`}
                >
                  <FeatherIcon className="mr-3" icon="briefcase" />
                  All Organizations
                </a>
              </li>
            </ul>
            <hr />
          </li>
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="users" />
              Users
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2">
                <a
                  href="/admin/users/all"
                  class={`nav-link ${checkCurrent('/admin/users/all')}`}
                >
                  <FeatherIcon className="mr-3" icon="users" />
                  All Users
                </a>
              </li>
              <li class="nav-item px-2">
                <a
                  href="/admin/users/user"
                  class={`nav-link ${checkCurrent('/admin/users/user')}`}
                >
                  <FeatherIcon className="mr-3" icon="user" />
                  Users
                </a>
              </li>
              <li class="nav-item px-2">
                <a
                  href="/admin/users/owner"
                  class={`nav-link ${checkCurrent('/admin/users/owner')}`}
                >
                  <FeatherIcon className="mr-3 ml-2" icon="user" />
                  Owners
                </a>
              </li>
              <li class="nav-item px-2">
                <a
                  href="/admin/users/admin"
                  class={`nav-link ${checkCurrent('/admin/users/admin')}`}
                >
                  <FeatherIcon className="mr-3" icon="users" />
                  Admin
                </a>
              </li>
              <li class="nav-item px-2">
                <a
                  href="/admin/users/subscription"
                  class={`nav-link ${checkCurrent('/admin/users/subscription')}`}
                >
                  <FeatherIcon className="mr-3  ml-3" icon="dollar-sign" />
                  Subscription
                </a>
              </li>
            </ul>
            <hr />
          </li>
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="unlock" />
              Codes
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2">
                <a
                  href="/admin/codes/all"
                  class={`nav-link ${checkCurrent('/admin/codes/all')}`}
                >
                  <FeatherIcon className="mr-3" icon="file" />
                  All Batches
                </a>
              </li>
              {/*<li class="nav-item px-2">
                <a
                  href="/admin/codes/active"
                  class={`nav-link ${checkCurrent('/admin/codes/active')}`}
                >
                  <FeatherIcon className="mr-3" icon="file" />
                  Activated Codes
                </a>
              </li>
              <li class="nav-item px-2">
                <a
                  href="/admin/codes/printed"
                  class={`nav-link ${checkCurrent('/admin/codes/printed')}`}
                >
                  <FeatherIcon className="mr-3" icon="file" />
                  Printed Codes
                </a>
              </li>*/}
            </ul>
          </li>
          {/*
          <li class="nav-item px-2">
            <h6>
              <FeatherIcon className="mr-3" icon="unlock" />
              Order
            </h6>
            <ul class="nav nav-menu overflow-x flex-nowrap flex-md-column ">
              <li class="nav-item px-2">
                <a href="/admin/codes" class="nav-link">
                  <FeatherIcon className="mr-3" icon="file" />
                  All Codes
                </a>
              </li>
              <li class="nav-item px-2">
                <a href="/admin/codes" class="nav-link">
                  <FeatherIcon className="mr-3" icon="file" />
                  Activated Codes
                </a>
              </li>
              <li class="nav-item px-2">
                <a href="/admin/codes" class="nav-link">
                  <FeatherIcon className="mr-3" icon="file" />
                  Printed Codes
                </a>
              </li>
            </ul>
            <hr />
          </li>*/}
        </ul>
      </div>
    </>
  );
};

export default Side;
