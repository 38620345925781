export const urls = [
  { social: 'airbnb', source: 'airbnb.svg', kind: 'Airbnb' },
  { social: 'facebook', source: 'iconmonstr-facebook-1.svg', kind: 'Facebook' },
  { social: 'amazon', source: 'amazon.svg', kind: 'Amazon' },
  { social: 'bootstrap', source: 'bootstrap.svg', kind: 'Bootstrap' },
  { social: 'codepen', source: 'codepen.svg', kind: 'Codepen' },
  { social: 'dribbble', source: 'dribbble.svg', kind: 'Dribbble' },
  { social: 'flickr', source: 'flickr.svg', kind: 'Flickr' },
  { social: 'github', source: 'github.svg', kind: 'Github' },
  { social: 'hangout', source: 'hangout.svg', kind: 'Hangout' },
  { social: 'instagram', source: 'iconmonstr-instagram-11.svg', kind: 'Instagram', },
  { social: 'itunes', source: 'itunes.svg', kind: 'Itunes' },
  { social: 'linkedin', source: 'iconmonstr-linkedin-1.svg', kind: 'Linkedin' },
  { social: 'medium', source: 'medium.svg', kind: 'Medium' },
  { social: 'pinterest', source: 'pinterest.svg', kind: 'Pinterest' },
  { social: 'skype', source: 'skype.svg', kind: 'Skype' },
  { social: 'slack', source: 'slack.svg', kind: 'Slack' },
  { social: 'snapchat', source: 'snapchat.svg', kind: 'Snapchat' },
  { social: 'spotify', source: 'iconmonstr-spotify-1.svg', kind: 'Spotify' },
  { social: 'treehouse', source: 'treehouse.svg', kind: 'Treehouse' },
  { social: 'twitter', source: 'iconmonstr-twitter-1.svg', kind: 'Twitter' },
  { social: 'uber', source: 'uber.svg', kind: 'Uber' },
  { social: 'viber', source: 'viber.svg', kind: 'Viber' },
  { social: 'vimeo', source: 'vimeo.svg', kind: 'Vimeo' },
  { social: 'venmo', source: 'venmo.png', kind: 'Venmo' },
  { social: 'weechat', source: 'weechat.svg', kind: 'Weechat' },
  { social: 'whatsapp', source: 'whatsapp.svg', kind: 'Whatsapp' },
  { social: 'wikipedia', source: 'wikipedia.svg', kind: 'Wikipedia' },
  { social: 'workplace', source: 'workplace.svg', kind: 'Workplace' },
  { social: 'yelp', source: 'yelp.svg', kind: 'Yelp' },
  { social: 'youtube', source: 'iconmonstr-youtube-6.svg', kind: 'Youtube' },
  { social: 'tiktok', source: 'tiktok.svg', kind: 'Tiktok' },
  { social: '', source: 'iconmonstr-link-1.svg', kind: 'Url' },
];
