import './content.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import api from '../../../utils/api';
const Content = () => {
  const [business, setBusiness] = useState(undefined);
  const codeToJoin = JSON.parse(localStorage.getItem('@SKANZ/qrCodeToJoin'));
  async function fetchBusiness() {
    const { data } = await api.get(`/business/code/${codeToJoin}`);
    setBusiness(data);
    console.log(data);
  }

  useEffect(() => {
    fetchBusiness();
  }, []);

  function handleClick(e) {
    e.preventDefault();
    localStorage.setItem('@SKANZ/newUser', JSON.stringify(true));
    window.location.href = '/sign-up';
  }

  if (business) {
    return (
      <>
        <Link to="/">
          <img
            src="/assets/img/brand/logo.png"
            alt="logo"
            className="w-25 mx-4 mt-4"
          />
        </Link>
        <div className="d-flex flex-column align-items-center justify-content-center vh-100 p-4">
          <div className="container mx-8 d-flex flex-column justify-content-center">
            <img
              src={
                business.logoUrl
                  ? business.logoUrl
                  : '/assets/img/brand/logo.png'
              }
              alt="logo"
              className="mx-auto mb-4 mh-75"
              style={{
                maxHeight: '300px',
                maxWidth: '75%',
              }}
            />
            <div className="row row-cols-1 align-items-center">
              <h3 className="col h5 mb-0 text-align-start pl-0">Welcome!</h3>
              <p className="col text-muted mb-0 pl-0">
                Create an account or sign in to join the {business.name}
              </p>
              <Link to="/login">
                <button className="col btn btn-sm btn-warning my-4 px-8">
                  Sign In
                </button>
              </Link>
              <Link to="/sign-up" onClick={handleClick}>
                <button className="col btn btn-sm btn-outline-warning ml-0 px-8">
                  Create account
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
export default Content;
