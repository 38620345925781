/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import api from '../../api';

export const createBusiness = async (business) => {
  const response = await api.post('/upload/signedUrl', {
    fileExtension: business.file.name.split('.')[1],
  });
  await axios.put(response.data.signedUrl.url, business.file, {
    headers: {
      'Content-Type': business.file.type,
    },
  });
  const url =
    `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
    response.data.signedUrl.key;
  const result = await api.post('/business', {
    name: business.name,
    owner: business.owner ? business.owner : null,
    logoUrl: url,
  });
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  user.organizationId = result.data._id;
  localStorage.setItem('@SKANZ/user', JSON.stringify(user));
  return result.data._id;
};

const fetchBusinesses = async () => {
  const { data } = await api.get('/business');
  return data;
};

const fetchBusiness = async (businessId) => {
  const { data } = await api.get(`/business/${businessId}/owners`);
  return data;
};

const deleteBusiness = async (businessId) => {
  const { data } = await api.delete(`/business/${businessId}`);
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  user.organizationId = undefined;
  localStorage.setItem('@SKANZ/user', JSON.stringify(user));
  return data;
};

const fetchBusinessWithOwnerId = async (ownerId) => {
  const { data } = await api.get(`/business/by-org-owner/${ownerId}`);
  return data;
};

const fetchNewCodeToJoin = async (businessId) => {
  const { data } = await api.get(
    `http://localhost:3501/business/generate/${businessId}`,
  );
  return data;
};

const updateBusiness = async (data) => {
  let url = undefined;
  if (data.file) {
    const response = await api.post('/upload/signedUrl', {
      fileExtension: data.file.name.split('.').pop(),
    });
    await axios.put(response.data.signedUrl.url, data.file, {
      headers: {
        'Content-Type': data.file.type,
      },
    });
    url =
      `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
      response.data.signedUrl.key;
  }
  await api.put(`/business/${data._id}`, {
    name: data.name,
    logoUrl: url ? url : data.logoUrl,
    profileColor: data.profileColor,
    profileType: data.profileType,
    address: data.address,
    latitude: data.latitude,
    longitude: data.longitude,
    employeeGallery: data.employeeGallery,
  });
};

export const useAddBusinessWithOwner = () => {
  const queryClient = useQueryClient();
  return useMutation(createBusiness, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const useBusinesses = () => {
  return useQuery('businesses', fetchBusinesses);
};

export const UseBusiness = (businessId, config) => {
  if (config) {
    return useQuery(
      ['business', businessId],
      () => fetchBusiness(businessId),
      config,
    );
  } else {
    return useQuery(['business', businessId], () => fetchBusiness(businessId), {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    });
  }
};

export const UseBusinessOwner = (ownerId) => {
  return useQuery(
    ['business', ownerId],
    () => fetchBusinessWithOwnerId(ownerId),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export const UseBusinessGenerateCode = (businessId) => {
  return useQuery(
    ['business', businessId],
    () => fetchNewCodeToJoin(businessId),
    {
      enabled: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};

export const useUpdateBusiness = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBusiness, {
    onMutate: async (data) => {
      const queryId = data._id;
      await queryClient.cancelQueries(['business', queryId]);
      const previousBusiness = queryClient.getQueryData(['business', queryId]);
      queryClient.setQueryData(['business', queryId], {
        ...previousBusiness,
        ...data,
      });
    },
  });
};

export const useDeleteBusiness = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBusiness, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
