import React, { useState, useEffect } from 'react';

import Header from './components/header';
import Content from './components/content';
import ModalJoinEvent from './components/modalJoinEvent';
import ModalAddTattoo from './components/modalAddTattoo';
import ModalEditTattoo from './components/modalEditTattoo';
import ModalConfirmAction from './components/modalConfirmAction';
import ModalQrCode from './components/qrcodeModal';

const UserDashboard = () => {
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [User, setUser] = useState(user);
  const [modalJoinEvent, setModalJoinEvent] = useState(false);
  const [modalAddTattoo, setModalAddTattoo] = useState(false);
  const [modalEditTattoo, setModalEditTattoo] = useState(false);
  const [modalConfirmAction, setModalConfirmAction] = useState(false);
  const [modalQR, setModalQR] = useState(false);

  useEffect(() => {
    checkActions();
  }, []);

  const checkActions = async () => {
    const unloggedQr = localStorage.getItem('@SKANZ/unloggedqr');
    if (unloggedQr) {
      setModalConfirmAction(true);
    }
  };

  return (
    <>
      <Header user={User}/>
      <Content
        setModalJoinEvent={setModalJoinEvent}
        setModalEditTattoo={setModalEditTattoo}
        setModalAddTattoo={setModalAddTattoo}
        setModalQR={setModalQR}
        modalOpen={
          modalAddTattoo || modalEditTattoo || modalConfirmAction || modalQR
        }
        setUser={setUser}
      />
      {modalJoinEvent && (
        <ModalJoinEvent setModalJoinEvent={setModalJoinEvent} />
      )}
      {modalAddTattoo && (
        <ModalAddTattoo setAddTattooModal={setModalAddTattoo} />
      )}
      {modalEditTattoo && (
        <ModalEditTattoo
          editTattooModal={modalEditTattoo}
          setEditTattooModal={setModalEditTattoo}
        />
      )}
      {modalConfirmAction && (
        <ModalConfirmAction
          setModalConfirmAction={setModalConfirmAction}
          type="qr"
        />
      )}
      {modalQR && <ModalQrCode modalQR={modalQR} setModalQR={setModalQR} />}
    </>
  );
};

export default UserDashboard;
