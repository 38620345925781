import React, { useState } from 'react';

import Header from './components/header';
import QrDetails from './components/qrDetails';

const AdminQr = () => {

  return (
    <>
      <Header />
      <QrDetails />
    </>
  );
};

export default AdminQr;
