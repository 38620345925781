import React, { useState, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useStore } from '../../../store/user';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import './users.css';

import useOutsideAlerter from '../../../utils/index';

const Header = ({ user }) => {
  user = useStore((state) => state.user);

  const [showMenu, setShowMenu] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const menuRef = useRef(null);
  const menuRefMobile = useRef(null);
  useOutsideAlerter(menuRef, () => {
    !showMenu && setShowMenu(false);
  });
  useOutsideAlerter(menuRefMobile, () => {
    !showMenuMobile && setShowMenuMobile(false);
  });

  // Add a check to see if the `quests` variable in local storage is not empty
  const questsInLocalStorage = JSON.parse(localStorage.getItem('@SKANZ/quests'));
  const showQuestsLink = Array.isArray(questsInLocalStorage) && questsInLocalStorage.length > 0;
  console.log('showQuestsLink:', showQuestsLink);


  const getCurrentPath = (path) => {
    const { pathname } = window.location;
    if (pathname === path) return 'active';
    return '';
  };

  const openMenu = () => {
    if (!isMobile) {
      setShowMenu(!showMenu);
    } else {
      setShowMenuMobile(!showMenuMobile);
    }
  };

  const openMobileMenu = () => {
    setShowMenuMobile(!showMenuMobile);
  };

  return (
    <>
      {isMobile && showMenuMobile && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#000000aa',
            top: 65,
            left: 0,
            zIndex: 1000000,
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
          }}>
          <div
            ref={menuRefMobile}
            style={{
              width: '100vw',
              padding: 20,
              backgroundColor: '#1f1f1f',
              borderBottomRightRadius: 16,
              borderBottomLeftRadius: 16,
            }}>
            <div class="dropdown-divider"></div>
            <h6 class="px-0 mb-2 text-warning">
              {`${user.firstName} ${user.lastName}`}
            </h6>
            <div>
              <a style={{ color: '#C7C7C7' }} href="/profile">
                <FeatherIcon icon="user" />{' '}
                <span style={{ marginLeft: 5 }}>My Profile</span>
              </a>
            </div>
            <div>
          {showQuestsLink && (
            <li className="nav-item">
              <Link
                to="/quests"
                className={`nav-link ${getCurrentPath('/quests')}`}
              >
                Quests
              </Link>
            </li>
          )}
              </div>
            <div>
              <a
                href={`${
                  user.roleId === 'admin' ? '/admin/dashboard' : '/dashboard'
                }`}
                style={{ color: '#C7C7C7' }}>
                <FeatherIcon icon="activity" />{' '}
                <span style={{ marginLeft: 5 }}>Dashboard</span>
              </a>
            </div>
            <div>
              <a style={{ color: '#C7C7C7' }} href="/settings">
                <FeatherIcon icon="settings" />{' '}
                <span style={{ marginLeft: 5 }}>Settings</span>
              </a>
            </div>
            <div>
              <span
                onClick={() => {
                  localStorage.clear();
                  window.location.href = '/';
                }}
                style={{ color: '#C7C7C7' }}>
                <FeatherIcon icon="log-out" />{' '}
                <span style={{ marginLeft: 5 }}>Sign Out</span>
              </span>
            </div>
          </div>
        </div>
      )}
      <nav
        className="navbar navbar-main navbar-expand-lg shadow navbar-dark bg-dark"
        id="navbar-main"
      >
        <div className="container">
          <a className="navbar-brand order-lg-1" href="/">
            <img alt="placeholder" src="/assets/img/brand/logo.png" id="navbar-logo" />
          </a>
          <div
            className="collapse navbar-collapse navbar-collapse-overlay order-lg-3"
            id="navbar-main-collapse"
          >
            <div className="position-relative">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-main-collapse"
                aria-controls="navbar-main-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={openMobileMenu}
              >
                <FeatherIcon icon="x" />
              </button>
            </div>
            <ul className="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto">
              <li className="nav-item dropdown dropdown-animate">
                <div
                  ref={menuRef}
                  className={`dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow p-3 ${showMenu ? 'show' : ''}`}
                >
                  <h6 className="dropdown-header px-0 mb-2 text-warning">
                    {`${user.firstName} ${user.lastName}`}
                  </h6>
                  <a href="/profile" className="dropdown-item">
                    <FeatherIcon icon="user" />
                    <span>My Profile</span>
                  </a>
                  <a
                    href={`${
                      user.roleId === 'admin'
                        ? '/admin/dashboard'
                        : '/dashboard'
                    }`}
                    className="dropdown-item"
                  >
                    <FeatherIcon icon="activity" />
                    <span>Dashboard</span>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="/settings" className="dropdown-item">
                    <FeatherIcon icon="settings" />
                    <span>Settings</span>
                  </a>
                  <span
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = '/';
                    }}
                    className="dropdown-item"
                  >
                    <FeatherIcon icon="log-out" />
                    <span>Sign Out</span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          {user.showProfilePicture ? (
            <div className="order-lg-4 ml-lg-3">
              <div onClick={openMenu}>
                <span className="avatar rounded-circle">
                  <img
                    className="avatar-image"
                    alt="placeholder"
                    src={user?.profilePicture ?? '/assets/img/newUser.png'}
                  />
                </span>
              </div>
            </div>
          ) : (
            <div className="order-lg-4 ml-lg-3">
              <div onClick={openMenu}>
                <span className="avatar rounded-circle">
                  <img
                    className="avatar-image"
                    alt="placeholder"
                    src={'/assets/img/newUser.png'}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </nav>
      {window.location.href.includes('cart') ? (
        <div className="mt-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="row mb-4">
                  <div className="col">
                    <Link to="/dashboard" className="nav active">
                      <FeatherIcon className="mr-3" icon="arrow-left" />
                      Return
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <section className="pt-5 bg-section-secondary">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="row align-items-center">
                  <div className="col-12">
                    <ul className="nav nav-tabs overflow-x">

                      <li className="nav-item">
                        <Link
                          to="/dashboard"
                          className={`nav-link ${getCurrentPath('/dashboard')}`}
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li className={`nav-item ${showQuestsLink ? '' : 'hide-quests'}`}>
                    <Link
                      to="/quests"
                      className={`nav-link ${getCurrentPath('/quests')}`}
                    >
                      Quests
                    </Link>
                  </li>
                      <li className="nav-item">
                        <Link
                          to="/profile"
                          className={`nav-link ${getCurrentPath('/profile')}`}
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/contacts"
                          className={`nav-link ${getCurrentPath('/contacts')}`}
                        >
                          Connections
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/settings"
                          className={`nav-link ${getCurrentPath('/settings')}`}
                        >
                          Settings
                        </Link>
                      </li>


                      {user?.plans?.isOrganization && (
                        <li className="nav-item">
                          <Link
                            to="/business-dashboard"
                            className={`nav-link ${getCurrentPath('/business')}`}
                          >
                            Go to Business Dashboard
                          </Link>
                        </li>
                      )}
                        <li className="nav-item ml-auto">
                            <Link to="/cart" className={`upgrade nav-link ${getCurrentPath('/cart')}`}>
                              Upgrade
                            </Link>
                        </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Header;