import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CreateModal from './components/createBusinessModal';
import { Link } from 'react-router-dom';
import { useBusinesses } from '../../../utils/hooks/Organization/business';

import Side from '../components/side';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const BusinessDashboard = ({ setPage }) => {
    const { data, isLoading, isError } = useBusinesses();
    const [open, setOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('@SKANZ/user'))
    
    const handleCreatedBusiness = async () => {
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    if(isLoading) {
        return (
            <section className="slice py-5 bg-section-secondary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <Side />
                        </div>
                        <div className="col-md-10">
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    if (isError) {
        return (
            <section className="slice py-5 bg-section-secondary">
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <Side />
                    </div>
                    <div className="col-md-10">
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <div>
                                <span className="sr-only">Error loading data</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        )
    }

    return (
    <>
        <section className="slice py-5 bg-section-secondary">
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <Side />
                    </div>
                    <div className="col-md-10">
                        <div className="row align-items-center mb-4">
                            <div className="col">
                                <h1 className="h5 mb-0">Organizations</h1>
                            </div>
                            <div className="col-auto">
                            <CreateModal cb={handleCreatedBusiness} userId={user._id}/>
                            </div>
                        </div>
                    {data.map(business => (
                        <Link to={`/admin/business/${business._id}`} key={business._id}>
                        <div className="card custom-card p-4">
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        alt="event logo"
                                        style={{ width: '100%' }}
                                        src={business.logoUrl}
                                    ></img>
                                </div>
                                <div className="col-md-9">
                                    <h3 className="h5 mb-0">{business.name}</h3>
                                </div>
                            </div>
                        </div>
                        </Link>
                    ))}
                    </div>
                </div>
            </div>
        </section>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            This is a success message!
            </Alert>
        </Snackbar>
    </>
    )
}

export default BusinessDashboard;