import './content.css';
import { Link } from 'react-router-dom';
const Content = () => {
  function handleClick(e) {
    e.preventDefault();
    localStorage.setItem('@SKANZ/newUser', JSON.stringify(true));
    window.location.href = '/sign-up';
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="container mx-8 d-flex flex-column">
          <img
            src="/assets/img/brand/logo.png"
            alt="logo"
            className="w-75 mx-auto mb-4"
          />
          <div className="row row-cols-1 align-items-center">
            <h3 className="col h5 mb-0 text-align-start pl-0">Welcome!</h3>
            <p className="col text-muted mb-0 pl-0">
              Create an account or sign in to check into this event
            </p>
            <Link to="/login">
              <button className="col btn btn-sm btn-warning my-4 px-8">
                Sign In
              </button>
            </Link>
            <Link to="/sign-up" onClick={handleClick}>
              <button className="col btn btn-sm btn-outline-warning ml-0 px-8">
                Create account
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Content;
