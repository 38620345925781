import { useState } from 'react';
import * as UserHeader from '../user/components/header';
import Header from '../../components/header/header';
import { useStore } from '../../store/user';
import { useForm } from 'react-hook-form';
import { FileUploader } from 'react-drag-drop-files';
import { useSnackbar } from 'notistack';
import api from '../../utils/api';

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

const Contact = () => {
  const user = useStore((state) => state.user);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (file) => {
    setFiles(file);
  };

  const onSubmit = async (data) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: 'application/json',
    });
    const formData = new FormData();
    formData.append('json', blob);
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }
    try {
      await api.post('/email/contact', formData);
      enqueueSnackbar('Email sent! Redirecting you to dashboard...', {
        variant: 'success',
      });
      setTimeout(() => (window.location.href = '/dashboard'), 5000);
    } catch (error) {
      enqueueSnackbar(error.response.data, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      {user ? (
        <UserHeader.default />
      ) : (
        <div className="mb-8">
          <Header />
        </div>
      )}
      <div className="container bg-dark p-4 rounded mt-4">
        <h1 className="text-gray font-weight-bolder">Contact Us</h1>
        <p className="h6 text-muted mb-4">
          If you want to send us a message, be that a bug or a feedback, please
          feel free to send us a message!
        </p>
        <form
          className="d-flex flex-column"
          style={{ gap: '1.5rem' }}
          onSubmit={handleSubmit(onSubmit)}
          enctype="multipart/form-data">
          <div className="d-flex w-100 justify-content-between">
            <div className="w-100 mr-4">
              <input
                type="email"
                placeholder="Your email"
                className="rounded bg-light form-control mb-3"
                {...register('email', { required: true })}
              />
              {errors.email?.type === 'required' && (
                <div class="alert alert-warning" role="alert">
                  Email is required!
                </div>
              )}
            </div>
            <div className="w-100">
              <input
                type="text"
                placeholder="Your name"
                aria-label="name"
                className="rounded bg-light form-control mb-3"
                {...register('name', { required: true })}
              />
              {errors.name?.type === 'required' && (
                <div class="alert alert-warning" role="alert">
                  Name is required!
                </div>
              )}
            </div>
          </div>
          <textarea
            placeholder="Your message..."
            className="rounded form-control bg-light"
            {...register('message', { required: true, minLength: 30 })}
          />
          {errors.message && errors.message?.type === 'minLength' && (
            <div class="alert alert-warning" role="alert">
              Message too small!
            </div>
          )}
          <p className="mb-0">
            Have any pictures to send to us? You can send up 3.
          </p>
          <FileUploader
            handleChange={handleChange}
            name="file"
            multiple={true}
            types={fileTypes}
            classes="w-100 mw-100 border-primary"
          />
          {files.length > 0 && (
            <ul className="list-group">
              <button
                className="btn btn-sm btn-outline-warning mb-4"
                onClick={() => setFiles([])}>
                Clear Images
              </button>
              {Array.prototype.map.call(files, (file) => (
                <li className="list-item h6 text-muted" id={file.name}>
                  {file.name}
                </li>
              ))}
            </ul>
          )}
          {files.length > 3 && (
            <div class="alert alert-warning" role="alert">
              Too many images!
            </div>
          )}
          <button
            type="submit"
            className="btn btn-sm btn-warning"
            disabled={files.length > 3 ? true : false}>
            Send Message
          </button>
        </form>
      </div>
    </>
  );
};

export default Contact;
