import React, { useState, useEffect } from 'react';

import Header from './components/header';
import Profile from './components/profile';
import AddSocialModal from './components/addSocialModal';
import EditUserModal from './components/editUserModal';
import ModalConfirmAction from './components/modalConfirmAction';

const UserProfile = () => {
  const [addSocialModal, setAddSocialModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [modalConfirmAction, setModalConfirmAction] = useState(false);

  useEffect(() => {
    checkActions();
  }, []);

  const checkActions = async () => {
    const unloggedContact = localStorage.getItem('@SKANZ/unloggedcontact');
    if (unloggedContact) {
      try {
        // Code that may throw the "No account selected" error
        setModalConfirmAction(true);
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  return (
    <>
      <Header />
      <Profile
        modalOpen={addSocialModal || editUserModal || modalConfirmAction}
        setAddSocialModal={setAddSocialModal}
        setEditUserModal={setEditUserModal}
      />
      {addSocialModal && (
        <AddSocialModal addSocialModal={addSocialModal} setAddSocialModal={setAddSocialModal} />
      )}
      {editUserModal && <EditUserModal setEditUserModal={setEditUserModal} />}
      {modalConfirmAction && (
        <ModalConfirmAction
          setModalConfirmAction={setModalConfirmAction}
          type="contact"
        />
      )}
    </>
  );
};

export default UserProfile;