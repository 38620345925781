import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '../../../utils/hooks/useQuery';
import { useSnackbar } from 'notistack';
import FeatherIcon from 'feather-icons-react';
import QRCode from 'qrcode.react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isMobile } from 'react-device-detect';
import { useEventByOrgId } from '../../../utils/hooks/Events/events';
import { UseUser } from '../../../utils/hooks/User/user';
import { useStore } from '../../../store/user';

import api from '../../../utils/api';

const Content = ({
  setModalJoinEvent,
  setModalAddTattoo,
  setModalEditTattoo,
  modalOpen,
  setModalQR,
}) => {
  const thisUrl = window.location.origin;
  const query = useQuery();
  console.log(query.get('subscriptionType') == 'user');
  if (query.get('subscriptionType') == 'user') {
    query.user = 'user';
  }
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const { data: User, status } = UseUser(user.userslug);
  const organizationId = User?.data.user?.organizationId;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [tattoos, setTattoos] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [newCodes, setNewCodes] = useState([]);
  const { data: event, isSuccess } = useEventByOrgId(user.organizationId);
  const setUser = useStore((state) => state.setUser);
  useEffect(() => {
    getTattoos();
    getPendingOrders();
    getNewCodes();
    if (query.user) {
      CheckSession(query.get('session_id'), true);
    }
    if (query.get('session_id') && !query.get('subscriptionType')) {
      CheckSession(query.get('session_id'), false);
    }
  }, []);
  async function CheckSession(sessionId, isUserSubscription) {
    try {
      const { data } = await api.post('/order/check', {
        userId: user._id,
        sessionId,
      });
      let message;
      if (isUserSubscription) {
        user.plans.isPremium = true;
        message =
          'You have successfully subscribed to the user premium plan! Go to your profile and change your own out-profile logo!';
      } else {
        user.plans.isOrganization = true;
        message =
          'You can now create your own organization! Go to the business dashboard';
      }
      if (data.status === 'active') {
        enqueueSnackbar(message, { variant: 'success' });
        setUser({
          ...user,
        });
        // save user to localstorage
        localStorage.setItem(
          '@SKANZ/user',
          JSON.stringify({
            ...user,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function checkSubscription() {
    try {
      const { data } = await api.post('/order/check', {
        subscriptionId: user.subscription,
        userId: user._id,
      });
      if (data.status !== 'active') {
        console.log('not active');
      }
      if (data.status === 'active') {
        if (user.subscriptionType === 'user') {
          setUser({
            ...user,
            roleId: 'premium',
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  const downloadImage = async (code) => {
    const qrCodeURL = document
      .getElementById(`qr-${code}`)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QRCode-${code}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const activateCode = async (code) => {
    try {
      await api.post('/user/activate', { code: `${code}` });
      getTattoos();
      getPendingOrders();
      getNewCodes();
    } catch (error) {
      console.log(error);
    }
  };

  const getTattoos = async () => {
    try {
      const { data } = await api.get('/user/tattoos');
      setTattoos(data.tattoos);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingOrders = async () => {
    try {
      const { data } = await api.get('/order/pending');
      setPendingOrders(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getNewCodes = async () => {
    try {
      const { data } = await api.get('/order/codes');
      setNewCodes(data);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelOrder = async (orderId) => {
    try {
      await api.post('/order/cancel', { orderId });
      getPendingOrders();
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateLink = async (orderId) => {
    try {
      const { data } = await api.post('/order/regenerate', { orderId });
      window.location.href = data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateProTattoo = async (item) => {
    try {
      const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
      const { data } = await api.put('/user/edit', {
        code: item.code,
        link: item.link?.includes('/out-profile/')
          ? ''
          : `/out-profile/${user.userslug}`,
      });
      setTattoos(data.tattoos);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTattooEventLink = async (item) => {
    try {
      if (!organizationId) {
        enqueueSnackbar("You don't own an organization", {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
      const { data: event } = await api.get(
        `/event/business/${user.organizationId}`,
      );
      if (event.result.length < 0) {
        enqueueSnackbar('This business does not have any event', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
      const { data } = await api.put('/user/edit', {
        code: item.code,
        eventLink: item.eventLink?.includes(
          `/join-event/${user.organizationId}`,
        )
          ? ''
          : `/join-event/${user.organizationId}`,
      });
      setTattoos(data.tattoos);
    } catch (error) {
      console.log(error);
    }
  };
  const TattooCard = ({ item }) => {
    return (
      <div className="col-md-3 col-sm-6 px-2">
        <div className="card">
          <div className="card-body text-center">
            {item.link?.includes('skanz.live/out-profile/') && (
              <span className="pro-tatto">Pro</span>
            )}
            <div className="avatar-parent-child">
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: 10,
                  padding: '10px 10px 0 10px',
                }}>
                <QRCode
                  size={80}
                  style={{ borderRadius: 5 }}
                  value={
                    item.eventLink
                      ? `${thisUrl}${item.eventLink}`
                      : `https://skanz.live/qr/${item.code}`
                  }
                />
              </div>
            </div>
            <a
              href={item.link}
              target="_blank"
              className="d-block mt-4 mb-0 mb-3"
              rel="noreferrer">
              {item.code}
            </a>
            <div className="actions d-flex justify-content-between">
              <a
                href={item.link}
                target="_blank"
                className="action-item mr-2 btn btn-sm btn-success"
                data-toggle="tooltip"
                title="Profile"
                rel="noreferrer">
                View
              </a>
              {/*<a
                onClick={() => alert("Not implemented.")}
                className="action-item btn btn-sm btn-danger"
                data-toggle="tooltip"
                title="Delete"
              >
                Delete
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TattooTable = ({ item }) => {
    return (
      <tr>
        <td
          style={{
            backgroundColor: '#2d2d2d',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
          scope="row">
          <div className="media align-items-center">
            <div>
              <div className="avatar-parent-child">
                <div
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: 10,
                    padding: '7px 7px 0 7px',
                  }}>
                  <QRCode
                    onClick={() =>
                      setModalQR(
                        item.eventLink
                          ? `${thisUrl}${item.eventLink}`
                          : `https://skanz.live/qr/${item.code}`,
                      )
                    }
                    size={50}
                    style={{ borderRadius: 5, cursor: 'pointer' }}
                    value={
                      item.eventLink
                        ? `${thisUrl}${item.eventLink}`
                        : `https://skanz.live/qr/${item.code}`
                    }
                  />
                </div>
              </div>
            </div>
            <div className="media-body ml-4">
              {item.nickname ? (
                <>
                  <h6 className="name h6 mb-0">{item.nickname}</h6>
                  <span className="text-muted">{item.code}</span>
                </>
              ) : (
                <h6 className="name h6 mb-0">{item.code}</h6>
              )}
            </div>
          </div>
        </td>
        {item.user && (
          <td style={{ backgroundColor: '#2d2d2d' }}>
            {(!item.eventLink ||
              !item.eventLink?.includes(
                `/join-event/${user.organizationId}`,
              )) && (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => updateProTattoo(item)}
                className="custom-control custom-switch mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input protatto"
                  checked={item.link?.includes('/out-profile/')}
                  disabled={item.eventLink?.includes(
                    `/join-event/${user.organizationId}`,
                  )}
                />
                <label className="custom-control-label" htmlFor="<%= qrs[i].id%>">
                  Link To Profile
                </label>
              </div>
            )}
          </td>
        )}
        {item.user && (
          <td
            style={{
              backgroundColor: '#2d2d2d',
            }}>
            {item?.scanCount}
          </td>
        )}
        <div
          style={{
            height: 10,
            width: 10,
            display: 'none',
          }}>
          <QRCode
            id={`qr-${item.code}`}
            size={1000}
            style={{ borderRadius: 5 }}
            value={
              item.eventLink
                ? `${thisUrl}${item.eventLink}`
                : `${process.env.REACT_APP_BASE_QR}${item.code}`
            }
          />
        </div>
        <td
          style={{
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: '#2d2d2d',
          }}
          className="text-right">
          <div className="actions ml-3">
            {item.user ? (
              <>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className=" action-item mr-2"
                  data-toggle="tooltip"
                  title="Quick view">
                  View
                </a>
                <a
                  className="action-item mr-2 action-edit"
                  onClick={() => setModalEditTattoo(item)}>
                  Edit
                </a>
              </>
            ) : (
              <a
                onClick={() => activateCode(item.code)}
                className=" action-item mr-2">
                Activate
              </a>
            )}
            <a
              onClick={() => downloadImage(item.code)}
              className=" action-item mr-2">
              Download
            </a>
          </div>
        </td>
      </tr>
    );
  };

  const TattooMobile = ({ item }) => {
    const [expand, setExpand] = useState(false);
    return (
      <div className="card">
        <div style={{ margin: 10 }}>
          {expand && (
            <div
              style={{
                borderRadius: 5,
                padding: '7px 7px 0 7px',
                backgroundColor: '#fff',
                marginBottom: 10,
              }}
              onClick={() => setExpand(false)}>
              <QRCode
                size={500}
                style={{
                  borderRadius: 5,
                  width: '100%',
                  aspectRatio: '1',
                  height: 'auto',
                }}
                value={
                  item.eventLink
                    ? `${thisUrl}${item.eventLink}`
                    : `https://skanz.live/qr/${item.code}`
                }
              />
            </div>
          )}
          <div className="row">
            <div className="media align-items-center">
              {!expand && (
                <div>
                  <div
                    style={{ margin: '0 0 0 15px' }}
                    className="avatar-parent-child">
                    <div
                      style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: 10,
                        padding: '7px 7px 0 7px',
                      }}>
                      <QRCode
                        onClick={() => setExpand(true)}
                        size={50}
                        style={{ borderRadius: 5 }}
                        value={
                          item.eventLink
                            ? `${thisUrl}${item.eventLink}`
                            : `https://skanz.live/qr/${item.code}`
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="ml-4">
                {item.nickname ? (
                  <>
                    <span
                      style={{ fontWeight: 700, fontSize: 16 }}
                      className="mb-0">
                      {item.nickname}
                    </span>{' '}
                    <span
                      style={{ fontWeight: 400, fontSize: 16 }}
                      className="mb-0">
                      ({item.code})
                    </span>
                  </>
                ) : (
                  <span style={{ fontWeight: 700, fontSize: 16 }} className="mb-0">
                    {item.code}
                  </span>
                )}

                <br />
                {item.user && (
                  <span className="name h6 mb-0 text-sm">
                    Scan Count: {item.scanCount}
                  </span>
                )}
              </div>
            </div>
          </div>
          <hr style={{ margin: '20px 0 0 0' }} />
          <div
            className="d-flex justify-content-around"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20,
              flex: 1,
            }}>
            {item.user && (
              <div className="d-flex justify-content-around">
                {(!item.eventLink ||
                  !item.eventLink?.includes(
                    `/join-event/${user.organizationId}`,
                  )) && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => updateProTattoo(item)}
                    className="custom-control custom-switch mb-2 mr-4">
                    <input
                      type="checkbox"
                      className="custom-control-input protatto"
                      checked={item.link?.includes('/out-profile/')}
                      disabled={item.eventLink?.includes(
                        `/join-event/${user.organizationId}`,
                      )}
                    />
                    <label className="custom-control-label" htmlFor="<%= qrs[i].id%>">
                      Link To Profile
                    </label>
                  </div>
                )}
              </div>
            )}
            {item.user ? (
              <div>
                <a
                  href={item.link}
                  target="_blank"
                  className=" action-item mr-2"
                  rel="noreferrer">
                  View
                </a>
                <a
                  className="action-item mr-2 action-edit"
                  onClick={() => setModalEditTattoo(item)}>
                  Edit
                </a>
                <a
                  onClick={() => downloadImage(item.code)}
                  className=" action-item mr-2">
                  Download
                </a>
              </div>
            ) : (
              <div>
                <a
                  onClick={() => activateCode(item.code)}
                  className=" action-item mr-2">
                  Activate
                </a>
                <a
                  onClick={() => downloadImage(item.code)}
                  className=" action-item mr-2">
                  Download
                </a>
              </div>
            )}
            <div
              style={{
                height: '0px',
                width: '0px',
                display: 'none',
              }}>
              <QRCode
                id={`qr-${item.code}`}
                size={1000}
                style={{ borderRadius: 5 }}
                value={`${
                  item.eventLink
                    ? `${thisUrl}${item.eventLink}`
                    : process.env.REACT_APP_BASE_QR
                }${item.code}`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const buyOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={modalOpen ? { filter: 'blur(4px)' } : {}}
      className="slice slice-sm bg-section-secondary">
      <div className="container h-100vh">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="row align-items-center mb-4">
              <div className="col">
                <div className="d-flex align-items-center mb-0">
                  <div className="d-flex">
                    <div className="pr-3">
                      <span className="h5 mb-0">
                        <FeatherIcon icon="users" />
                      </span>
                    </div>
                    <div className="">
                      <h3 className="h5 mb-0">Code Management</h3>
                      <p className="text-muted mb-0">Manage all codes</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={isMobile ? { width: '100%' } : {}} className="col-auto">
                <button
                  type="button"
                  style={isMobile ? { width: '100%', marginTop: 20 } : {}}
                  className={`btn btn-sm btn-outline-warning`}
                  onClick={handleClick}>
                  Go to <strong>SKANZ</strong> Store
                  <span style={{ marginLeft: 10 }} className="btn-inner--icon">
                    <FeatherIcon icon="chevron-down" />
                  </span>
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={buyOpen}
                  onClose={handleClose}
                  disableScrollLock
                  sx={{ marginTop: '5px' }}
                  PaperProps={{
                    sx: {
                      backgroundColor: 'rgb(45, 45, 45)',
                      color: '#fff',
                      borderRadius: '8px',
                    },
                  }}>
                  <MenuItem
                    sx={{
                      fontSize: '14px',
                      '&:hover': { backgroundColor: 'rgb(60, 60, 60)' },
                    }}
                    onClick={() => (window.location.href = '/cart')}>
                    <FeatherIcon className="mr-2" icon="camera" />
                    Get Digital Products
                  </MenuItem>

                  <MenuItem
                    sx={{
                      fontSize: '14px',
                      '&:hover': { backgroundColor: 'rgb(60, 60, 60)' },
                    }}
                    onClick={() =>
                      (window.location.href = 'https://www.skanz.io/shop')
                    }>
                    <FeatherIcon className="mr-2" icon="shopping-bag" />
                    Get Physical Products
                  </MenuItem>
                </Menu>
              </div>
              <div style={isMobile ? { width: '100%' } : {}} className="col-auto">
                <button
                  type="button"
                  style={isMobile ? { width: '100%', marginTop: 20 } : {}}
                  className={`btn btn-sm btn-warning`}
                  onClick={() => setModalJoinEvent(true)}>
                  Join Event
                  <span style={{ marginLeft: 10 }} className="btn-inner--icon">
                    <FeatherIcon icon="calendar" />
                  </span>
                </button>
              </div>
            </div>
            {pendingOrders.length > 0 && (
              <div
                style={{
                  borderRadius: '.375rem',
                }}
                className={`card mb-${newCodes.length > 0 ? '0' : '4'} p-3`}>
                <h5 className="mb-0">
                  {pendingOrders[0].amount} QR Code
                  {pendingOrders[0].amount > 1 ? 's' : ''} Pending
                </h5>
                They will be automatically available to activate after the
                payment is confirmed.
                {pendingOrders[0].statusCode == 0 && (
                  <div className="mt-2 mb-0">
                    <button
                      type="button"
                      className={`btn btn-sm btn-outline-danger`}
                      onClick={() => cancelOrder(pendingOrders[0]._id)}>
                      Cancel Order
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm btn-success`}
                      onClick={() => regenerateLink(pendingOrders[0]._id)}>
                      Resume Payment
                    </button>
                  </div>
                )}
                <span style={{ fontSize: 10, marginTop: 12 }}>
                  Ref: {pendingOrders[0]._id}
                </span>
              </div>
            )}
            {newCodes.length > 0 && (
              <div
                style={{
                  borderRadius: '.375rem',
                }}
                className="card mb-0 mt-5 p-3">
                <h5 className="mb-0">
                  {newCodes.length} QR Code
                  {newCodes.length > 1 ? 's' : ''} Available
                </h5>
                You can activate them on your account or share the code.
              </div>
            )}
            {newCodes.length > 0 && isMobile && <div style={{ height: 20 }} />}
            {newCodes.length > 0 && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-5">
                    {!isMobile ? (
                      <div className="table-responsive">
                        <table className="table table-cards align-items-center">
                          <tbody>
                            {newCodes.map((item) => (
                              <TattooTable item={item} />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        {newCodes.map((item) => (
                          <TattooMobile item={item} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {tattoos.length > 0 ? (
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-5">
                    {!isMobile ? (
                      <div className="table-responsive">
                        <table className="table table-cards align-items-center">
                          <thead>
                            <tr>
                              <th scope="col" style={{ minWidth: 240 }}>
                                Image
                              </th>
                              <th scope="col">Type</th>
                              <th scope="col">Scan Count</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {tattoos.map((item) => (
                              <TattooTable item={item} />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        {tattoos.map((item) => (
                          <TattooMobile item={item} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p>No activated codes found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
