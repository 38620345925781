import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import './quest.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const apiQuest = axios.create({
  baseURL: 'https://api.quest.circlepass.io'
});

const Quests = () => {
  const [quests, setQuests] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for showing/hiding modal

  // Function to handle showing modal
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Function to handle hiding modal
  const handleCloseModal = () => {
    setShowModal(false);
  };
  let collectionPoints = [];
  try {
    const collectionPointsStr = localStorage.getItem('@SKANZ/collectionPoints');
    collectionPoints = collectionPointsStr ? JSON.parse(collectionPointsStr) : [];
  } catch (error) {
    console.error('Error parsing @SKANZ/collectionPoints from local storage:', error);
  }

  useEffect(() => {
    const fetchQuests = async () => {
      let questsArray = [];
      try {
        const questsStr = localStorage.getItem('@SKANZ/quests');
        questsArray = questsStr ? JSON.parse(questsStr) : [];
      } catch (error) {
        console.error('Error parsing @SKANZ/quests from local storage:', error);
      }

      const questsData = await Promise.all(
        questsArray.map(async (questId) => {
          try {
            const response = await apiQuest.get(`/api/quests/${questId}`);
            const quest = response.data.data;

            // Get all collection point ids from the quest
            const countedPointIds = new Set(Object.keys(quest.collection_points));

            const collectionPoints = await Promise.all(
              Object.keys(quest.collection_points).map(async (pointId) => {
                const pointResponse = await apiQuest.get(`/api/collection_point/${pointId}`);
                const point = pointResponse.data.data;
                return { ...point, name: quest.collection_points[pointId] };
              })
            );

            return { ...quest, collection_points: collectionPoints, countedPointIds: countedPointIds };
          } catch (error) {
            console.error('Error fetching quest:', error);
            return null;
          }
        })
      );

      setQuests(questsData.filter((quest) => quest !== null));
    };

    fetchQuests();
  }, []);

  if (collectionPoints.length === 0) {
    return (
      <div className="text-center mt-5 no-quests">
        <h1 className='mt-5 mb-5'>You have not started any quests yet</h1>
      </div>
    );
  }

  return (
    <Container>
      {quests.map((quest) => {
        const totalPoints = quest.collection_points.filter((point) => quest.countedPointIds.has(point.id)).length;
        const completedPoints = quest.collection_points.filter((point) =>
          collectionPoints.includes(point.id) && quest.countedPointIds.has(point.id)
        ).length;
        const progressPercentage = (completedPoints / totalPoints) * 100;
        const pointsToUnlock = totalPoints - completedPoints;
        const progressBarClass = completedPoints === totalPoints ? 'glow-green' : '';





        return (
          <div key={quest.id}>
            <h1 className="text-center mt-5">{quest.name}</h1>
            <Row className="justify-content-center">
            {/*<a className="event-btn" href="https://skanz.live/join-event/QUEST">
              Join the Skanz event
        </a>*/}
            </Row>
            <p className="text-center"></p>
            <Row className="justify-content-center mb-2">
              <ProgressBar
                className={`custom-progress mb-3 ${progressBarClass}`}
                now={progressPercentage}
                label={`${completedPoints}/${totalPoints}`}
              />
            </Row>
            
            <Row className="justify-content-center mb-5">
              {completedPoints === totalPoints ? (
                <p>
                 You did it! You completed the Quest!{' '}
                  <span className="claim-reward" onClick={handleShowModal}>
                  <p className="mb-5 mt-0 badge rounded-pill bg-purple">claim your reward</p>
                  </span>
                </p>
              ) : (
                <>
                  <p>
                    Unlock {pointsToUnlock} more {pointsToUnlock > 1 ? 'badges' : 'badge'} to be entered to win a
                  </p>
                  <p className="mb-5 mt-0 badge rounded-pill bg-purple">{quest.reward}</p>
                </>
              )}
            </Row>

            {/* explaination modal*/}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Claim Your Reward</Modal.Title>
              </Modal.Header>
              <div class="modal-body">
              You did it! Show your badge at the bar for one complimentary 
              golf drink of choice.                
               <div class="">
                <b>If you have any questions or are interested in learning more about the club contact <a href="mailto:liz.miller@invitedclubs.com">Liz Miller</a>.</b></div>
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Row>
              {quest.collection_points.map((point) => (
                <Col xs={6} md={4} key={point.id}>
                  <Card className={`badge-style ${collectionPoints.includes(point.id) ? '' : 'greyed-out'}`}>
                    <Card.Img
                      variant="top"
                      src={point.image_url || 'https://via.placeholder.com/150'}
                    />
                    <Card.Body>
                      <Card.Title className="small-font">{point.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default Quests;
