import React from "react";

import Header from "./components/header";
import Users from "./components/users";
import Modal from "./components/modal";

const AdminDashboard = () => {
  return (
    <>
      <Header />
      <Users />
      <Modal />
    </>
  );
};

export default AdminDashboard;
