import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import EditBusinessModal from '../../admin/business/components/editBusinessModal';
import CreateModal from '../../admin/business/components/createBusinessModal';
import { UseBusiness } from '../../../utils/hooks/Organization/business';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { UseBusinessGenerateCode } from '../../../utils/hooks/Organization/business';
import { useEventByOrgId } from '../../../utils/hooks/Events/events';

function AddOwnerModal(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { refetch } = UseBusinessGenerateCode(props.business._id);
    return (
        <>
            <Dialog
            open={props.open} 
            onClose={props.onClose} 
            PaperProps={{
                style: {
                    overflow: 'hidden',
                    backgroundColor: '#2d2d2d',
                    width: '600px',
                    height: '220px'
                }}}
            >
            <div onClick={e => e.stopPropagation()}>
            <DialogTitle className='ml-3'>Add Owners</DialogTitle>   
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <span className='btn-inner--text text-white ml-2'>Click the button to copy the invite link or generate a new one.</span>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <button className='btn btn-primary btn-block p-4' onClick={() => {
                                        copy(`${window.location.origin}/profile/join/${props.business.codeToJoin}/`)
                                        enqueueSnackbar('Invite link copied to clipboard', {variant: 'success', persist: false, autoHideDuration: 2000})
                                        props.onClose()
                                    }}>
                                        <FeatherIcon icon='copy' className='mr-2'/>
                                        Copy Invite Link
                                    </button>
                                </div>
                                <div className='col-md-6'>
                                    <button className='btn btn-secondary btn-block p-4' onClick={async () => {
                                        const { data } = await refetch();
                                        props.bussRefetch()
                                        copy(`${window.location.origin}/profile/join/${data}/`)
                                        enqueueSnackbar('Generated new link and copied to clipboard!', {variant: 'success'})
                                        props.onClose()
                                    }}>
                                        <FeatherIcon icon='link' className='mr-2'/>
                                        Generate New Link
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                </div>
            </Dialog>
        </>
    )
}


export default function Business({setPage, setEventId}) {
    const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
    const { data, isLoading, isError, isSuccess, refetch } = UseBusiness(user.organizationId)
    const { data: event, isLoading: eventLoading, isSuccess: eventSuccess } = useEventByOrgId(user.organizationId);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const toggleModal = () => {
        setOpen(!open)
    };
    const toggleAddOwnerModal = () => {
        setOpenEdit(!openEdit)
    }

    if(eventSuccess) {
        if(event.data.result.length > 0) setEventId(event.data.result[0]._id)
    }

    if(isLoading && eventLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

    if(isError) {
        console.log('deu ruim')
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-center">
                    <h1 className="text-2xl font-bold">It seems like you don't have a Organization yet!</h1>
                    <p>As you have now the role of Organization Owner, you can create your own Organization.</p>
                    <CreateModal userId={user._id}/>
                </div>
            </div>
        )
    }

    if(isSuccess) {
        return(
            <div class="slice slice-sm bg-section-secondary">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 card shadow-lg">
                                        <div className="row mt-5 mb-3 mx-2">
                                            <div className="col">
                                                    <img style={{ width: '100%' }} src={data.logoUrl} alt="Business Logo" className='avatar-image'></img>
                                            </div>
                                            <div className="col">
                                                <span>Name</span>
                                                <h1 className="h5 mb-0">{data.name}</h1>
                                            </div>
                                        </div>
                                        <div className="row">
                                        <div className="col">
                                                <button
                                                type="button"
                                                className="btn btn-sm btn-warning btn-icon d-flex align-items-center justify-content-center ml-4"
                                                onClick={toggleModal}
                                                style={{ width: '125px' }}
                                                >
                                                    <span className="btn-inner--text">Edit</span>
                                                    <EditBusinessModal business={data} open={open} onClose={toggleModal}/>
                                                    <span className="btn-inner--icon">
                                                    <FeatherIcon icon="edit" />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                {event?.data.result.length > 0 ? (
                                                    <button
                                                    type="button"
                                                    className="btn btn-sm btn-warning btn-icon d-flex align-items-center justify-content-center mr-4"
                                                    style={{ width: '125px' }}
                                                    onClick={() => {setPage('event')}}
                                                    >
                                                    <span className="btn-inner--text">See Event</span>    
                                                    </button>
                                                ) : (
                                                    <button
                                                    type="button"
                                                    className="btn btn-sm btn-warning btn-icon d-flex align-items-center justify-content-center mr-4"
                                                    style={{ width: '125px' }}
                                                    onClick={() => setPage('create')}
                                                    >
                                                    <span className="btn-inner--text">Create Event</span>    
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row d-flex align-items-center justify-content-between mb-0 mt-4 mx-2">
                                            <div>
                                                <h1 className="h5 mb-0 ml-3">Owners</h1>
                                            </div>
                                            <div className="mr-3">
                                                <button
                                                type="button"
                                                className="btn btn-sm btn-warning btn-icon"
                                                onClick={toggleAddOwnerModal}
                                                style={{ width: '125px' }}
                                                >
                                                <span className="btn-inner--text">Add Owner</span>
                                                <AddOwnerModal open={openEdit} onClose={toggleAddOwnerModal} business={data} bussRefetch={refetch}/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-5 mx-2">
                                            <div className="col-md-12">
                                            {data.owners ? data.owners.map((owner, index) => (
                                                <div className="row align-items-center"
                                                key={owner._id}
                                                style={{marginBottom: index+1 === data.owners.length ? '0.5rem' : '3rem'}}>
                                                    <div className="col-md-3">
                                                        <img style={{ width: '100%' }} src={owner.profilePicture} alt="Owner Profile" className='avatar-image'></img>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <span>Name</span>
                                                        <h1 className="h5 mb-0">{owner.firstName}</h1>
                                                        
                                                        <span>Email</span>
                                                        <h1 className="h5 mb-0">{owner.email}</h1>
        
                                                        <span>Phone</span>
                                                        <h1 className="h5 mb-0">{owner.phone}</h1>
                                                    </div>
                                                </div>
                                            )) : <h1 className="h5 mb-0">No Owners</h1>}
                                            </div>
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return <></>    
}