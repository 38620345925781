import React from "react";

import Header from "../../components/header/header";
import Content from "./components/content";
import Footer from "../../components/footer";

const Home = () => {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
};

export default Home;
