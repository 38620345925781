import { useQuery } from 'react-query';
import api from '../../api';

const fetchUser = async (slug) => {
  console.log('foi aqui?')
  const response = await api.get(`/user/slug/${slug}`)
  console.log(response)
  return await api.get(`/user/slug/${slug}`);
}

export const UseUser = (slug) => {
  return useQuery(['User', slug], () => api.get(`/user/slug/${slug}`));
}