import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';

const Content = () => {
  const [content, setContent] = useState(
    'Create an account or sign in to activate the QR code.',
  );

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    const localContact = localStorage.getItem('@SKANZ/unloggedcontact');
    if (localContact)
      setContent('Create an account or sign in to save the contact.');
  };

  return (
    <div class="container-fluid d-flex flex-column">
      <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
        <div class="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div class="row justify-content-center">
            <div class="col-11 col-lg-10 col-xl-6">
              <div>
                <div class="mb-4">
                  <h6 class="h3 mb-1">Welcome!</h6>
                  <p class="text-muted mb-0">{content}</p>
                </div>
                <a href="/login">
                  <button class="btn btn-block btn-warning">Sign in</button>
                </a>
                <div class="mt-4">
                  <a href="/sign-up">
                    <button class="btn btn-block btn-outline-warning">
                      Create account
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
