import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import './questRedirect.css';
import { 
  createScanRecord,
  updateAccount
} from '../../utils/questIntegration';


const QuestRedirect = () => {
  const [collectionPoint, setCollectionPoint] = useState(null);
  const { variable } = useParams();
  const history = useHistory();

  const hardcodedQuestId = 'e97ea89f-6ee6-4caf-8780-88d1340bce78'; // hardcoded quest_id

  useEffect(() => {
    let accountId = localStorage.getItem('@SKANZ/accountId');
    let token = localStorage.getItem('@SKANZ/questToken'); // retrieve the saved token
    let collectionPointId;

    switch(variable) {
      case 'ccr':
        collectionPointId = 'b64bd243-789f-4201-bb05-70abdff612c7';
        break;
      case 'grain':
        collectionPointId = '8463f9c2-5311-4df5-b432-b38b5b7d1dfd';
        break;
      case 'office':
        collectionPointId = '1e19cf65-b8a1-4efc-8b49-ec6004c891c0';
        break;
      case 'grainpurchase':
        collectionPointId = 'c6dd6dd4-bd9f-4f72-83a7-88c7c6b1b889';
        break;
      case 'parking':
        collectionPointId = '0e2cf605-9140-4cf9-ad84-265816b30840';
        break;
      case 'linkraleigh':
        collectionPointId = '00181356-919d-4736-bb5c-a8f2ce700a65';
        break;

      case 'ccr-checkin':
        collectionPointId = '1bd82bf8-f8af-4919-b873-62bccc540a92';
        break;

      case 'sip-and-swing':
        collectionPointId = '3b31f4eb-6002-4b95-93e1-56f200552f14';
        break;

      default:
        collectionPointId = '';
    }

    const fetchAndModifyAccount = async () => {
      try {
        // Fetch the collection point data
        let pointResponse = await axios.get(`https://api.quest.circlepass.io/api/collection_point/${collectionPointId}`);
        let questId = pointResponse.data.data.quest_id; // get the quest_id from the response
        setCollectionPoint(pointResponse.data.data);
    
        // Update the account with the new quest_ids and collection_points
        await updateAccount(accountId, collectionPointId, token, questId);
            
        // Create a new scan record
        await createScanRecord(accountId, collectionPointId);
    
        // Redirect to /quests after a short delay
        setTimeout(() => {
          history.push("/quests");
        }, 4000);
      } catch (error) {
        console.error('Error message:', error.message);
        console.error('Error object:', error);
      }
    }
    
    if(accountId && token && collectionPointId) {
      fetchAndModifyAccount();
    } else if(collectionPointId) {
    
      // Fetch the collection point data
      axios.get(`https://api.quest.circlepass.io/api/collection_point/${collectionPointId}`)
        .then(response => {
          let questId = response.data.data.quest_id; // get the quest_id from the response
          setCollectionPoint(response.data.data);
          localStorage.setItem('@SKANZ/tempCollectionPoint', collectionPointId);
          localStorage.setItem('@SKANZ/tempQuest', questId);
        })
        .catch(error => {
          console.error('Error message:', error.message);
          console.error('Error object:', error);
        });
    }
  
}, [variable, history]);

return (
  collectionPoint ? (
    <Container className="text-center py-4">
      <Row className="align-items-center justify-content-center mt-5 mb-5">
        <Col xs={10} md={4}>
        <img src={collectionPoint.image_url} alt={collectionPoint.collector_name} className="img-fluid animated-image" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          <h2><b>Congratulations!</b></h2> 
          <h6>You just unlocked the {collectionPoint.collector_name} badge!</h6>
          {!localStorage.getItem('@SKANZ/accountId') || !localStorage.getItem('@SKANZ/questToken') ? (
            <div>
              <p>To claim it:</p>
              <Button className="login-btn" onClick={() => history.push("/login")}>Login</Button>
              <Button className="signup-btn" onClick={() => history.push("/sign-up")}>Sign Up</Button>

            </div>
          ) : <Spinner animation="border" size="sm" />}
        </Col>
      </Row>
    </Container>
  ) : null
);

};

export default QuestRedirect;
