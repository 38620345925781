import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import CircularProgress from '@mui/material/CircularProgress';
import CsvDownloader from 'react-csv-downloader';

import api from '../../../utils/api';

import Side from './side';

const Users = () => {
  const { selector } = useParams();

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  const getUrl = () => {
    switch (selector) {
      case 'all':
        return '';
      case 'user':
        return 'nonAdmin';
      case 'admin':
        return 'admin';
      case 'owner':
        return 'owner';
      case 'subscription':
        return 'subscription';
      default:
        return '';
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await api.get(`/admin/user/list/${getUrl()}`);
      setUsers(data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const UserItem = ({ user }) => {
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [codes, setCodes] = useState(null);

    const getUserDetails = async () => {
      try {
        const { data } = await api.get(`/admin/qrcode/user/count/${user._id}`);
        setCodes(data.qrs);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      if (expanded && !codes) {
        getUserDetails();
      }
    }, [expanded]);

    console.log(user);

    return (
      <div
        onClick={() => {
          setExpanded(!expanded);
        }}
        class="card custom-card mt-3 mb-0">
        <div class="p-3 row">
          <div class="col-md-1">
            <div class="avatar-container-user">
              <img
                alt="placeholder"
                src={user?.profilePicture ?? '/assets/img/newUser.png'}
                class="avatar-image"
              />
            </div>
          </div>
          <div class="col-md-4">
            <span>Name</span>
            <h1 class="h5 mb-0">{`${user?.firstName} ${user?.lastName}`}</h1>
          </div>
          <div class="col-md-5">
            <span>E-Mail</span>
            <h1 class="h5 mb-0">{user?.email}</h1>
          </div>
          <div class="col-md-2">
            {getUrl() === 'subscription' ? (
              <span>Plan</span>
            ) : (
              <span>Role</span>
            )}
            <div>
              {user?.roleId === 'admin' && (
                <p class="badge badge-danger">ADMIN</p>
              )}
              {user?.plans.isOrganization && (
                <>
                  <p class="badge badge-success">ORG_OWNER</p>
                </>
              )}
              {user.plans !== undefined && user?.roleId !== 'admin' && (
                <>
                  {user?.plans.isEventIncluded ? (
                    <p class="badge badge-warning">EVENTS</p>
                  ) : (
                    <>
                      {user?.plans.isPremium ? (
                        <p class="badge badge-success">PREMIUM</p>
                      ) : (
                        <>
                          {user.roleId === 'user' ? (
                            <p class="badge badge-primary">USER</p>
                          ) : (
                            <p class="badge badge-warning">EMPLOYEE</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {expanded && (
          <div class="bottom-radius-sm dark-bg ">
            <div class="p-3">
              {loading ? (
                <div class="loading-container-user">
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <div>
                  <div class="row">
                    <div class="col-md-5">
                      <span>Last Login</span>
                      <h1 class="h5 mb-0">
                        {new Date(user?.lastLogin).toLocaleDateString()}{' '}
                        {new Date(user?.lastLogin).toLocaleTimeString()}
                      </h1>
                    </div>
                    <div class="col-md-5">
                      <span>Activated Codes</span>
                      <div>
                        <span class="badge badge-secondary">
                          {codes?.length}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>User Profile</span>
                      <div>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`/out-profile/${user?.userslug}`}>
                          <span
                            style={{ cursor: 'pointer' }}
                            class="badge badge-primary">
                            OPEN
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-5">
                      <span>Profile Views Count</span>
                      <div>
                        <span class="badge badge-secondary">
                          {user?.profileViews ?? 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  {codes?.length > 0 && (
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <span>QR Codes</span>
                        <div>
                          {codes?.map((item) => (
                            <button
                              onClick={() =>
                                window.open(`/admin/qr/${item._id}`, '_blank')
                              }
                              type="button"
                              class="btn btn-success btn-sm">
                              {item.code}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const searchFilter = (user) => {
    if (
      `${user?.firstName} ${user?.lastName}`
        .toLowerCase()
        .includes(search.toLowerCase())
    )
      return true;

    if (`${user?.email}`.toLowerCase().includes(search.toLowerCase()))
      return true;

    if (`${user?.phone}`.toLowerCase().includes(search.toLowerCase()))
      return true;

    if (
      user?.contactPhones.join(',').toLowerCase().includes(search.toLowerCase())
    )
      return true;

    if (
      user?.contactEmails.join(',').toLowerCase().includes(search.toLowerCase())
    )
      return true;
  };
  const datas = [];

  for (const user of users) {
    datas.push({
      NAME: `${user?.firstName} ${user?.lastName}`,
      EMAIL: user?.email,
    });
  }

  return (
    <>
      <section class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <Side />
            </div>
            <div class="col-md-10">
              <div class="row align-items-center mb-4">
                <div class="col">
                  <h1 class="h5 mb-0">Users</h1>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    class="form-control-sm form-control"
                    placeholder="Search"
                  />
                </div>
                <div class="col">
                  <button
                    onClick={() => setSearch('')}
                    type="button"
                    class="btn btn-sm btn-warning ">
                    <span class="btn-inner--icon">
                      <FeatherIcon icon="x" />
                    </span>
                  </button>
                </div>
                <div class="col-auto">
                  <CsvDownloader
                    filename="email-list"
                    extension=".csv"
                    datas={datas}>
                    <button type="button" class="btn btn-sm btn-warning ">
                      Download all users emails
                    </button>
                  </CsvDownloader>
                </div>
              </div>
              {users.filter(searchFilter).map((user) => (
                <UserItem user={user} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
