import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';

import api from '../../../utils/api';

import Side from './side';

const Content = () => {
  const [tattoos, setTattoos] = useState(0);
  const [users, setUsers] = useState(0);
  const [scanCount, setScanCount] = useState(0);

  const user = useState(JSON.parse(localStorage.getItem('@SKANZ/user')));

  useEffect(() => {
    getCount();
  }, []);

  const getCount = async () => {
    try {
      const { data } = await api.get('/admin/user/count/tattoosUsers');
      setTattoos(data.tattoos);
      setUsers(data.users);
      setScanCount(data.scanCount);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section class="slice py-5 bg-section-secondary">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <Side />
          </div>
          <div class="col-md-10">
            <div class="row align-items-center mb-4">
              <div class="col">
                <h1 class="h5 mb-0">Dashboard</h1>
              </div>
            </div>
            <div class="row mx-n2">
              <div class="col-sm-6 col-lg-4 px-2">
                <div class="card mb-3">
                  <div class="card-header pb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <span class="h6">All users</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="icon icon-sm">
                          <img
                            alt="placeholder"
                            src={
                              user?.profilePicture ?? '/assets/img/newUser.png'
                            }
                            class="svg-inject"
                            style={{ width: 50, height: 50 }}
                          />
                        </div>
                      </div>
                      <div class="text-right">
                        <div class="d-flex align-items-center">
                          <div>
                            <span class="badge badge-xs badge-circle rounded-circle badge-success">
                              <FeatherIcon icon="check" />
                            </span>
                          </div>
                          <div class="pl-2">
                            <small class="h6 text-xs text-success">
                              {users}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <h6 class="mb-0">{users}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 px-2">
                <div class="card mb-3">
                  <div class="card-header pb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <span class="h6">All codes</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="icon icon-sm">
                          <img
                            alt="placeholder"
                            src="/assets/img/qrcode.png"
                            class="svg-inject"
                            style={{ width: 50, height: 50 }}
                          />
                        </div>
                      </div>
                      <div class="text-right">
                        <div class="d-flex align-items-center">
                          <div>
                            <span class="badge badge-xs badge-circle rounded-circle badge-success">
                              <FeatherIcon icon="check" />
                            </span>
                          </div>
                          <div class="pl-2">
                            <small class="h6 text-xs text-success">
                              {tattoos}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <h6 class="mb-0">{tattoos}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 px-2">
                <div class="card mb-3">
                  <div class="card-header pb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <span class="h6">All Scan Count</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="icon icon-sm">
                          <img
                            alt="placeholder"
                            src={'/assets/img/qrcode.png'}
                            class="svg-inject"
                            style={{ width: 50, height: 50 }}
                          />
                        </div>
                      </div>
                      <div class="text-right">
                        <div class="d-flex align-items-center">
                          <div>
                            <span class="badge badge-xs badge-circle rounded-circle badge-success">
                              <FeatherIcon icon="check" />
                            </span>
                          </div>
                          <div class="pl-2">
                            <small class="h6 text-xs text-success">
                              {scanCount}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <h6 class="mb-0">{scanCount}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
