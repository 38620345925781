import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

import api from '../../../../utils/api';

export default function AddOwnerModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [icon, setIcon] = useState('copy');
  const [codeToJoin, setCodeToJoin] = useState(props.codeToJoin);
  const base_url = window.location.origin;

  const Copy = () => {
    setIcon('check');
    enqueueSnackbar('Successfully copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 3000,
    });
    copy(`${base_url}/${codeToJoin}`);
    setTimeout(() => setIcon('copy'), 3000);
  };

  const regenerate = async () => {
    const { data } = await api.get(`business/generate/${props.businessId}`);
    setCodeToJoin(data);
    enqueueSnackbar('Successfully regenerated the code', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            overflow: 'hidden',
            backgroundColor: '#2d2d2d',
            padding: '1.5rem',
            width: '100%',
          },
        }}>
        <div onClick={(e) => e.stopPropagation()}>
          <DialogContent className="overflow-hidden p-2">
            <p className="h2 text-white">Link</p>
            <p className="text-secondary">
              Copy the link to invite people to your organization!
            </p>
            <div className="bg-light d-flex justify-items-between align-items-center px-4 py-2 rounded w-100">
              <input
                className="bg-light rounded w-100 text-secondary border-0"
                value={`${base_url}/${codeToJoin}`}
                disabled={true}
              />
              <FeatherIcon
                icon={icon}
                className={`text-${
                  icon === 'copy' ? 'secondary' : 'success'
                } ml-2`}
                onClick={Copy}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              onClick={regenerate}
              className="btn btn-sm btn-outline-warning btn-icon mb-4"
              style={{ height: '39px' }}>
              Regenerate
            </button>
            <button
              onClick={props.onClose}
              className="btn btn-sm btn-warning btn-icon mb-4"
              style={{ height: '39px', width: '85px' }}>
              Done
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
