import React, { useState, useEffect } from 'react';

import api from '../../../utils/api';

const ModalEditTattoo = ({ setEditTattooModal, editTattooModal }) => {
  const [value, setValue] = useState(editTattooModal.link);
  const [nickname, setNickname] = useState(editTattooModal.nickname);

  const submitData = async e => {
    e.preventDefault();
    try {
      await api.put('/user/edit', {
        code: editTattooModal.code,
        link: value,
        nickname,
      });
      window.location.href = '/dashboard';
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 10000,
        backgroundColor: '#00000050',
      }}
    >
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit code
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setEditTattooModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={e => submitData(e)}>
                {!editTattooModal.link?.includes('/out-profile/') && (
                  <div class="row mb-3">
                    <div class="col">
                      <label class="form-control-label">Embedded link</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ex.: http://google.com"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                      />
                      <input type="hidden" id="id" name="id" />
                    </div>
                  </div>
                )}
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nickname</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Ex.: Personal code"
                      value={nickname}
                      onChange={e => setNickname(e.target.value)}
                    />
                    <input type="hidden" id="id" name="id" />
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditTattoo;
