import React, { useState } from 'react';

import Header from './components/header';
import Quests from './components/quests';

const UserQuests = () => {

  return (
    <>
      <Header />
      <Quests/>
    </>
  );
};

export default UserQuests;
