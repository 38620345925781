import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useBusinesses } from '../../../utils/hooks/Organization/business';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import api from '../../../utils/api';
import { useStore } from '../../../store/user';

export default function AcceptToJoinPrompt(props) {
  const { data, isError, isLoading, isSuccess, remove } = useBusinesses();
  const { enqueueSnackbar } = useSnackbar();
  const [organization, setBusiness] = React.useState(null);
  const history = useHistory();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  if (isSuccess) {
    const business = data.find((business) => {
      return business.codeToJoin === props.codeToJoin;
    });
    if (!business && props.codeToJoin) {
      history.push('/profile');
      enqueueSnackbar('Business not found or the link is not valid anymore!', {
        variant: 'error',
      });
      remove();
      props.onClose();
    }
    if (!organization && business) {
      setBusiness(business);
    }
  }

  const joinOrganization = async () => {
    try {
      await api.put(`/business/${organization._id}/owner`, {
        codeToJoin: props.codeToJoin,
        userId: props.userId,
      });
      enqueueSnackbar('You have joined the organization!', {
        variant: 'success',
      });
      const newUser = {
        ...user,
        roleId: 'employee',
      };
      setUser(newUser);
    } catch (error) {
      enqueueSnackbar(`Failed to join: ${error.response.data.error}`, {
        variant: 'error',
      });
    }
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#2d2d2d',
            width: '500px',
          },
        }}>
        <DialogTitle id="alert-dialog-title">
          {'Join Organization?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-secondary">
            <p>Join {organization?.name}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={props.onClose}
            className="btn btn-outline-secondary btn-sm py-2 px-4 mx-2 mb-4 w-25">
            Cancel
          </button>
          <button
            onClick={() => {
              joinOrganization();
              props.onClose();
            }}
            autoFocus
            className="btn btn-warning btn-sm py-2 px-4 mx-2 mr-4 mb-4 w-25">
            Join
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
