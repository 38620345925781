import React, { useState } from 'react';

import api from '../../../utils/api';

const ModalConfirmAction = ({ setModalConfirmAction, type }) => {
  const [shareOwnProfile, setShareOwnProfile] = useState(false);
  const confirmAction = () => {
    if (type === 'contact') {
      saveContact();
    } else {
      activateQr();
    }
  };

  const saveContact = async () => {
    const _id = JSON.parse(localStorage.getItem('@SKANZ/unloggedcontact')).user
      ._id;
    const eventId = JSON.parse(
      localStorage.getItem('@SKANZ/unloggedcontact'),
    ).event;
    try {
      await api.post('/user/contacts', {
        user: _id,
        event: eventId,
        shareOwnProfile,
      });
      setModalConfirmAction(false);
      window.location.href = '/contacts';
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem('@SKANZ/unloggedcontact');
    setModalConfirmAction(false);
  };

  const activateQr = async () => {
    const unloggedQr = localStorage.getItem('@SKANZ/unloggedqr');
    try {
      await api.post('/user/activate', { code: unloggedQr });
      setModalConfirmAction(false);
      window.location.href = '/dashboard';
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem('@SKANZ/unloggedqr');
  };

  const cancelAction = () => {
    localStorage.removeItem('@SKANZ/unloggedqr');
    localStorage.removeItem('@SKANZ/unloggedcontact');
    setModalConfirmAction(false);
  };

  const content = () => {
    switch (type) {
      case 'contact':
        return {
          title: 'Add Contact',
          description:
            'Are you sure you want to add this contact to your list?',
        };
      case 'qr':
        return {
          title: 'Activate QR Code',
          description: 'Are you sure you want to activate the QR code?',
        };
      default:
        return {
          title: 'Add Contact',
          description:
            'Are you sure you want to add this contact to your list?',
        };
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 10000,
        backgroundColor: '#00000050',
      }}>
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {content().title}
              </h5>
            </div>
            <div class="modal-body">
              <p class="text-sm text-white mb-3">{content().description}</p>
              {type === 'contact' && (
                <>
                  <input
                    type="checkbox"
                    className="mr-2 mt-1"
                    onChange={() => setShareOwnProfile(!shareOwnProfile)}
                  />
                  Share my profile with this contact.
                </>
              )}
              <div class="row">
                <div class="col text-right">
                  <button
                    onClick={() => cancelAction()}
                    class="btn btn-sm btn-outline-danger mr-2 mt-3">
                    Cancel
                  </button>
                  <button
                    onClick={() => confirmAction(false)}
                    class="btn btn-sm btn-warning mr-2 mt-3">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmAction;
