import React from "react";

import Header from "./components/header";
import Content from "./components/content";

const Login = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default Login;
