import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSnackbar } from 'notistack';

import api from '../../../utils/api';
import axios from 'axios';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    border: '1px solid var(--orange)',
    color: state.selectProps.menuColor,
    padding: 20,
    backgroundColor: '#2d2d2d',
    '& > div': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 5,
    },
  }),

  option: (styles, { isFocused }) => ({
    ...styles,
    border: `1px solid ${isFocused ? 'var(--orange)' : 'transparent'}`,
    backgroundColor: isFocused ? '#2a2a2a' : null,
    borderRadius: 5,
    cursor: 'pointer',
  }),

  container: (styles) => ({
    ...styles,
    boxShadow: '0 0 0 0 transparent',
    ':active': {
      boxShadow: '0 0 0 0 transparent',
      borderColor: 'var(--orange)',
    },
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#333333',
    borderRadius: '.375rem',
    transition:
      'transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    borderColor:
      state.isSelected || state.isFocused ? 'var(--orange)' : 'transparent',
    backgroundColor: state.isSelected || state.isFocused ? '#111' : '#333333',
    ':hover': {
      borderColor: 'var(--orange)',
      boxShadow: 'none',
    },
    boxShadow: 'none',
    padding: 5,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const shapeStyles = {
  option: (styles, { isFocused }) => ({
    ...styles,
    color: '#000',
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#333333',
    borderRadius: '.375rem',
    transition:
      'transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    borderColor:
      state.isSelected || state.isFocused ? 'var(--orange)' : 'transparent',
    backgroundColor: state.isSelected || state.isFocused ? '#111' : '#333333',
    ':hover': {
      borderColor: 'var(--orange)',
      boxShadow: 'none',
    },
    boxShadow: 'none',
    padding: 5,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: '#FFFFFF' };
  },
};

const EditUser = () => {
  const _localUser = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [user, setUser] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);

  const [contactEmails, setContactEmails] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [colors, setColors] = useState([]);
  const [loadingColor, setLoadingColor] = useState(false);
  const [color, setColor] = useState(false);
  const [files, setFiles] = useState([]);

  console.log(user);
  useEffect(() => {
    console.log('new color: ', color);
  }, [color]);

  useEffect(() => {
    getUser();
    getColorOptions();
  }, []);

  useEffect(() => {
    checkAddEmail();
  }, [contactEmails]);

  useEffect(() => {
    checkAddPhone();
  }, [phoneNumbers]);

  const getUser = () => {
    const localUser = JSON.parse(localStorage.getItem('@SKANZ/user'));
    setUser(localUser);
    setChecked(localUser.showProfilePicture);
    setContactEmails(localUser.contactEmails);
    setPhoneNumbers(
      localUser?.contactPhones ? localUser?.contactPhones : [localUser.phone],
    );
  };

  const getColorOptions = async () => {
    try {
      const colors = await api.get('/user/color/icon/options');

      setColors(colors.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const saveColors = async () => {
  //   try {
  //     setLoadingColor(true);
  //     const response = await api.put('/user/color/icon', {
  //       color: color.label,
  //     });

  //     console.log(response.data);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setLoadingColor(false);
  //   }
  // };

  const submitData = async (e) => {
    e.preventDefault();
    if (files.length + user.gallery.length > 3) {
      enqueueSnackbar('You can only upload 3 images', {
        variant: 'error',
      });
      return;
    }
    const images = await uploadFiles(files);
    try {
      const [{ data }, response] = await Promise.all([
        api.put('/user/info', {
          ...user,
          contactEmails: contactEmails.filter((n) => n),
          contactPhones: phoneNumbers.filter((n) => n),
          profileIconColor: undefined,
          gallery: images ? [...user.gallery, ...images] : user.gallery,
        }),
        color.label
          ? api.put('/user/color/icon', {
              color: color.label,
            })
          : null,
      ]);
      enqueueSnackbar('Successfully update the profile!', {
        variant: 'success',
      });
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));
      window.location.href = '/profile';
    } catch (error) {
      enqueueSnackbar(`Could not update the profile! Error: ${error}`, {
        variant: 'error',
      });
      console.log(error);
    }
  };

  const updateContactEmails = (value, index) => {
    let _contactEmails = [...contactEmails];
    _contactEmails[index] = value;
    setContactEmails(_contactEmails);
  };

  const checkAddEmail = () => {
    let filledFields = 0;
    let emptyFields = [];
    contactEmails.forEach((email, index) => {
      if (email?.length > 0) return filledFields++;
      emptyFields.push(index);
    });
    if (filledFields === contactEmails.length - 1) return;
    if (emptyFields.length > 1) {
      let _contactEmails = [...contactEmails];
      _contactEmails.splice(emptyFields[0], 1);
      return setContactEmails(_contactEmails);
    }
    setContactEmails([...contactEmails, '']);
  };

  const updatePhoneNumbers = (value, index) => {
    let _phoneNumbers = [...phoneNumbers];
    _phoneNumbers[index] = value;
    setPhoneNumbers(_phoneNumbers);
  };

  const checkAddPhone = () => {
    let filledFields = 0;
    let emptyFields = [];
    phoneNumbers.forEach((phone, index) => {
      if (phone?.length > 0) return filledFields++;
      emptyFields.push(index);
    });
    if (filledFields === phoneNumbers.length - 1) return;
    if (emptyFields.length > 1) {
      let _phoneNumbers = [...phoneNumbers];
      _phoneNumbers.splice(emptyFields[0], 1);
      return setPhoneNumbers(_phoneNumbers);
    }
    setPhoneNumbers([...phoneNumbers, '']);
  };

  const customOption = ({ value, label }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            backgroundColor: value.secondary,
            width: 30,
            height: 30,
            borderRadius: 8,
          }}
        />
      </div>
    );
  };

  const deletePictureFromGallery = async (id, url) => {
    try {
      await api.delete(`/user/gallery/`, {
        data: {
          id,
          url,
        },
      });
      enqueueSnackbar('Successfully delete the picture!', {
        variant: 'success',
      });

      // update the editUser state
      const newGallery = user.gallery.filter((item) => item._id !== id);
      setUser({ ...user, gallery: newGallery });
    } catch (error) {
      enqueueSnackbar(`Could not delete the picture! Error: ${error}`, {
        variant: 'error',
      });
      console.log(error);
    }
  };

  const uploadFiles = async (files) => {
    const images = [];
    for (const file of files) {
      const response = await api.post('/upload/signedUrl', {
        fileExtension: file.name.split('.').pop()[1],
      });
      await axios.put(response.data.signedUrl.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      images.push({
        url:
          `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` +
          response.data.signedUrl.key,
      });
    }
    return images;
  };

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={(e) => submitData(e)}>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="form-control-label">First Name</label>
                <input
                  className="light-input form-control"
                  type="text"
                  required
                  value={user.firstName}
                  onChange={(e) =>
                    setUser({ ...user, firstName: e.target.value })
                  }
                  placeholder="Ex.: John"
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">Last Name</label>
                <input
                  className="light-input form-control"
                  type="text"
                  value={user.lastName}
                  onChange={(e) =>
                    setUser({ ...user, lastName: e.target.value })
                  }
                  placeholder="Ex.: Doe"
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">Company</label>
                <input
                  className="light-input form-control"
                  type="text"
                  value={user?.company}
                  onChange={(e) =>
                    setUser({ ...user, company: e.target.value })
                  }
                  placeholder="Ex.: Company Name"
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">Position</label>
                <input
                  className="light-input form-control"
                  type="text"
                  value={user?.position}
                  onChange={(e) =>
                    setUser({ ...user, position: e.target.value })
                  }
                  placeholder="Ex.: CEO"
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">Phone Numbers</label>
                {phoneNumbers.map((item, index) => (
                  <input
                    className="grouped-inputs light-input form-control"
                    type="text"
                    value={item}
                    onChange={(e) => updatePhoneNumbers(e.target.value, index)}
                    placeholder="Ex.: +1-123-456-7890"
                  />
                ))}
              </div>
              <div className="form-group">
                <label className="form-control-label">Contact Emails</label>
                {contactEmails.map((item, index) => (
                  <input
                    className="grouped-inputs light-input form-control"
                    type="text"
                    value={item}
                    onChange={(e) => updateContactEmails(e.target.value, index)}
                    placeholder="Ex.: email@company.com"
                  />
                ))}
              </div>
              <div className="form-group">
                <label className="form-control-label">About</label>
                <textarea
                  value={user.bio}
                  onChange={(e) => setUser({ ...user, bio: e.target.value })}
                  className="light-input form-control"
                  rows={3}
                />
              </div>
              {user?.plans?.isPremium !== true &&
                user?.plans?.isOrganization !== true &&
                user?.roleId !== 'employee' &&
                user?.roleId !== 'org_owner' && (
                  <>
                    <div className="form-group">
                      <label className="form-control-label">Profile Color</label>
                      <Select
                        placeholder="Select a color combination"
                        styles={customStyles}
                        formatOptionLabel={customOption}
                        options={colors}
                        value={
                          color
                            ? color
                            : {
                                value: _localUser.profileIconColor,
                                label: '',
                              }
                        }
                        onChange={setColor}
                        className="light-input form-control"
                      />
                    </div>
                  </>
                )}
              <div className="form-group">
                <label className="form-control-label">Profile Picture</label>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                      setUser({
                        ...user,
                        showProfilePicture: e.target.checked,
                      });
                    }}
                  />
                  <label
                    className="custom-control-label text-white"
                    for="customCheck1">
                    Show Profile Picture
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-control-label">Profile Image Shape</label>
                <Select
                  placeholder="Click to select"
                  styles={shapeStyles}
                  options={[
                    { value: 'Square', label: 'Square' },
                    { value: 'Circle', label: 'Circle' },
                  ]}
                  value={{
                    value: user?.profileImageFormat,
                    label: user?.profileImageFormat,
                  }}
                  onChange={(e) =>
                    setUser({ ...user, profileImageFormat: e.value })
                  }
                  className="light-input form-control"
                />
              </div>
            </div>
          </div>
          {user?.employeeGallery && (
            <>
              <div className="form-group">
                <label className="form-control-label">Gallery Pictures</label>
                <input
                  class="light-input form-control"
                  type="file"
                  multiple
                  onChange={(e) => {
                    setFiles(e.target.files);
                  }}
                />
                {user.gallery.length > 0 && (
                  <div className="form-group">
                    <label className="form-control-label">
                      Gallery Pictures
                    </label>
                    <div className="row">
                      {user.gallery.map((image) => (
                        <div
                          className="col-11 col-md-5 ml-3 mb-3 position-relative d-flex flex-column align-items-center justify-content-center border border-secondary rounded mr-3"
                          key={image._id}>
                          <img
                            src={image.url}
                            alt="gallery"
                            // fit the image to constrain the size
                            style={{
                              objectFit: 'contain',
                              width: '200px',
                              height: '150px',
                            }}
                            className="img-fluid p-2"
                          />
                          <div
                            onClick={() => {
                              deletePictureFromGallery(image._id, image.url);
                            }}>
                            <i className="fas fa-trash text-danger" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div class="row">
            {/* <div class="col">
              <button
                type="button"
                class="btn btn-sm btn-warning mr-2 mt-3"
                onClick={saveColors}
              >
                Save colors
              </button>
            </div> */}
            <div class="col text-right">
              <button
                type="submit"
                class="btn btn-sm btn-outline-warning mr-2 mt-3">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
