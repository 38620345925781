import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import QRCode from 'qrcode.react';
import { useParams } from 'react-router-dom';

import api from '../../../utils/api';

import Side from './side';

const Details = () => {
  const { qrId } = useParams();

  const [qr, setQr] = useState({});

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const { data } = await api.get(`/admin/qr/${qrId}`);
      setQr(data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadImage = async () => {
    const qrCodeURL = document
      .getElementById(`qr`)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `QRCode-${qr?.code}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <>
      <section class="slice py-5 bg-section-secondary">
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <Side />
            </div>
            <div class="col-md-10">
              <div class="row align-items-center mb-4">
                <div class="col">
                  <h1 class="h5 mb-0">
                    <button
                      type="button"
                      class="btn btn-sm btn-warning btn-icon"
                      onClick={() =>
                        (window.location.href = '/admin/codes/all')
                      }
                    >
                      <span class="btn-inner--icon">
                        <FeatherIcon icon="arrow-left" />
                      </span>
                      <span class="btn-inner--text">Return</span>
                    </button>
                    <span class="ml-3">QR Code Details</span>
                  </h1>
                </div>
              </div>
              <div class="card p-3 mb-4">
                <div class="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div class="col-md-3">
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: '#FFF',
                        borderRadius: 10,
                        padding: 10,
                      }}
                    >
                      <QRCode
                        id="qr"
                        size={1000}
                        style={{
                          borderRadius: 5,
                          width: '100%',
                          height: '100%',
                        }}
                        value={`${process.env.REACT_APP_BASE_QR}${qr?.code}`}
                      />
                    </div>
                  </div>
                  <div style={{ height: '100%', flex: 1 }} class="col-md-9">
                    <div class="row ">
                      <div class="col-md-12">
                        <span>Code</span>
                        <h1 class="h5 mb-0">{qr?.code}</h1>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <span>Status</span>
                        {qr?.active ? (
                          <div>
                            <span class="badge badge-success">Active</span>
                          </div>
                        ) : (
                          <div>
                            <span class="badge badge-warning">Inactive</span>
                          </div>
                        )}
                      </div>
                      <div class="col-md-6">
                        <span>Times Scanned</span>
                        <div>
                          <span class="badge badge-secondary">
                            {qr?.scanCount ?? 0}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: '50px', marginBottom: '1px' }}>
                      <button
                        onClick={() => downloadImage()}
                        type="button"
                        class="btn btn-warning btn-sm btn-block"
                      >
                        Download .png
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      display: 'none',
                    }}
                  >
                    <QRCode
                      id="qr"
                      size={1000}
                      style={{ borderRadius: 5 }}
                      value={`${process.env.REACT_APP_BASE_QR}${qr?.code}`}
                    />
                  </div>
                </div>
              </div>
              {qr?.user && (
                <>
                  <h1 class="h5 mb-2">Activated By</h1>
                  <div class="card">
                    <div class="p-3 row">
                      <div class="col-md-1">
                        <div class="avatar-container-user">
                          <img
                            alt="placeholder"
                            src={
                              qr?.user?.profilePicture ??
                              '/assets/img/newUser.png'
                            }
                            class="avatar-image"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <span>Name</span>
                        <h1 class="h5 mb-0">{`${qr?.user?.firstName} ${qr?.user?.lastName}`}</h1>
                      </div>
                      <div class="col-md-5">
                        <span>E-Mail</span>
                        <h1 class="h5 mb-0">{qr?.user?.email}</h1>
                      </div>
                      <div class="col-md-2">
                        <span>Role</span>
                        <div>
                          {qr?.user?.roleId === 'admin' && (
                            <p class="badge badge-danger">ADMIN</p>
                          )}
                          {qr?.user?.roleId === 'user' && (
                            <p class="badge badge-success">USER</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {qr?.user && (
                <>
                  <h1 class="h5 mb-2">Connection</h1>
                  <div class="card">
                    <div class="p-3 row">
                      <div class="col-md-12">
                        <span>Link</span>
                        <a target="_blank" rel="noreferrer" href={qr?.link}>
                          <h1 class="h5 mb-0">{qr?.link}</h1>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
