import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import api from '../../../utils/api';
import { pricesId } from '../../../utils/stripe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './users.css';

const Content = () => {
  const [amount, setAmount] = useState(1);
  const [billingOption, setBillingOption] = useState('monthly');
  const [eventSelectPlan, setEventSelectPlan] = useState('monthly');
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));

  const handleUpdate = (plus = true) => {
    if (plus) {
      if (amount >= 15) return;
      setAmount(amount + 1);
    } else {
      if (amount <= 1) return;
      setAmount(amount - 1);
    }
  };

  const handleBillingOptionChange = (option) => {
    setBillingOption(option);
  };

  const submit = async () => {
    try {
      const { data } = await api.post('/order/new', { amount: amount });
      window.location.href = data.paymentUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckout = async (option) => {
    try {
      let priceId;
      if (option === 'user') {
        if (billingOption === 'monthly') {
          priceId = pricesId.premiumPlanMonth;
        } else {
          priceId = pricesId.premiumPlanYearly;
        }
      } else if (option === 'subscription_plan') {
        if (eventSelectPlan === 'monthly') {
          priceId = pricesId.eventPlanMonth;
        } else {
          priceId = pricesId.eventPlanYearly;
        }
      }
      const payload = option ? { userId: user._id, option } : { userId: user._id };
      if (priceId) {
        payload.price_id = priceId;
      }
      const { data } = await api.post('/order/subscribe', payload);
      window.location.href = data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const PriceComponent = () => (
    <div className={`price-container ${isMobile ? 'price-container-mobile' : 'price-container-desktop'}`}>
      <button
        onClick={() => handleUpdate(false)}
        className={`btn btn-secondary btn-sm ${amount <= 1 && 'disabled'} ${isMobile && 'btn-block'}`}
      >
        <FeatherIcon icon="minus" />
      </button>
      <div className="price-button">{isMobile ? <div className="price-amount-mobile">{amount}</div> : amount}</div>
      <button
        onClick={() => handleUpdate()}
        className={`btn btn-secondary btn-sm ${amount >= 15 && 'disabled'} ${isMobile && 'btn-block'}`}
      >
        <FeatherIcon icon="plus" />
      </button>
    </div>
  );

  return (
    <div className="dark-mode">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="row align-items-center mb-4">
              <div className="col">
                <h1 className="h2 mb-2">Buy Digital Codes</h1>
                <div className="row mb-0">
                  <div className="col-xl-12">
                    <div className="card card-fluid">
                      <div>
                        <div className="qr-code-container">
                          <div className="qr-code-image">
                            <QRCode size={80} className="qr-code-svg" value="123123123" />
                          </div>
                          <div className="price-info" style={{ marginLeft: isMobile ? 'auto' : '5%' }}>
                            <div>Price</div>
                            <div className="price-info-label">
                              {billingOption === 'monthly' ? '$5' : '$5'}
                            </div>
                          </div>
                          <div className="total-info" style={{ marginLeft: '5%', marginRight: isMobile ? '5%' : '0px' }}>
                            <div>Total</div>
                            <div className="total-info-label">
                              ${(billingOption === 'monthly' ? 5 : 5) * amount}
                            </div>
                          </div>
                          {!isMobile && <PriceComponent className="price-component-container" />}
                        </div>
                        {isMobile && <div className="price-component-container-mobile"><PriceComponent /></div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pricing-card">
                  <div className="col-xl-12">
                    <div className="row justify-content-center">
                      <div className="col-md-5 text-center">
                        <div className="card mb-4 box-shadow border">
                          <div className="card-header bg-pricing text-white">
                            <h4 className="my-0 mb-4 pricing-title">Professional</h4>
                          </div>
                          <div className="card-body">
                            <div className="form-group mt-4">
                              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label
                                  className={`btn btn-price btn-toggle ${billingOption === 'monthly' ? 'active' : ''}`}
                                  onClick={() => handleBillingOptionChange('monthly')}
                                >
                                  <input type="radio" name="billingOption" autoComplete="off" /> Monthly
                                </label>
                                <label
                                  className={`btn btn-price btn-toggle ${billingOption === 'yearly' ? 'active' : ''}`}
                                  onClick={() => handleBillingOptionChange('yearly')}
                                >
                                  <input type="radio" name="billingOption" autoComplete="off" /> Yearly
                                </label>
                              </div>
                            </div>
                            <h1 className="card-title price-number">
                              {billingOption === 'monthly' ? '$5' : '$50'}
                              <small className="text-muted">
                                {billingOption === 'monthly' ? ' / mo' : ' / yr'}
                              </small>
                            </h1>
                            <ul className="list-unstyled mt-3 mb-4">
                              <li className="mb-4 text-success font-weight-bold h5">
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                NOW WITH THREE NEW PROFILES!
                              </li>
                              <li className="mb-4">
                                User Logo subscription includes:
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                The ability to change the SKANZ logo from your SKANZ profile to your own custom logo.
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                Change your SKANZ profile color to your own custom color.
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="btn btn-lg btn-block btn-primary"
                              onClick={() => handleCheckout('user')}
                            >
                              Go to Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p style={{ lineHeight: 1.2 }}>
                  * Your QR codes will be available on your dashboard after the payment is confirmed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
