import React from 'react';
import Header from '../components/header';
import Details from './components/businessDetails';
export default function BusinessDetails() {
    
    return (
        <>
            <Header />
            <Details />
        </>
    )
}