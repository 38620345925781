import React from 'react';

const Modal = () => {
  const user = {
    firstName: 'Madeline',
    lastName: 'Lockhart',
    email: 'email@example.com',
  };

  return (
    <div class="modal fade fixed-right">
      <div class="modal-dialog modal-vertical">
        <div class="modal-content">
          <div class="modal-body">
            <div>
              <button type="button" class="close">
                <span aria-hidden="true">{/*times*/}</span>
              </button>
            </div>
            <div class="px-4">
              <div class="d-flex my-4">
                <div class="avatar-parent-child mx-auto">
                  <img
                    alt="placeholder"
                    src="user.profilePicture"
                    class="avatar avatar-xl rounded-circle"
                  />
                  <span class="avatar-child avatar-badge bg-success"></span>
                </div>
              </div>
              <div class="text-center mb-4">
                <h6 class="h5 mb-0">
                  {user.firstName}
                  {user.lastName}
                </h6>
                <span class="d-block text-muted">{user.email}</span>
              </div>
              <div class="mb-5">
                <div class="text-center mb-3">
                  <span class="d-block h6 mb-1">Actived Codes</span>
                  <a href="#" class="h6 text-muted text-sm">
                    40
                  </a>
                </div>
              </div>
              <div class="card shadow-none border-0 bg-section-secondary mt-8">
                <div class="card-body pt-0 px-3 text-center">
                  <div class="pb-4 px-5 mt-n6">
                    <img
                      src="/assets/img/svg/illustrations/illustration-15.svg"
                      class="img-fluid img-center"
                      alt="Illustration"
                    />
                  </div>
                  <h5 class="h5 mb-3">Upgrade to Pro</h5>
                  <p class="text-muted">
                    One year support, montlhy updates for up to 5 team members.
                  </p>
                  <a href="#" class="btn btn-sm btn-warning">
                    Upgrade
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer py-3 mt-auto">
            <a
              onClick={() => {
                localStorage.clear();
                window.location.href = '/';
              }}
              class="btn btn-block btn-sm btn-neutral btn-icon rounded-pill"
            >
              <span class="btn-inner--icon">
                <i data-feather="log-out"></i>
              </span>
              <span class="btn-inner--text">Sign out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
