const isDevEnv =
  window.location.origin.includes('localhost') ||
  window.location.origin.includes('staging');
export const pricesId = {
  organizationTier1Month: isDevEnv
    ? 'price_1LA0RpEcLgF2WVsw1jbhGp0T'
    : 'price_1LCmcQEcLgF2WVswpzZ5lEBR',
  organizationTier2Month: isDevEnv
    ? 'price_1LA0RpEcLgF2WVswjgh1cFUf'
    : 'price_1LCmcQEcLgF2WVsw6kq7motZ',
  organizationTier3Month: isDevEnv
    ? 'price_1LA0RpEcLgF2WVswsYLZFAGl'
    : 'price_1LCmcQEcLgF2WVswTwhUm1Yi',
  organizationTier1Year: isDevEnv
    ? 'price_1LA0RpEcLgF2WVswNw9xJip0'
    : 'price_1LCmcQEcLgF2WVswq00oXAV3',
  organizationTier2Year: isDevEnv
    ? 'price_1LA0RpEcLgF2WVswI26bkKBJ'
    : 'price_1LCmcQEcLgF2WVswvaH9PDA9',
  organizationTier3Year: isDevEnv
    ? 'price_1LA0RpEcLgF2WVswkMRoCtnC'
    : 'price_1LCmcQEcLgF2WVswoCbht4hh',
  premiumPlanMonth: isDevEnv
    ? 'price_1L7lWOEcLgF2WVsw1OvbhnSo'
    : 'price_1L7hSEEcLgF2WVswQORdyfBh',
  premiumPlanYearly: isDevEnv
    ? 'price_1LCr3AEcLgF2WVswkycfv2P3'
    : 'price_1L7lVBEcLgF2WVswXRhHe2xF',
  eventPlanMonth: isDevEnv
    ? 'price_1KxC14EcLgF2WVswtKhbO9Dn'
    : 'price_1KxByjEcLgF2WVswTJSzd9H8',
  eventPlanYearly: isDevEnv
    ? 'price_1LCrK0EcLgF2WVswGf3gh632'
    : 'price_1LCrMXEcLgF2WVswHi7rg5Bq',
};
