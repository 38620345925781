import React, { useState, useEffect } from 'react';

import { urls } from '../../../utils/constants';
import api from '../../../utils/api';

const AddSocialModal = ({ addSocialModal, setAddSocialModal }) => {
  const [link, setLink] = useState('');
  const [nickname, setNickname] = useState('');
  const [social, setSocial] = useState({
    social: '',
    source: 'iconmonstr-link-1.svg',
    kind: 'Url',
    nickname: '',
  });

  const findSocial = link => {
    let found = false;
    urls.forEach(url => {
      if (!found) {
        if (link.toLowerCase().includes(url.social)) {
          found = true;
          setSocial(url);
        }
      }
    });
  };

  const submitData = async e => {
    e.preventDefault();
    try {
      let user = JSON.parse(localStorage.getItem('@SKANZ/user'));
      let newLink = link;
      var prefixS = 'https://';
      var prefix = 'http://';
      if (
        newLink.substr(0, prefix.length) !== prefix &&
        newLink.substr(0, prefixS.length) !== prefixS
      ) {
        newLink = prefixS + newLink;
      }
      user.social = [
        ...user.social,
        { kind: social.kind, data: newLink, nickname, visible: true },
      ];
      const { data } = await api.put('/user/info', user);
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));
      window.location.href = '/profile';
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(addSocialModal);
    if (addSocialModal == 'social') findSocial(link);
  }, [link]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 10000,
        backgroundColor: '#00000050',
      }}
    >
      <div style={{ flex: 1 }}>
        <div classNameName="modal-dialog modal-dialog-centered">
          <div className="show modal-content bg-dark-dark">
            <div className="modal-header">
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'var(--orange)',
                    width: 50,
                    height: 50,
                    borderRadius: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ height: 30, width: 30 }}
                    alt="social"
                    src={`/assets/img/svg/social/${social.source}`}
                  />
                </div>

                <h5 className="ml-3 modal-title">
                  Add new{' '}
                  {addSocialModal != 'social' ? 'link' : 'social connection'}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                onClick={() => setAddSocialModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={e => submitData(e)}>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-control-label">URL</label>
                      <input
                        className="form-control code-label"
                        type="text"
                        onChange={e => setLink(e.target.value)}
                        value={link}
                        required
                        placeholder={
                          addSocialModal == 'social'
                            ? 'Ex.: https://facebook.com/user.50'
                            : 'Ex.: https://company.com'
                        }
                      />
                    </div>
                    <div className="form-group">
                      {social?.kind === 'Url' && (
                        <>
                          <label className="form-control-label">Nickname</label>
                          <input
                            className="form-control code-label"
                            type="text"
                            onChange={e => setNickname(e.target.value)}
                            value={nickname}
                            required
                            placeholder={
                              addSocialModal == 'social'
                                ? 'Ex.: Facebook profile'
                                : 'Ex.: Company website'
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-right">
                    <button
                      type="submit"
                      className="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSocialModal;
