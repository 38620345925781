import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../utils/api';

const Redirect = () => {
  const { qrcode } = useParams();

  useEffect(() => {
    checkQrCode();
  }, []);

  const hasEvent = data => {
    if (data.qrCodeLink) {
      if (data?.event?._id && data.qrCodeLink.includes('out'))
        return `?e=${data?.event?._id}`;
    } else {
      if (data?.event?._id) return `?e=${data?.event?._id}`;
    }

    return '';
  };

  const checkQrCode = async () => {
    try {
      const { data } = await api.get(`/user/profile/${qrcode}`);
      window.location.href = data.qrCodeLink
        ? data.qrCodeLink + hasEvent(data)
        : '/out-profile/' + data.user.userslug + hasEvent(data);
    } catch (error) {
      if (error?.response?.data?.error?.includes('active'))
        handleEnableNew(qrcode);
      console.log(error);
    }
  };

  const handleEnableNew = qrcode => {
    localStorage.setItem('@SKANZ/unloggedqr', qrcode);
    const user = localStorage.getItem('@SKANZ/user');
    if (user) return (window.location.href = '/dashboard');
    window.location.href = '/alert';
  };

  return <></>;
};

export default Redirect;
