import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components';

import api from '../../../utils/api';

const LogoHeader = styled.img`
  width: 50%;
  margin-bottom: 40px;
  @media (min-width: 991px) {
    display: none;
  }
`;

const Content = () => {
  const [name, setName] = useState('');
  //const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const submitData = async (e) => {
    e.preventDefault();
    setError('');
    if (!name) return setError('Name required.');
    //if (!lastName) return setError('Last name required.');
    if (!email) return setError('Email required.');
    if (!password) return setError('Password required.');
    if (!terms) return setError('Please agree to the terms and conditions.');

    let scanningObj = {};

    const unloggedQr = localStorage.getItem('@SKANZ/unloggedqr');
    if (unloggedQr) {
      scanningObj = { scanning: true };
    }

    try {
      const { data } = await api.post('/auth/signUp', {
        firstName: name,
        //lastName,
        email,
        password,
        ...scanningObj,
      });
      setSuccess('Successfully registered, you will be logged in soon.');
      setTimeout(function () {
        submitLogin({ email, password });
      }, 1500);
    } catch (error) {
      if (error.response?.data?.error) setError(error.response.data.error);
    }
  };

  const submitLogin = async ({ email, password }) => {
    try {
      const { data } = await api.post('/auth/signIn', {
        email,
        password,
      });
      handleRedirect(data.user);
    } catch (error) {}
  };

  const handleRedirect = ({ roleId }) => {
    const isCodeToJoin = JSON.parse(
      localStorage.getItem('@SKANZ/qrCodeToJoin'),
    );
    if (isCodeToJoin) {
      localStorage.removeItem('@SKANZ/qrCodeToJoin');
      return (window.location.href = `/profile/${isCodeToJoin}`);
    }
    if (roleId === 'admin') {
      window.location.href = '/admin/dashboard';
    } else {
      const unloggedcontact = localStorage.getItem('@SKANZ/unloggedcontact');
      if (unloggedcontact) return (window.location.href = '/profile');
      window.location.href = '/dashboard';
    }
  };

  const handleGoogleLoginSuccess = async (googleData) => {
    console.log(googleData);
    try {
      const res = await api.post('auth/google', { token: googleData.tokenId });
      console.log(res.data);

      handleRedirect(res.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleLoginFailure = async (error) => {
    console.log(error);

    setError(JSON.stringify(error));
  };

  return (
    <div class="container-fluid d-flex flex-column">
      <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
        <div class="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div class="row justify-content-center">
            <div class="col-11 col-lg-10 col-xl-6">
              <div>
                <LogoHeader src="/assets/img/brand/logo.png" />
                <div class="mb-5">
                  <h6 class="h3 mb-1">Sign Up</h6>
                </div>
                <span class="clearfix"></span>
                {error && (
                  <div class="alert alert-danger text-center">{error}</div>
                )}
                {success && (
                  <div class="alert alert-success text-center">{success}</div>
                )}
                <form onSubmit={(e) => submitData(e)}>
                  <div class="form-group">
                    <label class="form-control-label">Name</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <FeatherIcon icon="user" />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="John"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      />
                    </div>
                  </div>
                  {/*<div class="form-group">
                    <label class="form-control-label">Last name</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <FeatherIcon icon="user" />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ive"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        required
                      />
                    </div>
                </div>*/}
                  <div class="form-group">
                    <label class="form-control-label">Email address</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <FeatherIcon icon="at-sign" />
                        </span>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <label class="form-control-label">Password</label>
                      </div>
                      <div class="mb-2">
                        <span
                          style={{ cursor: 'pointer' }}
                          class="small text-muted text-underline--dashed border-warning"
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? 'Hide password' : 'Show password'}
                        </span>
                      </div>
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <FeatherIcon icon="lock" />
                        </span>
                      </div>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        class="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setTerms(!terms)}
                    class="my-4">
                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        checked={terms}
                        type="checkbox"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="check-terms">
                        I agree to the{' '}
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = 'teste';
                          }}
                          href="/terms-and-conditions">
                          terms and conditions
                        </a>
                      </label>
                    </div>
                  </div>
                  <div class="mt-4">
                    <button type="submit" class="btn btn-block btn-warning">
                      Sign Up
                    </button>
                  </div>
                </form>
                <div class="py-3 text-center">
                  <span class="text-xs text-uppercase">or</span>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      buttonText="Log in with Google"
                      onSuccess={(e) => handleGoogleLoginSuccess(e)}
                      onFailure={(e) => handleGoogleLoginFailure(e)}
                      cookiePolicy="single_host_origin"
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className="btn btn-block btn-dark btn-icon">
                          <span class="btn-inner--icon">
                            <img
                              src="/assets/img/icons/brands/google.svg"
                              alt="Log in with google"
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </button>
                      )}
                    />
                  </div>
                </div>
                <div class="mt-4">
                  Already have an account?{' '}
                  <a href="/login" class="font-weight-bold">
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
