import React, { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import SubdirectoryArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import ErrorIcon from '@mui/icons-material/Error';
import ContactlessIcon from '@mui/icons-material/Contactless';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LanguageIcon from '@mui/icons-material/Language';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import './content.css';

const Content = () => {
  const [index, setIndex] = useState(0);

  const TEXTS = [
    "Businesses",
    "Individuals",
    "Events",
    "Campaigns",
    "Activists"
  ];

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      2000
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <section id="home">
      <img className="wave-bg" src="/assets/img/svg/shapes/wave-bg.svg" />
      <div className="content">
        <div className="heading-home">
          <h1 className="title-home">
            The modern networking tool for
            <TextTransition
              className="transition-text"
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.wobbly}
            />
          </h1>
          <img className="heading-img" src="/assets/img/svg/backgrounds/business.svg" />
        </div>
        <div className="section-2">
          <h2 className="section-title">How it Works</h2>
          <div className="items-section">
            <div className="item-section">
              <AccountBoxIcon className="item-icon" />
              <h4 className="card-title">Create your profile</h4>
              <p className="item-text">Include your contact information, social media links and more!</p>
              <SubdirectoryArrowRightIcon className="icon-arrow" />
            </div>
            <div className="item-section">
              <img src="/assets/img/icons/QrCode.png" className="item-icon" />
              <h4 className="card-title">Activate a SKANZ code</h4>
              <p className="item-text">Order your code and scan to link your profile</p>
              <SubdirectoryArrowRightIcon className="icon-arrow" />
            </div>
            <div className="item-section">
              <ScreenShareIcon className="item-icon" />
              <h4 className="card-title">Scan to share</h4>
              <p className="item-text">Share your codes and start connecting!</p>
            </div>
          </div>
        </div>
        <a href="/sign-up"  className="btn-signup second">Sign up for free!   <ErrorIcon className="button-icon" /></a>
        <div className="section-1">
          <div className="box-img">
            <img className="section2-img" src="/assets/img/svg/backgrounds/heatmap.svg" />
          </div>
          <div className="box-info">
            <h2 className="section-title">Build your digital profile and expand your network today!</h2>
            <ul className="ul-info">
              <li>Increase traffic to your website</li>
              <li>All-in-one digital networking tool</li>
              <li>Contactless information exchange</li>
              <a href="/sign-up"  className="btn-signup">Sign up for free!   <ErrorIcon className="button-icon" /></a>
            </ul>
          </div>
        </div>
        <div className="section-3">
          <div className="benefit-item">
            <div className="box-img">
              <img className="section3-img" src="/assets/img/svg/backgrounds/efficient.svg" />
            </div>
            <div className="box-content">
              <h4 className="benefit-title">Simple and Efficient </h4>
              <p className="benefit-text">Quickly exchange information by scanning a SKANZ code with a smartphone camera. With SKANZ, you will never lose a connection again!  </p>
            </div>
          </div>
          <div className="benefit-item">
            <div className="box-content">
              <h4 className="benefit-title">Flexible and Customizable </h4>
              <p className="benefit-text">Connect your SKANZ code to a URL or your profile .The possibilities are endless! </p>
            </div>
            <div className="box-img">
              <img className="section3-img" src="/assets/img/svg/backgrounds/customizable.svg" />
            </div>
          </div>
          <div className="benefit-item">
            <div className="box-img">
              <img className="section3-img" src="/assets/img/svg/backgrounds/privacy_settings.svg" />
            </div>
            <div className="box-content">
              <h4 className="benefit-title">Total Control and Privacy </h4>
              <p className="benefit-text">You have absolute control over your profile. Provide as much or little information and only share it with who you want.</p>
            </div>
          </div>
        </div>
        <a href="/sign-up"  className="btn-signup second">Sign up for free!   <ErrorIcon className="button-icon" /></a>
        <div className="section-4">
          <h2 className="section-title">How you can use SKANZ</h2>
          <div className="use-items">
            <div className="item-use">
              <div className="box-icon">
                <ContactlessIcon className="use-icon" />
              </div>
              <h4 className="title-use">Contactless information exchange</h4>
              <p className="text-use">Your all-in-one networking tool</p>
            </div>
            <div className="item-use">
              <div className="box-icon">
                <DashboardIcon className="use-icon" />
              </div>
              <h4 className="title-use">Digital business card </h4>
              <p className="text-use">Never lose a connection again!</p>
            </div>
            <div className="item-use">
              <div className="box-icon">
                <ExitToAppIcon className="use-icon" />
              </div>
              <h4 className="title-use">Surveys and sign-up</h4>
              <p className="text-use">Fast way to get customer's responses on surveys</p>
            </div>
          </div>
          <div className="use-items">
            <div className="item-use">
              <div className="box-icon">
                <MenuOpenIcon className="use-icon" />
              </div>
              <h4 className="title-use">Display your menu</h4>
              <p className="text-use">Low maintenance and maximum sanitation</p>
            </div>
            <div className="item-use">
              <div className="box-icon">
                <LanguageIcon className="use-icon" />
              </div>
              <h4 className="title-use">Link a website</h4>
              <p className="text-use">Drive traffic and convert more customers</p>
            </div>
            <div className="item-use">
              <div className="box-icon">
                <TrendingUpIcon className="use-icon" />
              </div>
              <h4 className="title-use">Boost your resume</h4>
              <p className="text-use">By linking prior work, awards, recognition and more</p>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default Content;
