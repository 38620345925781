import React from 'react';
import AccountBoxIcon from '@mui/icons-material//AccountBox';
import SubdirectoryArrowRightIcon from '@mui/icons-material//ArrowRightAlt';
import ScreenShareIcon from '@mui/icons-material//ScreenShare';
import ErrorIcon from '@mui/icons-material//Error';

import './content.css';

const Content = () => {

    return (
        <>
            <div className="section-2">
                <h2 className="section-title">How it Works</h2>
                <div className="items-section">
                    <div className="item-section">
                        <AccountBoxIcon className="item-icon" />
                        <h4 className="card-title">Create your profile</h4>
                        <p className="item-text">Include your contact information, social media links and more!</p>
                        <SubdirectoryArrowRightIcon className="icon-arrow" />
                    </div>
                    <div className="item-section">
                        <img src="/assets/img/icons/QrCode.png" className="item-icon" />
                        <h4 className="card-title">Activate a SKANZ code</h4>
                        <p className="item-text">Order your code and scan to link your profile</p>
                        <SubdirectoryArrowRightIcon className="icon-arrow" />
                    </div>
                    <div className="item-section">
                        <ScreenShareIcon className="item-icon" />
                        <h4 className="card-title">Scan to share</h4>
                        <p className="item-text">Share your codes and start connecting!</p>
                    </div>
                </div>
            </div>
            <a href="/sign-up" className="btn-signup btn-hiw">Sign up for free!   <ErrorIcon className="button-icon" /></a>
        </>
    )
}

export default Content;