import create from 'zustand';
import api from '../utils/api';

export const useStore = create((set) => ({
  user: JSON.parse(localStorage.getItem('@SKANZ/user')),
  setUser: (user) =>
    set((state) => ({
      ...state,
      user,
    })),
  fetchUser: async (user) => {
    const { data } = await api.get(`/user/slug/${user.userslug}`);
    set({ user: data.user });
  },
}));
