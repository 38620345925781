import React, { useState, useEffect } from "react";

import api from "../../../utils/api";

const ModalAddTattoo = ({ setAddTattooModal }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const submitData = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await api.post("/user/activate", { code: `${code}` });
      window.location.href = "/dashboard";
    } catch (error) {
      if (error?.response?.data?.error) setError(error?.response?.data?.error);
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 10000,
        backgroundColor: "#00000050",
      }}
    >
      <div style={{ flex: 1 }}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark-dark">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Activate code
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setAddTattooModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {error && (
                <div class="alert alert-danger text-center">{error}</div>
              )}
              <p class="text-sm text-white mb-3">
                Please type the code label number inside your pack to active
                your code.
              </p>
              <form onSubmit={(e) => submitData(e)}>
                <div class="row">
                  <div class="col">
                    <div class="input-group mb-3">
                      <input
                        required
                        type="text"
                        maxlength="6"
                        class="form-control code-label"
                        placeholder="Ex.: 000012"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-warning mr-2 mt-3"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddTattoo;
