import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { urls } from '../../../utils/constants';
import api from '../../../utils/api';
import { userSet } from '../../../utils/User';
import { Delete, Visibility, VisibilityOff } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';

export default function SocialItem({ item, id, user, setUser, isOnProfile }) {
  const { enqueueSnackbar } = useSnackbar();
  const findSocial = (item) => {
    let found = false;
    for (let i = 0; i < urls.length; i++) {
      if (item.kind === urls[i].kind) {
        return urls[i];
      }
    }
    return { social: '', source: 'iconmonstr-link-1.svg', kind: 'Url' };
  };
  const social = findSocial(item);
  const thisItem = user.social.find((i) => i === item);

  const updateVisibility = async (id) => {
    try {
      let socials = user.social;
      socials.splice(id, 1);
      let newUser = { ...user, social: socials };
      const { data } = await api.put('/user/info', newUser);
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));
      setUser(data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleVisibility = async (target, id) => {
    try {
      let socials = user.social;
      socials[id].visible = target;
      let newUser = { ...user, social: socials };
      const { data } = await api.put('/user/info', newUser);
      localStorage.setItem('@SKANZ/user', JSON.stringify(data.user));
      setUser(data.user);
      enqueueSnackbar(
        `Successfully ${socials[id].visible ? 'hidden' : 'shown'} ${
          socials[id].kind === 'Url' ? socials[id].nickname : socials[id].kind
        } on your profile.`,
        { variant: 'success' },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const toggleEventVisibility = async (socialItem) => {
    try {
      const newUser = { ...user };
      await api.put(`/user/social/event-visibility/${user._id}`, {
        socialId: item._id,
      });
      newUser.social.map((social, index) => {
        if (social === socialItem) {
          newUser.social[index].eventVisible =
            !newUser.social[index].eventVisible;
          localStorage.setItem('@SKANZ/user', JSON.stringify(newUser));
          setUser(newUser);
        }
      });
      enqueueSnackbar(
        `Successfully ${socialItem.visible ? 'hidden' : 'shown'} ${
          socialItem.kind === 'Url' ? socialItem.nickname : socialItem.kind
        } on your events.`,
        { variant: 'success' },
      );
    } catch (error) {
      console.log(error);
    }
  };

  if (!item) return <></>;

  if (!isOnProfile && !item.eventVisible) {
    return <></>;
  }

  const fontSize = "24";

  return (
    <div className={isOnProfile ? '' : 'col-auto ml-3 m-2'}>
      <div className="row align-items-center">
        <div
          style={{
            height: 50,
            width: 50,
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 50,
            alignItems: 'center',
            backgroundColor: 'var(--orange)',
          }}>
          {isOnProfile ? (
            <img
              style={{ height: 30, width: 30 }}
              alt="social"
              src={`/assets/img/svg/social/${social.source}`}
            />
          ) : (
            <a href={item.data}>
              <img
                style={{ height: 30, width: 30 }}
                alt="social"
                src={`/assets/img/svg/social/${social.source}`}
              />
            </a>
          )}
        </div>
        {isOnProfile && (
          <a
            href={item.data}
            style={{ marginLeft: '5%', fontWeight: 600, width: '30vw' }}>
            {social.social === ''
              ? item.nickname
                ? item.nickname
                : item.data
              : social.kind}
          </a>
        )}
        {isOnProfile && (
          <>
            <Tooltip
              placement="top"
              arrow
              title={item.eventVisible ? 'Hide on event' : 'Show on event'}>
              <div
                onClick={() => toggleEventVisibility(item)}
                style={{
                  marginLeft: 'auto',
                  marginRight: '-7rem',
                  color: 'var(--success-light)',
                  cursor: 'pointer',
                }}>
                {thisItem.eventVisible ? (
                  <Visibility fontSize={fontSize} />
                ) : (
                  <VisibilityOff fontSize={fontSize} />
                )}
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={item.visible ? 'Hide on profile' : 'Show on profile'}>
              <div
                onClick={() => toggleVisibility(!item.visible, id)}
                style={{
                  marginLeft: 'auto',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}>
                {item.visible ? (
                  <Visibility fontSize={fontSize} />
                ) : (
                  <VisibilityOff fontSize={fontSize} />
                )}
              </div>
            </Tooltip>
            <Tooltip placement="top" arrow title="Delete">
              <div
                onClick={() => updateVisibility(id)}
                style={{
                  marginLeft: '16px',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}>
                <Delete fontSize={fontSize} />
              </div>
            </Tooltip>
          </>
        )}
      </div>
      {isOnProfile && (
        <div
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#2c4056',
            margin: '10px 0 10px 0',
          }}
        />
      )}
    </div>
  );
}
