import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Cropper from '../../../../components/cropper';
import { useParams } from 'react-router-dom';
import { HexColorPicker } from 'react-colorful';
import Select from 'react-select';
import { useStore } from '../../../../store/user';
import { useSnackbar } from 'notistack';
import { PlacesAutocomplete } from './PlacesAutoComplete';

import {
  UseBusiness,
  useUpdateBusiness,
  useDeleteBusiness,
  UseBusinessOwner,
} from '../../../../utils/hooks/Organization/business';

import api from '../../../../utils/api';

const shapeStyles = {
  option: (styles, { isFocused }) => ({
    ...styles,
    color: '#000',
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#333333',
    borderRadius: '.375rem',
    transition:
      'transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    borderColor:
      state.isSelected || state.isFocused ? 'var(--orange)' : 'transparent',
    backgroundColor: state.isSelected || state.isFocused ? '#111' : '#333333',
    ':hover': {
      borderColor: 'var(--orange)',
      boxShadow: 'none',
    },
    boxShadow: 'none',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: '#FFFFFF' };
  },
};

function DeletePrompt(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-danger btn-icon d-flex align-items-center justify-content-center mr-2"
        onClick={handleOpen}
        style={{ height: '32px', width: '99px' }}>
        Delete
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#2d2d2d',
            width: '500px',
            height: '235px',
          },
        }}>
        <DialogTitle className="mt-4 ml-4">Delete Business?</DialogTitle>
        <DialogContent>
          <DialogContentText className="mx-4">
            <p className="mb-0 text-white mr-0">
              Are you sure you want to delete this business? This will delete
              the business and remove all of its owners.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-4 mx-4">
          <button
            className="btn btn-sm btn-warning btn-icon mr-auto ml-3"
            onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn btn-sm btn-danger btn-icon mr-4"
            onClick={props.onDelete}>
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function EditBusinessModal(props) {
  const user = useStore((state) => state.user);
  console.log(user);
  const [file, setFile] = useState(null);
  const { businessId } = useParams();
  const { data, isLoading } = UseBusiness(
    businessId ? businessId : user.organizationId,
  );
  const { mutate: UpdateBusiness } = useUpdateBusiness();
  const { mutate: DeleteBusiness } = useDeleteBusiness();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(
    data ? data.name : props.business.name,
  );
  const [profileType, setProfileType] = React.useState(
    data ? data.profileType : props.business.profileType,
  );
  const [color, setColor] = useState(props.business.profileColor);
  const [openHexEditor, setOpenHexEditor] = useState(false);
  const [colorChosen, setColorChosen] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [address, setAddress] = useState(data?.address || '');
  const [latitude, setLatitude] = useState(data?.latitude || '');
  const [longitude, setLongitude] = useState(data?.longitude || '');
  const [employeeGallery, setEmployeeGallery] = useState(data?.employeeGallery);
  const { enqueueSnackbar } = useSnackbar();

  const setAddressAndCoords = (address, lat, lng) => {
    setAddress(address);
    setLatitude(lat);
    setLongitude(lng);
  };

  const optionsProfile = [
    { value: 'Standard', label: 'Standard' },
    { value: 'Premium', label: 'Premium' },
    { value: 'Organization', label: 'Organization' },
  ];

  useEffect(() => {
    setSecondaryColor(data?.profileColor);
  }, [data]);

  useEffect(() => {
    console.log(file);
  }, [file]);
  const update = () => {
    const newData = {
      ...data,
      name,
      file,
      ownerId: props.business.owner,
      profileColor: color,
      profileType,
      address,
      latitude,
      longitude,
      employeeGallery,
    };
    newData.file = file;
    UpdateBusiness(newData);
    handleClose();
  };

  const fileChanger = (file) => {
    setFile(file);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteBusiness = () => {
    DeleteBusiness(data._id);
    props.refetch();
    handleClose();
  };

  const handleClick = (color, type) => {
    setColor(color);
    setColorChosen(type);
    setOpenHexEditor(true);
  };

  const changeEmployeeGallery = async (e) => {
    try {
      await api.post(`/business/${user.organizationId}/gallery/employee`, {
        employeeGallery: e.target.checked,
      });
      enqueueSnackbar('Employee Gallery Updated', {
        variant: 'success',
      });
      setEmployeeGallery(e.target.checked);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button className="btn btn-sm btn-warning" onClick={handleOpen}>
        Edit
        <FeatherIcon icon="edit" className="ml-2" />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#2d2d2d',
            width: '600px',
            height: '500',
            color: 'unset',
          },
        }}>
        <DialogTitle className="ml-4 mt-4">Edit Business</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-control-label">Name</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex.: Bussiness name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            {user?.plans.isEventIncluded && (
              <>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Profile Type</label>
                    <Select
                      styles={shapeStyles}
                      className="light-input"
                      defaultValue={{
                        value: profileType,
                        label: profileType,
                      }}
                      options={optionsProfile}
                      onChange={(e) => {
                        setProfileType(e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <PlacesAutocomplete
                    setAddress={setAddressAndCoords}
                    address={address}
                  />
                </div>

                {/* <div className="row ml-1">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-control-label">Latitude</label>
                      <input
                        class="light-input form-control"
                        type="number"
                        value={latitude}
                        onChange={(e) => {
                          setLatitude(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-control-label">Longitude</label>
                      <input
                        class="light-input form-control"
                        type="number"
                        value={longitude}
                        onChange={(e) => {
                          setLongitude(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">
                      Employee Gallery
                    </label>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="customCheck1"
                        className="custom-control-input"
                        checked={employeeGallery}
                        onChange={(e) => {
                          changeEmployeeGallery(e);
                          setEmployeeGallery(e.target.checked);
                        }}
                      />
                      <label class="custom-control-label" for="customCheck1">
                        Employees own gallery
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-md-6 text-white p-0 mt-2">
            <h6>
              Your color hex-code:{' '}
              <span className="rounded p-1" style={{ backgroundColor: color }}>
                {color}
              </span>
            </h6>
            <p>Click the color below to edit it</p>
            <p className="d-flex text-white">
              Color:{' '}
              <div
                onClick={() => handleClick(data?.profileColor, 'secondary')}
                style={{
                  backgroundColor: color,
                  width: '25px',
                  height: '25px',
                  display: 'inline-block',
                  marginLeft: '6px',
                  borderRadius: '25%',
                }}></div>
            </p>
            {openHexEditor && (
              <div className="border p-4 rounded">
                <HexColorPicker
                  color={color}
                  onChange={setColor}
                  className="p-2 m-2"
                />
                <p className="d-flex justify-content-between text-white">
                  Current Color: {color}
                  <div
                    style={{
                      backgroundColor: secondaryColor,
                      width: '25px',
                      height: '25px',
                      display: 'inline-block',
                      marginLeft: '6px',
                      borderRadius: '25%',
                    }}></div>
                </p>
                <div className="d-flex justify-content-between my-4">
                  <label htmlFor="hexCode">
                    <span>Hex Code:</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    maxLength="7"
                  />
                </div>
                <div className="d-flex justify-content-start">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setOpenHexEditor(false);
                    }}>
                    Confirm
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => setOpenHexEditor(false)}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
          <Cropper setFile={fileChanger} />
        </DialogContent>
        <DialogActions className="mb-4">
          <DeletePrompt onDelete={deleteBusiness} />
          <button
            onClick={handleClose}
            className="btn btn-sm btn-warning btn-icon mr-2 d-flex align-items-center justify-content-center"
            style={{ height: '32px', width: '99px' }}>
            Cancel
          </button>
          <button
            onClick={update}
            className="btn btn-sm btn-warning btn-icon d-flex align-items-center justify-content-center"
            style={{ height: '32px', width: '99px' }}>
            {isLoading ? (
              <CircularProgress style={{ height: '15px', width: '15px' }} />
            ) : (
              'Update'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
